import {Form, json, redirect, useNavigate, useNavigation, useParams, useSubmit} from 'react-router-dom';
import {createCampaign, deleteCampaign, editCampaign} from '../../api/campaignClient';
import classes from '../../styling/components/Editor.module.css';
import {Template} from '../../model/Template';
import {Domain} from '../../model/Domain';
import DomainMultiSelect from './DomainMultiSelect';
import {flushSync} from 'react-dom';
import CampaignDateInput from './CampaignDateInput';
import CampaignTimeInput from './CampaignTimeInput';
import CampaignSendDayInput from './CampaignSendDayInput';
import CampaignReceiverGroupSelect from './CampaignReceiverGroupSelect';
import {Campaign, Status} from '../../model/Campaign';
import {ReceiverGroup} from '../../model/ReceiverGroup';
import {useEffect, useState} from 'react';
import {Alert, Box, Button, FormControlLabel, Switch, TextField} from '@mui/material';
import CustomTextField from '../Input/CustomTextfield';
import dayjs, {Dayjs} from 'dayjs';
import {useTranslation} from 'react-i18next';
import TemplateTable from "../Template/TemplateTable";

interface Props {
    method: string,
    campaign?: Campaign,
    receiverGroups: ReceiverGroup[],
    templates: Template[],
    domains: Domain[]
}

const CampaignEditor: React.FC<Props> = (props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const navigation = useNavigation();
    const { customerId } = useParams();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const domains: Domain[] = props.domains;
    const senderDomains = domains.filter((domain) => domain.type.localeCompare("Sender") === 0);
    const standardDomains = domains.filter((domain) => domain.type.localeCompare("Standard") === 0);
    const mailFromDomains = domains.filter((domain) => domain.type.localeCompare("mailFrom") === 0);
    // Implement landingpageDomains later
    // const landingPageDomains = domains.filter((domain) => domain.type.localeCompare("Landingpage") === 0);
    //Get initial campaignDomains
    let campaignSenderDomains: number[] = [];
    let campaignStandardDomains: number[] = [];
    let campaignMailFromDomains: number[] = [];
    if (props.campaign) {
        campaignSenderDomains = props.campaign.domains.filter((domain) => domain.type.localeCompare("Sender") === 0).map((domain) => parseInt(domain.domainId!))
        campaignStandardDomains = props.campaign.domains.filter((domain) => domain.type.localeCompare("Standard") === 0).map((domain) => parseInt(domain.domainId!))
        campaignMailFromDomains = props.campaign.domains.filter((domain) => domain.type.localeCompare("mailFrom") === 0).map((domain) => parseInt(domain.domainId!))
    }
    function cancelHandler() {
        navigate('..');
    }

    const [errorStates, setErrorStates] = useState({});
    const [hasError, setHasError] = useState<boolean>(false);

    // Callback function to update the error state for a specific field
    const handleTextFieldError = (name: string, errorState: boolean) => {
        setErrorStates(prevErrorStates => {
            const updatedErrorStates = {
                ...prevErrorStates,
                [name]: errorState,
            };

            // Now checking inside the update function, where we have the updated state
            const anyError = Object.values(updatedErrorStates).some(error => error);
            setHasError(anyError);

            return updatedErrorStates;
        });
    };

    async function deleteHandler() {
        const proceed = window.confirm(`${t('campaignEditor.confirmDelete')}`);

        if (proceed) {
            const response = await deleteCampaign(props.campaign!.campaignId!, customerId!);
            if (!response.ok) {
                throw json({ message: `${t('campaignEditor.deleteError')}` }, { status: 500 });
            }
            else {
                return navigate('..');
            }

        }
    }

    // Receivergroups
    const [checkedReceiverGroups, setCheckedReceiverGroups] = useState<boolean[]>(
        props.receiverGroups.map((receiverGroup) => (
            props.campaign ? props.campaign.receiverGroups!.map(receiverGroup => receiverGroup.receiverGroupId).includes(receiverGroup.receiverGroupId) : receiverGroup.name === "Default" ? true : false))
    );

    const handleReceiverGroupChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const nextChecked = checkedReceiverGroups.map((c, i) => {
            if (i === index) {
                return event.target.checked;
            } else {
                return c;
            }
        });
        setCheckedReceiverGroups(nextChecked);
    };

    //save initial templateIds in state
    const [selectedFollowUpTemplateIds, setSelectedFollowUpTemplateIds] = useState<string[]>([]);
    const [selectedBaselineTemplateIds, setSelectedBaselineTemplateIds] = useState<string[]>([]);
    let campaignFollowUpTemplateIds: string[] = [];
    let campaignBaselineTemplateIds: string[] = [];
    useEffect(() => {
        if (props.campaign != null) {
            campaignFollowUpTemplateIds = props.campaign.followUpTemplates?.map(template => template.templateId!) as string[];
            setSelectedFollowUpTemplateIds(campaignFollowUpTemplateIds);
            campaignBaselineTemplateIds = props.campaign.baselineTemplates?.map(template => template.templateId!) as string[];
            setSelectedBaselineTemplateIds(campaignBaselineTemplateIds);
        }
    }, [])

    const followUpTemplatesSelectionChangeHandler = (selection: string[]) => {
        setSelectedFollowUpTemplateIds(selection);
    }
    const baselineTemplatesSelectionChangeHandler = (selection: string[]) => {
        setSelectedBaselineTemplateIds(selection);
    }
    // Define states to define the possible selection for baseline and followUp templates
    const [baselineSelection, setBaselineSelection] = useState<Template[]>(props.templates);
    const [followUpSelection, setFollowUpSelection] = useState<Template[]>(props.templates);
    // Adjust followUpSelection based on baseline selection changes
    useEffect(() => {
        setFollowUpSelection(props.templates.filter(template =>
            !selectedBaselineTemplateIds.includes(template.templateId!)
        ));
    }, [selectedBaselineTemplateIds, props.templates]);
    // Adjust baselineSelection based on followUp selection changes
    useEffect(() => {
        setBaselineSelection(props.templates.filter(template =>
            !selectedFollowUpTemplateIds.includes(template.templateId!)
        ));
    }, [selectedFollowUpTemplateIds, props.templates]);

    const submit = useSubmit();
    // This variable is used to fill in the status TextField
    const [status, setStatus] = useState<string>(props.campaign ? props.campaign.status : Status.IN_CONFIGURATION);

    // Validate form before submit
    const [campaignSaveError, setCampaignSaveError] = useState<boolean>(false);
    const [campaignSaveErrorText, setCampaignSaveErrorText] = useState<string>("");
    const validateForm = () => {
        let error: boolean = false;
        let errorText: string = "";
        if (selectedFollowUpTemplateIds.length == 0) {
            errorText += `${t('campaignEditor.templateError')}`;
        }
        if (campaignTime.dayEndTime.isBefore(campaignTime.dayStartTime))
            errorText += `${t('campaignEditor.sendTimeError')}`;
        if (!hasAtLeastOneSendday)
            errorText += `${t('campaignEditor.sendDayError')}`;
        if (!hasSenderDomains)
            errorText += `${t('campaignEditor.senderDomainError')}`;
        if (!hasStandardDomains)
            errorText += `${t('campaignEditor.standardDomainError')}`;
        if (!hasExactlyOneMailFromDomain)
            errorText += `${t('campaignEditor.mailFromDomainError')}`;
        if (!checkedReceiverGroups.includes(true))
            errorText += `${t('campaignEditor.receiverGroupError')}`;
        if (errorText.length > 0) {
            error = true;
        }
        setCampaignSaveError(error);
        setCampaignSaveErrorText(errorText);
        return !error;
    }
    const submitHandler = (event: any, status: string) => {
        event.preventDefault();
        setCampaignSaveErrorText("");
        setCampaignSaveError(false);
        // Use flushSync to ensure Status is updated before submitting
        flushSync(() => {
            setStatus(status);
        });
        if (validateForm()) {
            submit(event.currentTarget, {
                method: props.method as any
            });
        }
    }
    // The following states serve solely the purpose to validate if an input has been made. The values are still passed to the action via names
    // Send time
    const [campaignTime, setCampaignTime] = useState({
        dayStartTime: dayjs(props.campaign ? `2000-01-01T${props.campaign.dayStartTime}` : '2000-01-01T09:00'),
        dayEndTime: dayjs(props.campaign ? `2000-01-01T${props.campaign.dayEndTime}` : '2000-01-01T17:00'),
    });

    const handleTimeChange = (field: string, newValue: Dayjs) => {
        setCampaignTime((prevCampaignTime) => ({
            ...prevCampaignTime,
            [field]: newValue,
        }));
    }
    // Send days
    const [checkedDays, setCheckedDays] = useState<boolean[]>(
        props.campaign
            ? props.campaign.sendDays.split('').map((char) => char === '1')
            : [true, true, true, true, true, false, false]
    );

    const handleCheckboxChange = (index: number) => {
        setCheckedDays((prevCheckedDays) => {
            // Create a new array with the toggled value
            const newCheckedDays = [...prevCheckedDays];
            newCheckedDays[index] = !newCheckedDays[index];

            // Check if at least one checkbox is checked
            const atLeastOneChecked = newCheckedDays.some((day) => day);
            setHasAtLeastOneSendday(atLeastOneChecked);

            return newCheckedDays; // Return the new state
        });
    };
    const [hasAtLeastOneSendday, setHasAtLeastOneSendday] = useState<boolean>(
        props.campaign ? props.campaign.sendDays.includes('1') : true
    );
    // Domains
    const [hasSenderDomains, setHasSenderDomains] = useState<boolean>(campaignSenderDomains.length > 0);
    const [hasStandardDomains, setHasStandardDomains] = useState<boolean>(campaignStandardDomains.length > 0);
    const [hasExactlyOneMailFromDomain, setHasExactlyOneMailFromDomain] = useState<boolean>(campaignMailFromDomains.length == 1);
    const handleSenderDomainChange = (selectedDomains: Domain[]) => {
        if (selectedDomains.length > 0) {
            setHasSenderDomains(true);
        } else {
            setHasSenderDomains(false);
        }
    };
    const handleStandardDomainChange = (selectedDomains: Domain[]) => {
        if (selectedDomains.length > 0) {
            setHasStandardDomains(true);
        } else {
            setHasStandardDomains(false);
        }
    };
    const handleMailFromDomainChange = (selectedDomains: Domain[]) => {
        if (selectedDomains.length == 1) {
            setHasExactlyOneMailFromDomain(true);
        } else {
            setHasExactlyOneMailFromDomain(false);
        }
    };

    const [baselineEnabled, setBaselineEnabled] = useState(props.campaign ? props.campaign.baselineEnabled : false);
    const handleBaselineEnabledChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setBaselineEnabled(event.target.checked);
        baselineTemplatesSelectionChangeHandler([]);
        setSelectedBaselineTemplateIds([]);
    }

    const getWeeksBetween = (startDate: Date, endDate: Date) => {
        if (!startDate || !endDate) return 6; // Fallback to 6 if any dates are invalid

        const start = dayjs(startDate);
        const end = dayjs(endDate);
        const duration = end.diff(start, 'week');
        return Math.abs(duration); // Get absolute value
    };
    // Determine the initial value of baselineDuration based on the campaign dates
    const initialBaselineDuration = props.campaign && props.campaign.baselineEndDate ?
        getWeeksBetween(props.campaign.startDate, props.campaign.baselineEndDate) :
        6;


    return (
        <Box component={Form} method={props.method as any} className={classes.form} onSubmit={() => setIsSubmitting(true)} autoComplete='off'>
            <CustomTextField name="name" label={t('campaignEditor.nameLabel')} defaultValue={props.campaign ? props.campaign.name : ''} maxLength={50} regEx={/^[A-Za-z0-9À-ÖØ-öø-ÿ-\s/()[\]]+$/} helper={t('campaignEditor.nameHelper')} onErrorChange={handleTextFieldError} />
            <CampaignDateInput campaign={props.campaign ? props.campaign : undefined} onErrorChange={handleTextFieldError} />
            <CampaignTimeInput dayStartTime={campaignTime.dayStartTime} dayEndTime={campaignTime.dayEndTime} onTimeChange={handleTimeChange} campaign={props.campaign ? props.campaign : undefined} />
            <DomainMultiSelect id="senderdomains" name="senderdomains" label={t('campaignEditor.senderDomainLabel')} defaultValue={campaignSenderDomains} options={senderDomains} onSelectChange={handleSenderDomainChange} enableEdit={true} />
            <DomainMultiSelect id="standarddomains" name="standarddomains" label={t('campaignEditor.standardDomainLabel')} defaultValue={campaignStandardDomains} options={standardDomains} onSelectChange={handleStandardDomainChange} enableEdit={true} />
            <DomainMultiSelect id="mailfromdomains" name="mailfromdomains" label={t('campaignEditor.mailFromDomainLabel')} defaultValue={campaignMailFromDomains} options={mailFromDomains} onSelectChange={handleMailFromDomainChange} enableEdit={true} />
            <div>
                <CampaignSendDayInput onCheckboxChange={handleCheckboxChange} campaign={props.campaign ? props.campaign : undefined} />
                <TextField id="status" label="Status" name="status" type="text" className={classes.displayNone} required fullWidth aria-readonly value={status} />
                <CampaignReceiverGroupSelect receiverGroups={props.receiverGroups} checked={checkedReceiverGroups} handleChange={handleReceiverGroupChange} enableEdit={true} />
            </div>
            <FormControlLabel control={<Switch name="baselineEnabled" checked={baselineEnabled}
                onChange={handleBaselineEnabledChange} />}
                label={t('campaignEditor.enableBaseline')} />
            {baselineEnabled &&
                <div>
                    <CustomTextField disabled={!baselineEnabled} name="baselineDuration" label={t('campaignEditor.baselineDuration')} defaultValue={initialBaselineDuration as any as string} maxLength={2} regEx={/^[0-9]+$/} helper={t('campaignEditor.baselineDurationHelper')} onErrorChange={handleTextFieldError} />
                    {t('campaignEditor.baselineTemplates')}
                    <TemplateTable templates={baselineSelection} selectedTemplateIds={selectedBaselineTemplateIds}
                                   onSelectionChange={baselineTemplatesSelectionChangeHandler}/>
                    <TextField id="baselineTemplates" label={t('campaignEditor.templateLabel')} name="baselineTemplates" type="text" fullWidth value={selectedBaselineTemplateIds} />
                </div>
            }
            <div>{t('campaignEditor.followUpTemplates')}
                <TemplateTable templates={followUpSelection} selectedTemplateIds={selectedFollowUpTemplateIds}
                               onSelectionChange={followUpTemplatesSelectionChangeHandler}/>
                <TextField id="followUpTemplates" label={t('campaignEditor.templateLabel')} name="followUpTemplates" type="text" fullWidth value={selectedFollowUpTemplateIds} />
            </div>
            {campaignSaveError && <Alert onClose={() => { setCampaignSaveError(false) }} severity="error">{campaignSaveErrorText}</Alert>}
            <div>
                {props.method === 'PUT' && <Button type="button" className={classes.delete} variant="contained" onClick={deleteHandler} disabled={isSubmitting}>{t('campaignEditor.delete')}</Button>}
                <Button type="button" variant="outlined" onClick={cancelHandler} disabled={isSubmitting}>{t('campaignEditor.cancel')}</Button>
                <Button type="button" onClick={(event) => submitHandler(event, props.campaign ? props.campaign.status : Status.IN_CONFIGURATION)} variant="contained" disabled={isSubmitting || hasError}>{t('campaignEditor.save')}</Button>
            </div>
        </Box >
    );
}

export default CampaignEditor;

export async function action({
    request,
    params
}: { request: Request, params: { customerId: string, campaignId: string } }) {
    const method = request.method;
    const customerId = params.customerId;
    const data: any = await request.formData();
    const {
        name,
        startDate,
        endDate,
        dayStartTime,
        dayEndTime,
        timezone,
        monday,
        tuesday,
        wednesday,
        thursday,
        friday,
        saturday,
        sunday,
        senderdomains,
        standarddomains,
        mailfromdomains,
        baselineEnabled,
        baselineDuration,
        status,
        baselineTemplates,
        followUpTemplates,
        ...rest
    } = Object.fromEntries(data);
    const selectedReceiverGroups: any = Object.values(rest).map((id) => (
        { receiverGroupId: id }
    ));

    //Transfer selected baselineTemplates into correct format for API
    const selectedBaselineTemplates: string = data.get('baselineTemplates') as string;
    let selectedBaselineTemplateArray: any;
    if (selectedBaselineTemplates != null) {
        selectedBaselineTemplateArray = selectedBaselineTemplates.split(',').filter(e => e !== "");
        selectedBaselineTemplateArray = selectedBaselineTemplateArray.map((id: string) => (
            { templateId: id }
        ));
    } else {
        selectedBaselineTemplateArray = [];
    }
    //Transfer selected followUpTemplates into correct format for API
    const selectedFollowUpTemplates: string = data.get('followUpTemplates') as string;
    let selectedFollowUpTemplateArray: any = selectedFollowUpTemplates.split(',').filter(e => e !== "");
    selectedFollowUpTemplateArray = selectedFollowUpTemplateArray.map((id: string) => (
        { templateId: id }
    ));
    //Transfer selected SenderDomains into correct format for API
    const selectedSenderDomains: string = data.get('senderdomains') as string;
    let selectedSenderDomainArray: any[] = selectedSenderDomains.split(',');
    selectedSenderDomainArray = selectedSenderDomainArray.map((id: string) => (
        { domainId: parseInt(id) }
    ));
    //Transfer selected Standarddomains into correct format for API
    const selectedStandardDomains: string = data.get('standarddomains') as string;
    let selectedStandardDomainArray: any[] = selectedStandardDomains.split(',');
    selectedStandardDomainArray = selectedStandardDomainArray.map((id: string) => (
        { domainId: parseInt(id) }
    ));
    //Transfer selected mailFromDomains into correct format for API
    const selectedMailFromDomains: string = data.get('mailfromdomains') as string;
    let selectedMailFromDomainArray: any[] = selectedMailFromDomains.split(',');
    selectedMailFromDomainArray = selectedMailFromDomainArray.map((id: string) => (
        { domainId: parseInt(id) }
    ));
    const selectedDomainArray: any[] = selectedSenderDomainArray.concat(selectedStandardDomainArray).concat(selectedMailFromDomainArray);
    //Store sendDays into correct format for API (String with 7x 0 or 1)
    let sendDays: string = "";
    sendDays += data.get('monday') === "1" ? "1" : "0";
    sendDays += data.get('tuesday') === "1" ? "1" : "0";
    sendDays += data.get('wednesday') === "1" ? "1" : "0";
    sendDays += data.get('thursday') === "1" ? "1" : "0";
    sendDays += data.get('friday') === "1" ? "1" : "0";
    sendDays += data.get('saturday') === "1" ? "1" : "0";
    sendDays += data.get('sunday') === "1" ? "1" : "0";
    //Baseline
    let baselineEndDate;
    if (baselineEnabled === "on") {
        baselineEndDate = dayjs(startDate).add(baselineDuration, 'week').add(1, 'day').toDate();
        if (!dayjs(baselineEndDate).isBefore(dayjs(endDate))) {
            throw json({ message: 'Could not add campaign to customer. Baselineenddate must be before enddate.' }, { status: 500 });
        }
    } else {
        baselineEndDate = null;
    }

    let campaignData = {
        name: data.get('name') as string,
        startDate: data.get('startDate') as Date,
        endDate: data.get('endDate') as Date,
        dayStartTime: data.get('dayStartTime') as Date,
        dayEndTime: data.get('dayEndTime') as Date,
        timezone: data.get('timezone'),
        sendDays: sendDays,
        domains: selectedDomainArray,
        baselineEnabled: baselineEnabled === "on",
        baselineEndDate: baselineEndDate as Date,
        status: data.get('status') as Status,
        baselineTemplates: selectedBaselineTemplateArray,
        followUpTemplates: selectedFollowUpTemplateArray,
        receiverGroups: selectedReceiverGroups
    };
    console.log(campaignData);
    if (method === 'POST') {
        const response = await createCampaign(campaignData, customerId);
        if (!response.ok) {
            throw json({ message: 'Could not add campaign to customer.' }, { status: 500 });
        }
        else {
            return redirect('..');
        }
    }

    if (method === 'PUT') {
        const campaignId = params.campaignId;
        const response = await editCampaign(campaignData, campaignId, customerId);

        if (!response.ok) {
            throw json({ message: 'Could not update campaign in customer.' }, { status: 500 });
        } else {
            return redirect('..');
        }
    }
}