import {Dialog, DialogTitle, List, ListItem, ListItemButton, ListItemText} from "@mui/material";
import {Customer} from "../../../model/Customer";
import React from "react";
import { useTranslation } from "react-i18next";

type Props = {
    open: boolean
    onClose: () => void
    onSelect: (customerId: string) => void
    customers: Customer[];
}
const CopyTemplateDialog: React.FC<Props> = (props) => {
    const { t } = useTranslation();
    const {open, onClose, onSelect, customers} = props;

    const handleClose = () => {
        onClose();
    };

    const handleListItemClick = (value: string) => {
        onSelect(value);
    };

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>{t('copyTemplateDialog.selectTargetCustomer')}</DialogTitle>
            <List>
                {customers.length > 0 && customers.map((customer) => (
                    <ListItem key={customer.customerId} disableGutters>
                        <ListItemButton onClick={() => handleListItemClick(customer.customerId!)}
                                        key={customer.customerId}>
                            <ListItemText primary={customer.fullName}/>
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Dialog>
    );

}

export default CopyTemplateDialog;