import {Button} from "@mui/material";
import React from "react";
import SendIcon from '@mui/icons-material/Send';
import {useSnackbarAlert} from "../../hooks/useSnackbarAlert";
import {useTranslation} from "react-i18next";
import {sendTestmail} from "../../api/sendTestmailClient";
import {getCustomerTestmailConfig} from "../../api/testmailConfigClient";
import {useParams} from "react-router-dom";
import {useConfirmationDialog} from "../../hooks/useConfirmationDialog";

interface Props {
    templateId: string
}

export const SendTestmailButton: React.FC<Props> = (props) => {

    const {templateId} = props;
    const [snackbar, openSnackbarAlert] = useSnackbarAlert();
    const [confirmationDialog, openConfirmationDialog] = useConfirmationDialog();
    const {t} = useTranslation();
    let {customerId} = useParams();


    const handleSendTestmail = async () => {
        const response = await sendTestmail(templateId)
        if (!response.ok)
            openSnackbarAlert(t('templateTable.testmailSentError'), "error");
        else
            openSnackbarAlert(t('templateTable.testmailSentSuccessfully'), "success");
    }

    const confirmSendTestmail = async () => {
        const response = await getCustomerTestmailConfig(customerId!)
        const config = await response.json()
        openConfirmationDialog("", t('templateTable.testmailConfirm', {mailAddress: config.email}), handleSendTestmail)
    }

    return (
        <>
            {snackbar}
            {confirmationDialog}
            <Button onClick={confirmSendTestmail}>
                <SendIcon/>
            </Button>
        </>
    )
}