import {Await, defer, useLoaderData} from "react-router-dom";
import React, {Suspense} from "react";
import {UserEditor} from "../../components/User/UserEditor";
import {OrganizationLevel} from "../../model/enum/OrganizationLevel";
import {loadSystemRoles} from "./EditSystemUser";
import {CircularProgress} from "@mui/material";

const NewSystemUserPage: React.FC = (props) => {
    const {availableRoles} = useLoaderData() as { availableRoles: string[] };


    return (
        <Suspense fallback={<CircularProgress/>}>
            <Await resolve={availableRoles}>
                {availableRoles => <UserEditor method="POST" availableRoles={availableRoles}
                                               level={OrganizationLevel.SYSTEM}/>}
            </Await>
        </Suspense>
    )
}

export default NewSystemUserPage;

export async function newSystemUserPageLoader() {
    return defer({
        availableRoles: loadSystemRoles()
    })
}