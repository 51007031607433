import React from "react";
import {toPct} from "../../../util/Calc";
import {useTranslation} from "react-i18next";
import classes from '../../../styling/components/analytics/ClickRateDoughnut.module.css';
import {useTheme} from "@mui/material";
import {Doughnut} from "react-chartjs-2";

interface Props {
    clicked: number;
    sent: number;
}

export const ClickRateDoughnut: React.FC<Props> = (props) => {

    const {clicked, sent} = props
    const {t} = useTranslation()
    const theme = useTheme()
    const rate = toPct(clicked, sent)

    const data = {
        datasets: [{
            data: [clicked, sent],// @ts-ignore
            backgroundColor: [theme.palette.click.main, "#F0F0F0"]
        }],
        labels: [t('statCard.clicked'), t('statCard.notClicked')]
    }

    return (
        <div className={classes.clickRateDisplay}>
            <div className={classes.graphContainer}>
                <div className={classes.centerText}>
                    <span className={classes.percentageText}>{rate}%</span>
                    <span className={classes.subText}>{t("campaignAnalyticsView.clickRate")}</span>
                </div>
                <Doughnut data={data} options={{plugins: {legend: {display: false}}, cutout: 50}}/>
            </div>
        </div>
    )
}