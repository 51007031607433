import React, {Suspense} from "react";
import {Await, defer, json, useLoaderData} from "react-router-dom";
import {getCustomerTestmailConfig} from "../../api/testmailConfigClient";
import {TestmailConfig} from "../../model/TestmailConfig";
import TestmailConfigEditor from "../../components/Testmail/TestmailConfigEditor";
import {CircularProgress} from "@mui/material";

const TestmailConfigPage: React.FC = (props) => {
    const {testmailConfig} = useLoaderData() as { testmailConfig: TestmailConfig };
    return (
        <Suspense fallback={<CircularProgress/>}>
            <Await resolve={Promise.all([testmailConfig])}>
                {data => {
                    let method: string;
                    if (data[0] === null) {
                        method = "POST";
                    } else {
                        method="PUT";
                    }
                     return <TestmailConfigEditor method={method} testmailConfig={data[0]}/>}
                }
            </Await>
        </Suspense>
    )
}

export default TestmailConfigPage;
export async function loadTestmailConfig(customerId: string) {
    const response = await getCustomerTestmailConfig(customerId);

    if (!response.ok) {
        if (response.status === 400) {
           return null;
        }
        throw json({ message: 'Could not fetch testmailConfig for customer.' }, { status: 500 });
    } else {
        const resData = await response.json();
        return resData
    }
}

export async function loader({ request, params }: { request: Request, params: { customerId: string } }) {
    const customerId = params.customerId;

    return defer({
        testmailConfig: loadTestmailConfig(customerId)
    })
}