import React, {Suspense} from "react";
import CampaignEditor from "../../components/Campaign/CampaignEditor";
import {Await, defer, json, useLoaderData} from "react-router-dom";
import {ReceiverGroup} from "../../model/ReceiverGroup";
import {Template} from "../../model/Template";
import {loadCustomerReceiverGroups} from "../Receiver/ReceiverManager";
import {loadCustomerTemplates} from "../Template/TemplateManager";
import {Domain} from "../../model/Domain";
import {listDomains} from "../../api/domainClient";
import {CircularProgress} from "@mui/material";

const NewCampaignPage: React.FC = (props) => {
    const {
        receiverGroups,
        templates,
        domains
    } = useLoaderData() as { receiverGroups: ReceiverGroup[], templates: Template[], domains: Domain[] };

    return (
        <Suspense fallback={<CircularProgress/>}>
            <Await resolve={Promise.all([receiverGroups, templates, domains])}>
                {data => <CampaignEditor method="POST" receiverGroups={data[0]} templates={data[1]} domains={data[2]}/>}
            </Await>
        </Suspense>
    )
}

export default NewCampaignPage;

async function loadDomains(customerId: string) {
    const response = await listDomains(customerId);

    if (!response.ok) {
        throw json({message: 'Could not fetch Domains.'}, {status: 500});
    } else {
        const resData = await response.json();
        return resData
    }
}

export async function loader({
                                 request,
                                 params
                             }: { request: Request, params: { customerId: string, campaignId: string } }) {
    const campaignId = params.campaignId;
    const customerId = params.customerId;

    return defer({
        receiverGroups: loadCustomerReceiverGroups(customerId),
        templates: loadCustomerTemplates(customerId),
        domains: loadDomains(customerId),
    })
}