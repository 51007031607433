import {Domain} from "../model/Domain";
import {fetchWithToken} from "./fetchWithToken";


const getBaseUrl = () => {
    let url;
    switch (process.env.NODE_ENV) {
        case 'production':
            url = '/api';
            break;
        case 'development':
            url = 'http://localhost:5000/api';
            break;
        default:
            url = '/api';
    }

    return url;
}

export async function getDomain(domainId: string, customerId: string) {
    try {
        const response = await fetchWithToken(getBaseUrl() + `/customers/${customerId}/domains/${domainId}`, {
            method: "GET",
        })
        return response
    } catch (error) {
        throw new Error("Error")
    }
}

export async function getCampaignDomains(campaignId: string, customerId: string) {
    try {
        const response = await fetchWithToken(getBaseUrl() + `/customers/${customerId}/domains?campaignId=${campaignId}`, {
            method: "GET"
        })
        return response
    } catch (error) {
        throw new Error("Error")
    }
}

export async function listDomains(customerId: string) {
    try {
        const response = await fetchWithToken(getBaseUrl() + `/customers/${customerId}/domains`, {
            method: "GET"
        })
        return response
    } catch (error) {
        throw new Error("Error")
    }
}

export async function deleteDomain(domain_id: string){
    try {
        const response = await fetchWithToken(getBaseUrl() + `/system/domains/${domain_id}`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
            },

        })
        return response
    } catch (error){
        throw new Error("Error")
    }
}

export async function createDomain(domain: Domain){
    try {
        const response = await fetchWithToken(getBaseUrl() + "/system/domains", {
            method: "POST",
            body: JSON.stringify(domain),
            headers: {
                "Content-Type": "application/json",
            },
        })
        return response
    } catch (error){
        throw new Error("Error")
    }
}

export async function editDomain(domain: Domain, domain_id: String){
    try {
        const response = await fetchWithToken(getBaseUrl() + `/system/domains/${domain_id}`, {
            method: "PUT",
            body: JSON.stringify(domain),
            headers: {
                "Content-Type": "application/json",
            },
        })
        return response
    } catch (error){
        throw new Error("Error")
    }
}