// import 'froala-editor/css/froala_style.min.css';
// import 'froala-editor/css/froala_editor.pkgd.min.css';
// import 'froala-editor/js/plugins.pkgd.min.js';
///<reference path= "../../../node_modules/froala-editor/index.d.ts" />
import React, {useContext, useEffect, useRef, useState} from 'react';
import FroalaEditorComponent from 'react-froala-wysiwyg';
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';
import FroalaEditor from 'froala-editor';
import {Box, Button} from '@mui/material';
import classes from '../../styling/components/EmailEditor.module.css';
import '../../styling/preview/css/froala_styles.css';
import DateDialog from './TemplateEditorDialog/DateDialog';
import {UserContext} from '../../App';
import HintDialog from './TemplateEditorDialog/HintDialog';
import {LearningContent} from '../../model/LearningContent';
import {useTranslation} from 'react-i18next';
import MonthDialog from './TemplateEditorDialog/MonthDialog';

interface Props {
    onChange: any,
    model: string
    hints: LearningContent[]
}

const EmailEditor: React.FC<Props> = (props) => {
    const { t } = useTranslation();
    const getBaseUrl = () => {
        let url;
        switch (process.env.NODE_ENV) {
            case 'production':
                url = '/api';
                break;
            case 'development':
                url = 'http://localhost:5000/api';
                break;
            default:
                url = '/api';
        }

        return url;
    }
    /*
    // Authorization alternative to UserContext
    const [authorization, setAuthorization] = useState<string>("");
    useEffect(() => {
        const fetchData = async () => {
            try {
              const header = await getAuthHeader();
              setAuthorization(header);
   
            } catch (error) {
              console.error('Error fetching data:', error);
              // Handle error if needed
            }
          };
          fetchData();
    }, []);
    */
    const {authHeader} = useContext(UserContext);
    // Define colors
    let colors = ['#000000', '#333333', '#61BD6D', '#1ABC9C', '#54ACD2', '#4280F7', '#FFFFFF', 'REMOVE'];

    const [showPreview, setShowPreview] = useState<boolean>(false);

    const togglePreview = () => {
        setShowPreview((prevState) => !prevState);
    }
    const [editor, setEditor] = useState<FroalaEditor | null>();
    const editorRef = useRef<FroalaEditorComponent | null>(null);
 
    useEffect(() => {
        if (editorRef.current) {
            // Access the Froala Editor instance through the ref
            const editorInstance = editorRef.current.getEditor();
            if (editorInstance) {
                // Set the editor instance in the state
                setEditor(editorInstance);
            }
        }
    }, [editorRef]);
 
    const [bodyHintIds, setBodyHintIds] = useState<number[]>([]);
    useEffect(() => {
        // Update bodyHintIds when hints prop changes
        setBodyHintIds(props.hints.filter((hint) => hint.location.includes('l_body')).map((hint) => parseInt(hint.location.split('_')[2], 10)));
    }, [props.hints]);
    // Custom buttons now working
    FroalaEditor.RegisterCommand('salutation(formal)', {
        title: `${t('emailEditor.salutationFormal')}`,
        icon: `${t('emailEditor.salutationFormal')}`,
        focus: true,
        undo: true,
        refreshAfterCallback: true,
        callback: function () {
            this.html.insert('$SALUTATIONFORMAL$');
        }
    });
    FroalaEditor.RegisterCommand('salutation(informal)', {
        title: `${t('emailEditor.salutationInformal')}`,
        icon: `${t('emailEditor.salutationInformal')}`,
        focus: true,
        undo: true,
        refreshAfterCallback: true,
        callback: function () {
            this.html.insert('$SALUTATIONINFORMAL$');
        }
    });
    FroalaEditor.RegisterCommand('firstname', {
        title: `${t('emailEditor.firstname')}`,
        icon: `${t('emailEditor.firstname')}`,
        focus: true,
        undo: true,
        refreshAfterCallback: true,
        callback: function () {
            this.html.insert('$FIRSTNAME$');
        }
    });
    FroalaEditor.RegisterCommand('lastname', {
        title: `${t('emailEditor.lastname')}`,
        icon: `${t('emailEditor.lastname')}`,
        focus: true,
        undo: true,
        refreshAfterCallback: true,
        callback: function () {
            this.html.insert('$LASTNAME$');
        }
    });
    FroalaEditor.RegisterCommand('email', {
        title: `${t('emailEditor.email')}`,
        icon: `${t('emailEditor.email')}`,
        focus: true,
        undo: true,
        refreshAfterCallback: true,
        callback: function () {
            this.html.insert('$EMAIL$');
        }
    });
    FroalaEditor.RegisterCommand('subdomain', {
        title: `${t('emailEditor.subdomain')}`,
        icon: `${t('emailEditor.subdomain')}`,
        focus: true,
        undo: true,
        refreshAfterCallback: true,
        callback: function () {
            this.html.insert('$SUBDOMAIN$');
        }
    });
    FroalaEditor.RegisterCommand('domain', {
        title: `${t('emailEditor.domain')}`,
        icon: `${t('emailEditor.domain')}`,
        focus: true,
        undo: true,
        refreshAfterCallback: true,
        callback: function () {
            this.html.insert('$DOMAIN$');
        }
    });
    FroalaEditor.RegisterCommand('company', {
        title: `${t('emailEditor.company')}`,
        icon: `${t('emailEditor.company')}`,
        focus: true,
        undo: true,
        refreshAfterCallback: true,
        callback: function () {
            this.html.insert('$COMPANYLONG$');
        }
    });
    FroalaEditor.RegisterCommand('companyShort', {
        title: `${t('emailEditor.companyShort')}`,
        icon: `${t('emailEditor.companyShort')}`,
        focus: true,
        undo: true,
        refreshAfterCallback: true,
        callback: function () {
            this.html.insert('$COMPANY$');
        }
    });
    FroalaEditor.RegisterCommand('zipcode', {
        title: `${t('emailEditor.zipcode')}`,
        icon: `${t('emailEditor.zipcode')}`,
        focus: true,
        undo: true,
        refreshAfterCallback: true,
        callback: function () {
            this.html.insert('$ZIPCODE$');
        }
    });
    FroalaEditor.RegisterCommand('city', {
        title: `${t('emailEditor.city')}`,
        icon: `${t('emailEditor.city')}`,
        focus: true,
        undo: true,
        refreshAfterCallback: true,
        callback: function () {
            this.html.insert('$CITY$');
        }
    });
    FroalaEditor.RegisterCommand('street', {
        title: `${t('emailEditor.street')}`,
        icon: `${t('emailEditor.street')}`,
        focus: true,
        undo: true,
        refreshAfterCallback: true,
        callback: function () {
            this.html.insert('$STREET$');
        }
    });
    FroalaEditor.RegisterCommand('phone', {
        title: `${t('emailEditor.phone')}`,
        icon: `${t('emailEditor.phone')}`,
        focus: true,
        undo: true,
        refreshAfterCallback: true,
        callback: function () {
            this.html.insert('$PHONE$');
        }
    });
    FroalaEditor.RegisterCommand('today', {
        title: `${t('emailEditor.today')}`,
        icon: `${t('emailEditor.today')}`,
        focus: true,
        undo: true,
        refreshAfterCallback: true,
        callback: function () {
            this.html.insert('$CURRENTDATE$');
        }
    });
    const [openDateDialog, setOpenDateDialog] = useState<boolean>(false);
    const handleDateDialogOpen = () => {
        setOpenDateDialog(true);
    }
    const handleDateDialogClose = (numberOfDays: string) => {
        setOpenDateDialog(false);
        // Only insert anything if dialog hasn't been cancelled
        if (numberOfDays !== "cancelled") {
            // If no "-" contained, it's a positive value, thus add '+'
            if (!numberOfDays.includes('-')) {
                numberOfDays = "+" + numberOfDays;
            }
            // Restore cursor position and insert placeholder
            editor!.selection.restore();
            editor!.html.insert('$CURRENTDATE' + numberOfDays + '$', false);
        }
    }
    FroalaEditor.RegisterCommand('today+-x', {
        title: `${t('emailEditor.todayx')}`,
        icon: `${t('emailEditor.todayx')}`,
        focus: true,
        undo: true,
        refreshAfterCallback: true,
        callback: function () {
            handleDateDialogOpen();
            // Save editor instance and cursor positon to later add placeholder onClose of dialog
            editor!.selection.save();
        }
    });
    FroalaEditor.RegisterCommand('currentMonth', {
        title: `${t('emailEditor.currentMonth')}`,
        icon: `${t('emailEditor.currentMonth')}`,
        focus: true,
        undo: true,
        refreshAfterCallback: true,
        callback: function () {
            this.html.insert('$CURRENTMONTH$');
        }
    });
    FroalaEditor.RegisterCommand('month+-x', {
        title: `${t('emailEditor.monthx')}`,
        icon: `${t('emailEditor.monthx')}`,
        focus: true,
        undo: true,
        refreshAfterCallback: true,
        callback: function () {
            handleMonthDialogOpen();
            // Save editor instance and cursor positon to later add placeholder onClose of dialog
            editor!.selection.save();
        }
    });
    const [openMonthDialog, setOpenMonthDialog] = useState<boolean>(false);
    const handleMonthDialogOpen = () => {
        setOpenMonthDialog(true);
    }
    const handleMonthDialogClose = (numberOfMonths: string) => {
        setOpenMonthDialog(false);
        // Only insert anything if dialog hasn't been cancelled
        if (numberOfMonths !== "cancelled") {
            // If no "-" contained, it's a positive value, thus add '+'
            if (!numberOfMonths.includes('-')) {
                numberOfMonths = "+" + numberOfMonths;
            }
            // Restore cursor position and insert placeholder
            editor!.selection.restore();
            editor!.html.insert('$CURRENTMONTH' + numberOfMonths + '$', false);
        }
    }
    FroalaEditor.RegisterCommand('currentYear', {
        title: `${t('emailEditor.currentYear')}`,
        icon: `${t('emailEditor.currentYear')}`,
        focus: true,
        undo: true,
        refreshAfterCallback: true,
        callback: function () {
            this.html.insert('$CURRENTYEAR$');
        }
    });
    FroalaEditor.RegisterCommand('addAttachment', {
        title: `${t('emailEditor.addAttachment')}`,
        icon: `${t('emailEditor.addAttachment')}`,
        focus: true,
        undo: true,
        refreshAfterCallback: true,
        callback: function () {
            this.html.insert("<table style=\"width: 245px; border: 1px solid rgb(217, 217, 217);\" class=\"noBorder\">\n" +
                "<tbody>\n" +
                "<tr>\n" +
                "<td style=\"vertical-align: top;\"><a href=\"%24LINK1%24\"><img src=\"https://look2x-image-upload.s3.eu-central-1.amazonaws.com/1707729959611-Icon_Word.png\" style=\"min-width: 35px; max-width: 35px; min-height: 45px;\" width=\"35\" height=\"45\" class=\"fr-fic fr-dii\"></a></td>\n" +
                "<td style=\"vertical-align: top; width: 185px;\"><a style=\"text-decoration:none; color:#000;\" href=\"%24LINK1%24\">Dokument.docx</a>\n" +
                "<br><span style=\"color:#797673;\">40,1 KB</span></td>\n" +
                "<td style=\"max-width: 25px; min-width:25px;\"><a href=\"%24LINK1%24\"><img src=\"https://look2x-image-upload.s3.eu-central-1.amazonaws.com/1707730085711-arrow_attachment.png\" style=\"max-width: 20px; min-width:20px;\" width=\"20\" height=\"15\" class=\"fr-fic fr-dii\"></a></td>\n" +
                "</tr>\n" +
                "</tbody>\n" +
                "</table>");
        }
    });
    FroalaEditor.RegisterCommand('addLearningHint', {
        title: `${t('emailEditor.addLearningHint')}`,
        icon: `${t('emailEditor.addLearningHint')}`,
        focus: true,
        undo: true,
        refreshAfterCallback: true,
        callback: function () {
            handleHintDialogOpen();
            // Save editor instance and cursor positon to later add placeholder onClose of dialog
            editor!.selection.save();
        }
    });

    const [openHintDialog, setOpenHintDialog] = useState<boolean>(false);

    const handleHintDialogOpen = () => {
        setOpenHintDialog(true);
    };

    const handleHintDialogClose = (hintId: string) => {
        setOpenHintDialog(false);
        if (hintId !== "cancelled") {
            editor!.selection.restore();
            let selectedHtml = editor!.html.getSelected();

            //OLD Approach
            if (selectedHtml === "<p></p>") {
                return editor!.html.insert(`$L${hintId}OPEN$$L${hintId}CLOSE$`);
            }
            if (selectedHtml.startsWith("<p") && selectedHtml.endsWith("</p>")) {
                const pOpeningTagEnd: number = selectedHtml.indexOf(">");
                const tempSelection = selectedHtml.slice(pOpeningTagEnd + 1, -4);
                //Check if sliced string still contains <p> tags, i.e. there have been multiple <p> in the parent, if so do nothing
                if (!tempSelection.includes("<p"))
                    selectedHtml = tempSelection;
            }
            return editor!.html.insert(`$L${hintId}OPEN$${selectedHtml}$L${hintId}CLOSE$`);
        };
    }

    return (
        <>
            <HintDialog open={openHintDialog} onClose={handleHintDialogClose} options={bodyHintIds} />
            <DateDialog open={openDateDialog} onClose={handleDateDialogClose} />
            <MonthDialog open={openMonthDialog} onClose={handleMonthDialogClose} />
            <FroalaEditorComponent
                ref={editorRef}
                tag='textarea'
                model={props.model}
                onModelChange={props.onChange}
                config={{
                    key: 'Ja2A4wA1D2D2C1C4H4C3oCd2ZSb1XHi1Cb2a1KIWCWMJHXCLSwG1G1B2B2B1D7D6E1E4H4==',
                    attribution: false,
                    placeholderText: `${t('emailEditor.bodyPlaceholder')}`,
                    //Update immediately
                    immediateReactModelUpdate: false,
                    // use inline styling
                    useClasses: true,
                    // customize toolbar buttons
                    toolbarButtons: [
                        [
                            'salutation(formal)',
                            'salutation(informal)',
                            'firstname',
                            'lastname',
                            'email'
                        ],
                        [
                            'subdomain',
                            'domain',
                            'company',
                            'companyShort',
                            'zipcode',
                            'city',
                            'street',
                            'phone',
                        ],
                        [
                            'addAttachment',
                            'addLearningHint',
                            'today',
                            'today+-x',
                            'currentMonth',
                            'month+-x',
                            'currentYear',
                        ],
                        [
                            'html',
                            'fullscreen',
                            'undo',
                            'redo',
                            'trackChanges'
                        ],
                        [
                            'bold',
                            'italic',
                            'underline',
                            'textColor',
                            'backgroundColor',
                            'inlineStyle',
                            'clearFormatting'
                        ],
                        [
                            'alignLeft',
                            'alignCenter',
                            'alignRight',
                            'alignJustify'
                        ],
                        [
                            'formatOL',
                            'formatUL',
                            'indent',
                            'outdent'
                        ],
                        [
                            'paragraphFormat'
                        ],
                        [
                            'fontFamily',
                            'fontSize',
                            'lineHeight',
                        ],
                        [
                            'insertLink',
                            'insertImage',
                            'insertTable',
                        ]
                    ],
                    //Predefine inline styles, example: CTA Button, idea: attachment
                    inlineStyles: {
                        'CTA': 'background-color: #706FD3; padding: 12px 16px; color:#ffffff; border-radius: 4px; text-decoration: none;',

                    },
                    // Allow style tags
                    htmlRemoveTags: ['script'],
                    // Set image width and height as attribute
                    imageOutputSize: true,
                    // Set fontsize unit
                    fontSizeUnit: 'px',
                    // Set color selection
                    colorsBackground: colors,
                    colorsText: colors,
                    tableColors: colors,
                    tableStyles: {
                        noBorder: 'No Border',
                    },
                    tableCellStyles: {
                    },
                    // Set link dropdown options
                    linkList: [
                        {
                            text: '$LINK1$',
                            href: '$LINK1$'
                        },
                        {
                            text: '$LINK2$',
                            href: '$LINK2$'
                        },
                        {
                            text: '$LINK3$',
                            href: '$LINK3$'
                        },
                        {
                            text: '$LINK4$',
                            href: '$LINK4$'
                        },
                        {
                            text: '$CUSTOMERLINK1$',
                            href: '$CUSTOMERLINK1$'
                        },
                        {
                            text: '$CUSTOMERLINK2$',
                            href: '$CUSTOMERLINK2$'
                        },
                        {
                            text: '$MSLOGINLINK$',
                            href: '$MSLOGINLINK$'
                        },
                        {
                            text: '$INTRANETLINK$',
                            href: '$INTRANETLINK$'
                        },
                    ],
                    // Predefined link stylings, classes have to be defined in CSS!
                    linkStyles: {
                        'Class1': 'No styling',
                    },
                    // Link autoprefix
                    linkAutoPrefix: '',
                    // Do not convert emails to mailto links
                    linkConvertEmailAddress: false,
                    // Set request headers
                    requestHeaders: {
                        authorization: authHeader,
                    },
                    // Set the image upload parameter.
                    imageUploadParam: 'file',
                    // Set the image upload URL.
                    imageUploadURL: getBaseUrl() + '/images',
                    // Set the image upload URL.
                    imageManagerLoadURL: getBaseUrl() + '/images',
                    // Set the delete image request URL.
                    imageManagerDeleteURL: getBaseUrl() + '/images',
                    // Set the delete image request type.
                    imageManagerDeleteMethod: "DELETE",
                    // Set request type.
                    imageUploadMethod: 'POST',
                    // Set max image size to 5MB.
                    imageMaxSize: 5 * 1024 * 1024,
                    // Allow to upload PNG and JPG.
                    imageAllowedTypes: ['jpeg', 'jpg', 'png'],
                    //Set html untouched to true
                    htmlUntouched: true,
                    // Define events
                    events: {
                    }
                }}
            />
            <Button type="button" variant="outlined" onClick={togglePreview}>{t('emailEditor.togglePreview')}</Button>
            <Box className={classes.inlinePreview}>
                {showPreview && <FroalaEditorView
                    model={props.model}
                />}
            </Box>
        </>
    );
}
export default EmailEditor;