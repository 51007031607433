import React, {Suspense} from "react";
import ReceiverEditor from "../../components/Receiver/ReceiverEditor";
import {Await, defer, useLoaderData, useParams} from "react-router-dom";
import {loadCustomerReceiverGroups, loadCustomerReceivers} from "./ReceiverManager";
import {Receiver} from "../../model/Receiver";
import {ReceiverGroup} from "../../model/ReceiverGroup";
import {CircularProgress} from "@mui/material";

const EditReceiverPage: React.FC = (props) => {
    const {receivers, receiverGroups} = useLoaderData() as { receivers: Receiver[], receiverGroups: ReceiverGroup[] };
    const {receiverId} = useParams();
    return (
        <Suspense fallback={<CircularProgress/>}>
            <Await resolve={Promise.all([receivers, receiverGroups])}>
                {data => <ReceiverEditor
                    receiver={data[0].find((receiver: Receiver) => receiver.receiverId == receiverId)}
                    receiverGroups={data[1]} method="PUT"/>}
            </Await>
        </Suspense>
    );
}

export default EditReceiverPage;


export async function loader({request, params}: { request: Request, params: { customerId: string } }) {
    const id = params.customerId;

    return defer({
        receivers: loadCustomerReceivers(id),
        receiverGroups: loadCustomerReceiverGroups(id),
    })
}