import {Form, json, redirect, useNavigate, useNavigation, useParams} from 'react-router-dom';
import {createReceiverGroup, deleteReceiverGroup, editReceiverGroup} from '../../api/receiverGroupClient';

import classes from '../../styling/components/Editor.module.css';
import {ReceiverGroup} from '../../model/ReceiverGroup';
import {Box, Button, TextField} from '@mui/material';
import React, {useState} from "react";
import ReceiverTable from '../Receiver/ReceiverTable';
import {Receiver} from '../../model/Receiver';
import CustomTextField from '../Input/CustomTextfield';
import { useTranslation } from 'react-i18next';

interface Props {
    method: string,
    receivers: Receiver[],
    receiverGroups: ReceiverGroup[]
}

const ReceiverGroupEditor: React.FC<Props> = (props) => {
    const { t } = useTranslation();
    let {receiverGroupId, customerId} = useParams();
    const currentReceiverGroup = props.receiverGroups.find((receiverGroup) => receiverGroup.receiverGroupId?.toString() === receiverGroupId);
    const [isDefault, setIsDefault] = useState<boolean>(currentReceiverGroup ? currentReceiverGroup.name === 'Default' : false);
    const navigate = useNavigate();
    const navigation = useNavigation();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    function cancelHandler() {
        navigate('..');
    }

    const [errorStates, setErrorStates] = useState({});
    const [hasError, setHasError] = useState<boolean>(false);

    // Callback function to update the error state for a specific field
    const handleTextFieldError = (name: string, errorState: boolean) => {
        setErrorStates(prevErrorStates => {
            const updatedErrorStates = {
                ...prevErrorStates,
                [name]: errorState,
            };
    
            // Now checking inside the update function, where we have the updated state
            const anyError = Object.values(updatedErrorStates).some(error => error);
            setHasError(anyError);
    
            return updatedErrorStates;
        });
    };

    async function deleteHandler() {
        const proceed = window.confirm(`${t('receiverGroupEditor.confirmDelete')}`);

        if (proceed) {
            const response = await deleteReceiverGroup(receiverGroupId!, customerId!);
            if (!response.ok) {
                throw json({ message: `${t('receiverGroupEditor.deleteError')}` }, { status: 500 });
            }
            else {
                return navigate('..');
            }
        }
    }
    const [selectedReceiverIds, setSelectedReceiverIds] = useState<string[]>(
        props.receivers
            .filter(receiver => receiver.receiverGroups.some(receiverGroup => receiverGroup.receiverGroupId! === receiverGroupId!))
            .map(receiver => receiver.receiverId!)
    );

    const selectionChangeHandler = (selection: string[]) => {
        setSelectedReceiverIds(selection);
    }

    return (
        <Box component={Form} method={props.method as any} className={classes.form} onSubmit={() => setIsSubmitting(true)} autoComplete='off'>
            {!isDefault ?
                <CustomTextField name="name" label={t('receiverGroupEditor.nameLabel')} defaultValue={currentReceiverGroup ? currentReceiverGroup.name : ''} maxLength={50} regEx={/^[A-Za-z0-9À-ÖØ-öø-ÿ-\s/()&.[\]]+$/} helper={t('receiverGroupEditor.nameHelper')} onErrorChange={handleTextFieldError}/>
                :
                <TextField id="name" label={t('receiverGroupEditor.nameLabel')} name="name" margin="normal" type="text" value={currentReceiverGroup!.name} />
            }
                <ReceiverTable receivers={props.receivers} fromReceiverGroupEditor={true} isDefaultGroup={isDefault} selectedReceiverIds={selectedReceiverIds} onSelectionChange={selectionChangeHandler} />
                <TextField id="receivers" label="Receivers" name="receivers" type="text" className={classes.displayNone} value={selectedReceiverIds} />
                {(props.method === 'PUT' && !isDefault) && <Button type="button" className={classes.delete} variant="contained" onClick={deleteHandler} disabled={isSubmitting}>{t('receiverGroupEditor.delete')}</Button>}
                <Button type="button" variant="outlined" onClick={cancelHandler} disabled={isSubmitting}>{t('receiverGroupEditor.cancel')}</Button>
                <Button type="submit" variant="contained" disabled={isSubmitting|| hasError}>{t('receiverGroupEditor.save')}</Button>
        </Box>
    );
}

export default ReceiverGroupEditor;

export async function action({ request, params }: { request: Request, params: any }) {
    const method = request.method;
    const customerId = params.customerId;
    const data = await request.formData();
    //Transfer selected Receivers into correct format for API
    const selectedReceivers: string = data.get('receivers') as string;
    let selectedReceiverArray: any = selectedReceivers.split(',').filter(e => e !== "");
    selectedReceiverArray = selectedReceiverArray.map((id: string) => (
        { receiverId: id }
    ));

    const receiverGroupData = {
        name: data.get('name') as string,
        receivers: selectedReceiverArray,
        customer: {
            customerId: customerId,
        }
    };

    if (method === 'POST') {
        const response = await createReceiverGroup(receiverGroupData, customerId);

        if (!response.ok) {
            throw json({ message: 'Could not add ReceiverGroup' }, { status: 500 });
        }
        else {
            return redirect('..');
        }
    }

    if (method === 'PUT') {
        const receiverGroupId = params.receiverGroupId;
        const response = await editReceiverGroup(receiverGroupData, receiverGroupId, customerId);

        if (!response.ok) {
            throw json({ message: 'Could not update ReceiverGroup' }, { status: 500 });
        } else {
            return redirect('..');
        }
    }
}