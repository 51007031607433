import React from 'react';
import { Select, FormControl, InputLabel, MenuItem, SelectChangeEvent } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface DifficultySelectProps {
  difficulty: string;
  handleDifficultyChange: (event: SelectChangeEvent) => void;
}

const DifficultySelect: React.FC<DifficultySelectProps> = ({ difficulty, handleDifficultyChange }) => {
  const { t } = useTranslation();
  return (
    <FormControl fullWidth>
      <InputLabel id="difficulty-label">{t('difficultySelect.label')}</InputLabel>
      <Select
        labelId="difficulty-label"
        id="difficulty"
        name="difficulty"
        value={difficulty}
        label={t('difficultySelect.label')}
        onChange={handleDifficultyChange}
      >
        <MenuItem value="Easy">{t('difficultySelect.easy')}</MenuItem>
        <MenuItem value="Medium">{t('difficultySelect.medium')}</MenuItem>
        <MenuItem value="Hard">{t('difficultySelect.hard')}</MenuItem>
      </Select>
    </FormControl>
  );
};

export default DifficultySelect;