import {Campaign, CampaignDomains, CampaignReceiverGroups, CampaignStatus, CampaignTemplates} from "../model/Campaign";
import {fetchWithToken} from "./fetchWithToken";

const getBaseUrl = (customerId: string) => {
    let url;
    switch (process.env.NODE_ENV) {
        case 'production':
            url = `/api/customers/${customerId}/campaigns`;
            break;
        case 'development':
            url = `http://localhost:5000/api/customers/${customerId}/campaigns`;
            break;
        default:
            url = `/api/customers/${customerId}/campaigns`;
    }

    return url;
}

export async function getCampaign(campaign_id: string, customerId: string) {
    try {
        const response = await fetchWithToken(getBaseUrl(customerId) + `/${campaign_id}`, {
            method: "GET",
        })
        return response
    } catch (error) {
        throw new Error("Error")
    }
}

export async function getCustomerCampaigns(customerId: string) {
    try {
        const response = await fetchWithToken(getBaseUrl(customerId), {
            method: "GET"
        })
        return response
    } catch (error) {
        throw new Error("Error while loading campaigns")
    }
}

export async function deleteCampaign(campaign_id: string, customerId: string) {
    try {
        const response = await fetchWithToken(getBaseUrl(customerId) + `/${campaign_id}`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
            },
        })
        return response
    } catch (error) {
        throw new Error("Error")
    }
}

export async function createCampaign(campaign: Campaign, customerId: string) {
    try {
        const response = await fetchWithToken(getBaseUrl(customerId), {
            method: "POST",
            body: JSON.stringify(campaign),
            headers: {
                "Content-Type": "application/json",
            },
        })
        return response
    } catch (error) {
        throw new Error("Error")
    }
}

export async function editCampaign(campaign: Campaign, campaign_id: string, customerId: string) {
    try {
        const response = await fetchWithToken(getBaseUrl(customerId) + `/${campaign_id}`, {
            method: "PUT",
            body: JSON.stringify(campaign),
            headers: {
                "Content-Type": "application/json",
            },
        })
        return response
    } catch (error) {
        throw new Error("Error")
    }
}

export async function updateCampaignStatus(campaignStatus: CampaignStatus, campaign_id: string, customerId: string) {
    try {
        const response = await fetchWithToken(getBaseUrl(customerId) + `/${campaign_id}/status?status=${campaignStatus.status}`, {
            method: "PUT"
        })
        return response
    } catch (error) {
        throw new Error("Error")
    }
}

export async function updateCampaignReceiverGroups(campaignReceiverGroups: CampaignReceiverGroups, campaign_id: string, customerId: string) {
    try {
        const response = await fetchWithToken(getBaseUrl(customerId) + `/${campaign_id}/receiverGroupUpdate`, {
            method: "PUT",
            body: JSON.stringify(campaignReceiverGroups),
            headers: {
                "Content-Type": "application/json",
            },
        })
        return response
    } catch (error) {
        throw new Error("Error")
    }
}

export async function updateCampaignDomains(campaignDomains: CampaignDomains, campaign_id: string, customerId: string) {
    try {
        const response = await fetchWithToken(getBaseUrl(customerId) + `/${campaign_id}/domainUpdate`, {
            method: "PUT",
            body: JSON.stringify(campaignDomains),
            headers: {
                "Content-Type": "application/json",
            },
        })
        return response
    } catch (error) {
        throw new Error("Error")
    }
}

export async function updateCampaignTemplates(baselineTemplates: CampaignTemplates, followUpTemplates: CampaignTemplates, campaign_id: string, customerId: string) {
    const body = {
        updateBaselineTemplates: baselineTemplates,
        updateFollowUpTemplates: followUpTemplates
    };
    try {
        const response = await fetchWithToken(getBaseUrl(customerId) + `/${campaign_id}/templateUpdate`, {
            method: "PUT",
            body: JSON.stringify(body),
            headers: {
                "Content-Type": "application/json",
            },
        })
        return response
    } catch (error) {
        throw new Error("Error")
    }
}
