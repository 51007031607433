import React, {useContext, useEffect} from "react";
import {UserContext} from "../../App";
import {OrganizationLevel} from "../../model/SelfUser";
import {useNavigate} from "react-router-dom";

export const UserForwarder: React.FC = (props) => {

    const navigate = useNavigate()
    const {user} = useContext(UserContext);

    useEffect(() => {
        if (user.role === "Demo Viewer")
            navigate(`/customers/${user.organizationId}/demo/analytics`)
        else if (user.organizationLevel === OrganizationLevel.CUSTOMER)
            navigate(`/customers/${user.organizationId}/analytics`)
        else
            navigate("/customers")
    }, []);

    return (
        <>
        </>
    )

}