import {useTranslation} from "react-i18next";
import React, {useState} from "react";
import {json} from "react-router-dom";
import {generateLearningLinks} from "../api/sendTestmailClient";
import LearningLinksDialog from "../components/Template/TemplateTableDialog/LearningLinksDialog";

export function useLearningLinkDialog(): [JSX.Element, (templateIds: string[]) => void, boolean] {
    const {t} = useTranslation();

    const [learningLinks, setLearningLinks] = useState<string[]>([]);
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState<boolean>(false);

    const generateLinks = (templateIds: string[]) => {
        setLoading(true);
        generateLearningLinks(templateIds)
            .then((res) => {
                if (!res.ok) {
                    throw json({message: `${t('templateTable.generateLinksError')}`}, {status: 500});
                }
                setLoading(false);
                return res.json()
            })
            .then((data) => {
                setLearningLinks(data);
                setLoading(false);
            })
            .catch((exception) => {
                setLoading(false)
                console.log(exception.message);
            });
    }

    const openDialog = (selectedTemplateIds: string[]) => {
        generateLinks(selectedTemplateIds)
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const dialog = <LearningLinksDialog
        open={open}
        onClose={handleClose}
        learningLinks={learningLinks}
        loading={loading}
    />

    return [dialog, openDialog, loading]
}