import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';

type Props = {
  open: boolean
  onClose: () => void
  onContinue: () => void
  selectedReceiverGroups: any[];
}

const ConfirmReceiverGroupDialog: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { open, onClose, onContinue, selectedReceiverGroups } = props;
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{t('confirmReceiverGroupDialog.confirmReceiverGroupSelection')}</DialogTitle>
      <DialogContent>
        <p>{t('confirmReceiverGroupDialog.areYouSure')}</p>
        <p>{t('confirmReceiverGroupDialog.selectedReceiverGroups')}{selectedReceiverGroups.join(', ')}</p>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {t('confirmReceiverGroupDialog.cancel')}
        </Button>
        <Button onClick={onContinue} color="primary">
          {t('confirmReceiverGroupDialog.continue')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmReceiverGroupDialog;
