import ConfirmationDialog from "../components/Input/ConfirmationDialog";
import {useState} from "react";

const emptyData = {
    title: "",
    content: "",
    callback: () => {
    }
}

export function useConfirmationDialog(): [JSX.Element, (title: string, content: string, onConfirmation: () => void) => void] {

    const [open, setOpen] = useState(false)
    const [data, setData] = useState(emptyData)

    const handleCancel = () => {
        setOpen(false)
    }
    const handleProceed = (callback: () => void) => {
        return () => {
            callback()
            setOpen(false)
        }
    }

    const dialog = <ConfirmationDialog
        open={open} title={data.title} content={data.content} onCancel={handleCancel}
        onProceed={handleProceed(data.callback)}
    />

    const openDialog = (title: string, content: string, onConfirmation: () => void) => {
        setData({title: title, content: content, callback: onConfirmation})
        setOpen(true)
    }

    return [dialog, openDialog]
}