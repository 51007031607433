import { Form, json, redirect, useNavigate, useNavigation } from 'react-router-dom';
import classes from '../../styling/components/Editor.module.css';
import { Box, Button } from '@mui/material';
import React, { useState } from "react";
import { TestmailConfig } from '../../model/TestmailConfig';
import { createTestmailConfig, editTestmailConfig } from '../../api/testmailConfigClient';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useTranslation } from 'react-i18next';
import CustomTextField from '../Input/CustomTextfield';
import GenderSelect from '../Receiver/GenderSelect';

interface Props {
    method: string,
    testmailConfig?: TestmailConfig
}

const TestmailConfigEditor: React.FC<Props> = (props) => {
    const { t } = useTranslation();
    const { testmailConfig } = props;
    const navigate = useNavigate();
    const navigation = useNavigation();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    function cancelHandler() {
        if (props.method === 'POST') {
            navigate('../templates');
        } else {
            navigate('../templates')
        }
    }

    const [errorStates, setErrorStates] = useState({});
    const [hasError, setHasError] = useState<boolean>(false);

    // Callback function to update the error state for a specific field
    const handleTextFieldError = (name: string, errorState: boolean) => {
        setErrorStates(prevErrorStates => {
            const updatedErrorStates = {
                ...prevErrorStates,
                [name]: errorState,
            };
    
            // Now checking inside the update function, where we have the updated state
            const anyError = Object.values(updatedErrorStates).some(error => error);
            setHasError(anyError);
    
            return updatedErrorStates;
        });
    };

    // Gender
    const [gender, setGender] = useState(testmailConfig ? testmailConfig.gender : "");

    const handleGenderChange = (event: SelectChangeEvent) => {
        setGender(event.target.value as string);
    };

    return (
        <Box component={Form} method={props.method as any} className={classes.form} onSubmit={() => setIsSubmitting(true)} autoComplete='off'>
            <CustomTextField name="firstName" label={t('testmailConfigEditor.firstNameLabel')} defaultValue={props.testmailConfig ? props.testmailConfig.firstName : ''} maxLength={50} regEx={/^[a-zA-ZÀ-ÖØ-öø-ÿß\s-]+$/} helper={t('testmailConfigEditor.firstNameHelper')} onErrorChange={handleTextFieldError}/>
            <CustomTextField name="lastName" label={t('testmailConfigEditor.lastNameLabel')} defaultValue={props.testmailConfig ? props.testmailConfig.lastName : ''} maxLength={50} regEx={/^[a-zA-ZÀ-ÖØ-öø-ÿß\s-]+$/} helper={t('testmailConfigEditor.lastNameHelper')} onErrorChange={handleTextFieldError}/>
            <GenderSelect gender={gender} handleGenderChange={handleGenderChange} />
            <CustomTextField name="emailAddress" label={t('testmailConfigEditor.emailLabel')} defaultValue={props.testmailConfig ? props.testmailConfig.email : ''} maxLength={50} regEx={/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/} helper={t('testmailConfigEditor.emailHelper')} onErrorChange={handleTextFieldError}/>
            <div>
                <Button type="button" variant="outlined" onClick={cancelHandler} disabled={isSubmitting}>{t('testmailConfigEditor.cancel')}</Button>
                <Button type="submit" variant="contained" disabled={isSubmitting || hasError}>{t('testmailConfigEditor.save')}</Button>
            </div>
        </Box>
    );
}

export default TestmailConfigEditor;

/*type Params = {
    customerId: string
}*/
export async function action({ request, params }: { request: Request, params: any }) {
    const method = request.method;
    const customerId = params.customerId;
    const data = await request.formData();
    
    const testmailConfigData = {
        firstName: data.get('firstName') as string,
        lastName: data.get('lastName') as string,
        gender: data.get('gender') as string,
        email: data.get('emailAddress') as string,
    };

    if (method === 'POST') {
        const response = await createTestmailConfig(testmailConfigData, customerId);

        if (!response.ok) {
            throw json({ message: 'Could not add testmailConfig' }, { status: 500 });
        }
        else {
            return redirect('../templates');
        }
    }

    if (method === 'PUT') {
        const response = await editTestmailConfig(testmailConfigData, customerId);

        if (!response.ok) {
            throw json({ message: 'Could not update customer' }, { status: 500 });
        } else {
            return redirect('../templates');
        }
    }
}