import {Await, useLoaderData} from "react-router-dom";
import React, {Suspense} from "react";
import {DomainGroup} from "../../model/Domain";
import DomainEditor from "../../components/Domain/DomainEditor";
import {CircularProgress} from "@mui/material";

const NewDomainPage: React.FC = (props) => {
    const { domainGroups } = useLoaderData() as { domainGroups: DomainGroup[] };

    return (
        <Suspense fallback={<CircularProgress/>}>
            <Await resolve={domainGroups}>
                {domainGroups => <DomainEditor method="POST" domainGroups={domainGroups} />}
            </Await>
        </Suspense>
    )
}

export default NewDomainPage;