import React from "react";
import {Template} from "../../model/Template";
import {Customer} from "../../model/Customer";
import classes from "../../styling/pages/Manager.module.css";
import {Link, useNavigate, useParams} from "react-router-dom";
import {Box, Button} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useCopyTemplateDialog} from "../../hooks/useCopyTemplateDialog";
import {useLearningLinkDialog} from "../../hooks/useLearningLinkDialog";
import TemplateTable from "./TemplateTable";

interface Props {
    customers: Customer[]
    templates: Template[]
}

export const TemplateOverview: React.FC<Props> = (props) => {

    const {customers, templates} = props;
    const {t} = useTranslation();
    const {customerId} = useParams();
    const navigate = useNavigate()

    const [selectedTemplateIds, setSelectedTemplateIds] = React.useState<string[]>([]);
    const handleSelectionChange = (newSelection: string[]) => {
        setSelectedTemplateIds(newSelection);
    }

    //Copy Templates
    const [copyTemplateDialog, openCopyTemplateDialog] = useCopyTemplateDialog();

    const handleOpenCopyTemplateDialog = () => {
        openCopyTemplateDialog(handleCopyTemplateResult, customerId!, selectedTemplateIds, customers);
    }

    const handleCopyTemplateResult = (data: Template[], targetCustomerId: string) => {
        if (customerId?.toString().localeCompare(targetCustomerId) === 0) {
            navigate('.', {replace: true});
        }

    }

    // Generate links
    const [learningLinkDialog, openLearningLinkDialog, learningLinksLoading] = useLearningLinkDialog();

    const handleOpenLearningLinkDialog = () => {
        openLearningLinkDialog(selectedTemplateIds);
    }

    return (
        <>
            <Box className={classes.buttonContainer}>
                <Link to={"new"}>
                    <Button variant="contained">
                        {t('templateManager.newTemplate')}
                    </Button>
                </Link>
            </Box>

            <div className={classes.tableButtons}>
                <Button disabled={selectedTemplateIds.length === 0 || learningLinksLoading}
                        onClick={handleOpenLearningLinkDialog} variant="contained">
                    {t('templateTable.generateLearningLinks')}
                </Button>
                {learningLinkDialog}
                <Button disabled={selectedTemplateIds.length === 0 || learningLinksLoading}
                        onClick={handleOpenCopyTemplateDialog} variant="contained">
                    {t('templateTable.copyTemplates')}
                </Button>
                {copyTemplateDialog}
            </div>

            <TemplateTable templates={templates} selectedTemplateIds={selectedTemplateIds}
                           onSelectionChange={handleSelectionChange} extendedFields/>
        </>
    )
}