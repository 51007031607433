import {Await, defer, json, useLoaderData} from "react-router-dom";
import React, {Suspense} from "react";
import {UserEditor} from "../../components/User/UserEditor";
import {OrganizationLevel} from "../../model/enum/OrganizationLevel";
import {User} from "../../model/User";
import {getInstanceUser} from "../../api/userClient";
import {listInstanceRoles} from "../../api/roleClient";
import {CircularProgress} from "@mui/material";

const EditInstanceUserPage: React.FC = (props) => {
    const {user, availableRoles} = useLoaderData() as { user: User, availableRoles: string[] };


    return (
        <Suspense fallback={<CircularProgress/>}>
            <Await resolve={Promise.all([user, availableRoles])}>
                {data => <UserEditor method="PUT" user={data[0]} availableRoles={data[1]}
                                     level={OrganizationLevel.INSTANCE}/>}
            </Await>
        </Suspense>
    )
}

export default EditInstanceUserPage;

async function loadUser(userId: string, instanceId: string) {
    const response = await getInstanceUser(userId, instanceId);

    if (!response.ok) {
        throw json({message: 'Could not fetch user.'}, {status: response.status});
    } else {
        const resData = await response.json();
        return resData
    }
}

export async function loadInstanceRoles(instanceId: string) {
    const response = await listInstanceRoles(instanceId);

    if (!response.ok) {
        throw json({message: 'Could not fetch user.'}, {status: response.status});
    } else {
        const resData = await response.json();
        return resData
    }
}

export async function editInstanceUserPageLoader({
                                                     request,
                                                     params
                                                 }: { request: Request, params: { userId: string, instanceId: string } }) {
    const userId = params.userId;
    const instanceId = params.instanceId;
    return defer({
        user: loadUser(userId, instanceId),
        availableRoles: loadInstanceRoles(instanceId)
    })
}