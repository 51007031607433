import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { DialogActions, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface HintDialogProps {
    open: boolean;
    onClose: (inputValue: string) => void;
    options: number[];
}

const HintDialog: React.FC<HintDialogProps> = ({ open, onClose, options }) => {
    const { t } = useTranslation();
    const [hintInput, setHintInput] = useState('');

    const handleInsertHint = () => {
        onClose(hintInput);
        setHintInput('');
    };

    const handleClose = () => {
        onClose('cancelled');
        setHintInput('');
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>{t('hintDialog.title')}</DialogTitle>
            <DialogContent>
                <FormControl fullWidth>
                    <InputLabel id="learningHintIdInputLabel">{t('hintDialog.label')}</InputLabel>
                    <Select
                        labelId="learningHintIdInputLabel"
                        id="learningHintIdInput"
                        value={hintInput}
                        onChange={(e) => setHintInput(e.target.value as string)}
                        label={t('hintDialog.label')}
                    >
                        {options.map((option) => (
                            <MenuItem key={option} value={option}>
                                {option}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>{t('hintDialog.cancel')}</Button>
                <Button onClick={handleInsertHint}>{t('hintDialog.insert')}</Button>
            </DialogActions>
        </Dialog>
    );
};

export default HintDialog;
