import {fetchWithToken} from "./fetchWithToken";

const getBaseUrl = () => {
    let url;
    switch (process.env.NODE_ENV) {
        case 'production':
            url = `/api/system/actuator/`;
            break;
        case 'development':
            url = `http://localhost:5000/api/system/actuator/`;
            break;
        default:
            url = `/api/system/actuator/`;
    }

    return url;
}

export enum ActuatorEndpoint {
    flyway = "flyway",
    loggers = "loggers",
    quartzjobs = "quartz/jobs",
    quartztriggers = "quartz/triggers",
    threaddump = "threaddump"
}

export async function getActuatorEndpoint(endpoint: ActuatorEndpoint) {
    try {
        const response = await fetchWithToken(getBaseUrl() + endpoint.valueOf(), {
            method: "GET"
        })
        return response
    } catch (error) {
        throw new Error("Error")
    }
}

export function getHeapDumpUrl() {
    return getBaseUrl() + "heapdump"
}

export async function setLoggerLevel(loggerName: string, loglevel: string) {
    const patchObj = {
        configuredLevel: loglevel
    }
    try {
        const response = await fetchWithToken(getBaseUrl() + "loggers/" + loggerName, {
            method: "POST",
            body: JSON.stringify(patchObj),
            headers: {
                "Content-Type": "application/json",
            },
        })
        return response
    } catch (error) {
        throw new Error("Error")
    }
}