import React from "react";
import {AnalyticsOverview} from "../../components/Analytics/AnalyticsOverview";
import {campaignOverview} from "../../util/DemoData";

const DemoAnalyticsOverviewPage: React.FC = () => {


    const campaigns = campaignOverview
    return (
        <AnalyticsOverview campaigns={campaigns}/>
    )
}

export default DemoAnalyticsOverviewPage
