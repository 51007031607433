import {ReceiverGroup} from "./ReceiverGroup"

export type Receiver = {
    receiverId?: string,
    emailAddress: string
    firstName: string,
    lastName: string,
    gender: string,
    receiverGroups: ReceiverGroup[]
}

export enum Gender {
    MALE= "m",
    FEMALE = "f",
    DIVERS = "x"
}