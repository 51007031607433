import { Dialog, DialogTitle, DialogContentText, TextField, DialogContent, DialogActions, Button } from "@mui/material";
import { useRef, useState } from "react";
import CustomTextField from "../../Input/CustomTextfield";
import { useTranslation } from "react-i18next";


const DateDialog: React.FC<{ open: boolean, onClose: any }> = (props) => {
  const { t } = useTranslation();
    const { open, onClose } = props;
    const handleClose = () => {
      onClose("cancelled");
    };

    const [errorStates, setErrorStates] = useState({});
    const [hasError, setHasError] = useState<boolean>(false);

    // Callback function to update the error state for a specific field
    const handleTextFieldError = (name: string, errorState: boolean) => {
        setErrorStates(prevErrorStates => {
            const updatedErrorStates = {
                ...prevErrorStates,
                [name]: errorState,
            };
    
            // Now checking inside the update function, where we have the updated state
            const anyError = Object.values(updatedErrorStates).some(error => error);
            setHasError(anyError);
    
            return updatedErrorStates;
        });
    };

    const handleAdd = (value: string) => {
      if (value.match(/^-?\d+$/)) {
        onClose(value);
        setDayCount("");
      }
    }

    const [dayCount, setDayCount] = useState("");
    const handleDayCountChange = (event: any) => {
      setDayCount(event.target.value);
    }

return (
    <Dialog open={open} onClose={handleClose}>
    <DialogTitle>{t('dateDialog.title')}</DialogTitle>
    <DialogContent>
      <DialogContentText>
      {t('dateDialog.text')}
      </DialogContentText>
      <CustomTextField name="dayCount" label={t('dateDialog.label')} defaultValue="" value={dayCount} onChange={handleDayCountChange} regEx={/^-?\d+$/} helper={t('dateDialog.helper')} onErrorChange={handleTextFieldError}/>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose}>{t('dateDialog.cancel')}</Button>
      <Button disabled={hasError} onClick={() => handleAdd(dayCount)}>{t('dateDialog.addPlaceholder')}</Button>
    </DialogActions>
  </Dialog>
)
}

export default DateDialog;