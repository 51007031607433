import React from 'react';
import TemplateTagMultiSelect from './TemplateTagMultiSelect'; // Make sure to provide the correct path
import { TemplateTagGroup } from '../../model/TemplateTag';
import { useTranslation } from 'react-i18next';

interface TemplateTagGroupsSelectProps {
  templateTagGroups: TemplateTagGroup[]; // Replace TemplateTagGroup with the actual type of your template tag groups
  selectedTemplateTagGroups: TemplateTagGroup[]; // Replace TemplateTagGroup with the actual type of your selected template tag groups
  setSelectedTemplateTagGroups: React.Dispatch<React.SetStateAction<TemplateTagGroup[]>>;
}

const TemplateTagGroupSelect: React.FC<TemplateTagGroupsSelectProps> = ({
  templateTagGroups,
  selectedTemplateTagGroups,
  setSelectedTemplateTagGroups,
}) => {
  const { t } = useTranslation();
  return (
    <>
      {templateTagGroups.map((group) => {
        const selectedGroup = selectedTemplateTagGroups.find((selectedGroup) => selectedGroup.type === group.type);
        const selectedValues: string[] = selectedGroup ? selectedGroup.tags.map((tag) => tag.templateTagId!) : [];

        return (
          <TemplateTagMultiSelect
            key={`${group.type}tags`}
            id={`${group.type}tags`}
            label={`${t('templateTagGroupSelect.labelStart')} ${group.type} ${t('templateTagGroupSelect.labelEnd')}`}
            name={`${group.type}tags`}
            value={selectedValues}
            onChange={(newValue) => {
              // Update the selectedTemplateTagGroups state
              const updatedGroup: TemplateTagGroup = {
                type: group.type,
                tags: group.tags.filter((tag) => newValue.includes(tag.templateTagId!)),
              };
              // Find the index of the group in selectedTemplateTagGroups
              const groupIndex = selectedTemplateTagGroups.findIndex((selectedGroup) => selectedGroup.type === group.type);
              // Create a copy of the selectedTemplateTagGroups array
              const updatedGroups = [...selectedTemplateTagGroups];
              // Update the group at the found index or push the new group if not found
              if (groupIndex !== -1) {
                updatedGroups[groupIndex] = updatedGroup;
              } else {
                updatedGroups.push(updatedGroup);
              }
              setSelectedTemplateTagGroups(updatedGroups);
            }}
            options={group.tags}
          />
        );
      })}
    </>
  );
};

export default TemplateTagGroupSelect;