import {Await, defer, json, useLoaderData} from "react-router-dom";
import React, {Suspense} from "react";
import {TemplateTag} from "../../model/TemplateTag";
import {getTemplateTag} from "../../api/templateTagClient";
import TemplateTagEditor from "../../components/TemplateTag/TemplateTagEditor";
import {loadTemplateTagGroups} from "./TemplateTagManager";
import {CircularProgress} from "@mui/material";

const EditTemplateTagPage: React.FC = (props) => {
    const {
        templateTag,
        templateTagGroups
    } = useLoaderData() as { templateTag: TemplateTag, templateTagGroups: TemplateTag[] };


    return (
        <Suspense fallback={<CircularProgress/>}>
            <Await resolve={Promise.all([templateTag, templateTagGroups])}>
                {data => <TemplateTagEditor method="PUT" templateTag={data[0]} templateTagGroups={data[1]}/>}
            </Await>
        </Suspense>
    )
}

export default EditTemplateTagPage;

async function loadTemplateTag(templateTagId: string, customerId: string) {
    const response = await getTemplateTag(templateTagId, customerId);

    if (!response.ok) {
        throw json({message: 'Could not fetch templateTag.'}, {status: response.status});
    } else {
        const resData = await response.json();
        return resData
    }
}

export async function loader({
                                 request,
                                 params
                             }: { request: Request, params: { templateTagId: string, customerId: string } }) {
    const templateTagId = params.templateTagId;
    const customerId = params.customerId;
    return defer({
        templateTag: loadTemplateTag(templateTagId, customerId),
        templateTagGroups: loadTemplateTagGroups()
    })
}