import React, {Suspense} from "react";
import {Await, defer, json, useLoaderData} from "react-router-dom";
import {AnalyticsOverview} from "../../components/Analytics/AnalyticsOverview";
import {getCustomerOverviewAnalytics} from "../../api/analyticsClient";
import {CampaignAnalyticsSummary} from "../../model/CampaignAnalyticsSummary";
import {CircularProgress} from "@mui/material";

const AnalyticsOverviewPage: React.FC = () => {

    const {campaigns} = useLoaderData() as { campaigns: CampaignAnalyticsSummary[] };
    return (
        <Suspense fallback={<CircularProgress/>}>
            <Await resolve={campaigns}>
                {loadedCampaigns => <AnalyticsOverview campaigns={loadedCampaigns}/>}
            </Await>
        </Suspense>
    )
}

export default AnalyticsOverviewPage

async function loadCustomerOverviewAnalytics(id: string) {
    const response = await getCustomerOverviewAnalytics(id);

    if (!response.ok) {
        throw json({message: 'Could not fetch customercampaigns.'}, {status: response.status});
    } else {
        const resData = await response.json();
        return resData
    }
}

export async function loader({request, params}: { request: Request, params: { customerId: string } }) {
    const id = params.customerId;

    return defer({
        campaigns: loadCustomerOverviewAnalytics(id)
    })
}