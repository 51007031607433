import {createTheme} from '@mui/material/styles'

const primaryColor = {
    main: '#a0aed9',
    contrastText: '#ffffff'
}


let theme = createTheme({
    palette: {
        primary: primaryColor,

    },
    typography: {
        fontFamily: 'Roboto, sans-serif',
    },
    components: {
        MuiCard: {
            styleOverrides: {
                root: {
                    borderRadius: '20px',
                },

            }
        },
        MuiCardHeader: {
            styleOverrides: {
                root: {
                    padding: "20px 40px",
                    textAlign: "left",
                    color: "black",
                },
                title: {
                    fontSize: "1rem",
                    fontFamily: "Oswald"
                }
            }
        },
        MuiCircularProgress: {
            styleOverrides: {
                root: {
                    margin: "auto"
                }
            }
        }
    }
})

export const muiTheme = createTheme(theme, {
    palette: {
        click: theme.palette.augmentColor({
            color: {
                main: "#66B3FF",
            },
            name: 'click'
        }),
        interaction: theme.palette.augmentColor({
            color: {
                main: "#FFB666",
            },
            name: 'interaction'
        }),
        reporting: theme.palette.augmentColor({
            color: {
                main: "#005CB8",
            },
            name: 'reporting'
        }),
    }
})
