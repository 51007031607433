import React, {useState} from "react";
import {Alert, AlertColor, Snackbar, SnackbarOrigin} from "@mui/material";
import ReactDOM from "react-dom";

const emptyData: {
    content: string,
    severity: AlertColor | undefined,
    anchorOrigin?: SnackbarOrigin,
    autoHideDuration?: number
} = {
    content: "",
    severity: undefined,
    anchorOrigin: undefined,
    autoHideDuration: undefined,
}

export function useSnackbarAlert(): [JSX.Element, (content: string, severity: AlertColor, anchorOrigin?: SnackbarOrigin, autoHideDuration?: number) => void] {
    const [open, setOpen] = useState(false);
    const [data, setData] = useState(emptyData);

    const handleClose = () => {
        setOpen(false);
    }

    const snackbar = ReactDOM.createPortal(
        <Snackbar open={open}
                  autoHideDuration={data.autoHideDuration ?? 6000}
                  onClose={handleClose}
                  anchorOrigin={data.anchorOrigin ?? {vertical: "top", horizontal: "right"}}
                  style={{position: 'fixed'}}
        >
            <Alert onClose={handleClose} severity={data.severity}>{data.content}</Alert>
        </Snackbar>,
        document.body);

    const openSnackbarAlert = (content: string, severity: AlertColor, anchorOrigin?: SnackbarOrigin, autoHideDuration?: number) => {
        setData({
            content: content,
            severity: severity,
            anchorOrigin: anchorOrigin,
            autoHideDuration: autoHideDuration,
        });
        setOpen(true);
    }

    return [snackbar, openSnackbarAlert]

}