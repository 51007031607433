import { json, useNavigate, useNavigation, useParams } from 'react-router-dom';
import { deleteCampaign, updateCampaignStatus, } from '../../api/campaignClient';
import classes from '../../styling/components/DisplayCampaign.module.css';
import { Campaign, Status } from '../../model/Campaign';
import { ReceiverGroup } from '../../model/ReceiverGroup';
import { Box, Button, FormControlLabel, Switch, TextField } from '@mui/material';
import React, { useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { Template } from '../../model/Template';
import { Domain } from '../../model/Domain';
import DomainUpdate from './CampaignUpdate/DomainUpdate';
import TemplateUpdate from './CampaignUpdate/TemplateUpdate';
import ReceiverGroupUpdate from './CampaignUpdate/ReceiverGroupUpdate';
import CampaignDateInput from './CampaignDateInput';
import CampaignTimeInput from './CampaignTimeInput';
import { useTranslation } from 'react-i18next';
import { useConfirmationDialog } from "../../hooks/useConfirmationDialog";
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const DisplayCampaign: React.FC<{ campaign: Campaign, receiverGroups: ReceiverGroup[], templates: Template[], domains: Domain[] }> = (props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const navigation = useNavigation();
    const { customerId } = useParams();
    const isSubmitting = navigation.state === 'submitting';
    const domains: Domain[] = props.domains;
    const senderDomains = domains.filter((domain) => domain.type.localeCompare("Sender") === 0);
    const standardDomains = domains.filter((domain) => domain.type.localeCompare("Standard") === 0);
    const mailFromDomains = domains.filter((domain) => domain.type.localeCompare("mailFrom") === 0);
    // Implement landingpageDomains later
    // const landingPageDomains = domains.filter((domain) => domain.type.localeCompare("Landingpage") === 0);
    //Get initial campaignDomains
    let campaignSenderDomains: Domain[] = [];
    let campaignStandardDomains: Domain[] = [];
    let campaignMailFromDomains: Domain[] = [];
    if (props.campaign) {
        campaignSenderDomains = props.campaign.domains.filter((domain) => domain.type.localeCompare("Sender") === 0);
        campaignStandardDomains = props.campaign.domains.filter((domain) => domain.type.localeCompare("Standard") === 0);
        campaignMailFromDomains = props.campaign.domains.filter((domain) => domain.type.localeCompare("mailFrom") === 0);
    }
    //Enable editing only if running or paused. Disabled if cancelled,ready or finished
    const [enableEdit, setEnableEdit] = useState<boolean>((props.campaign.status === 'Running' || props.campaign.status === 'Paused') ? true : false);
    function goBackHandler() {
        navigate('..');
    }

    //Confirmation Dialog for cancelling or deleting campaign
    const [confirmationDialog, openConfirmationDialog] = useConfirmationDialog()
    const confirmCancel = () => {
        openConfirmationDialog(t('displayCampaign.cancelConfirmationTitle'), t('displayCampaign.cancelConfirmationContent'), cancelHandler)
    }
    const confirmDelete = () => {
        openConfirmationDialog(t('displayCampaign.deleteConfirmationTitle'), t('displayCampaign.deleteConfirmationContent'), deleteHandler)
    }
    async function cancelHandler() {
        const response = await updateCampaignStatus({ status: Status.CANCELLED }, props.campaign.campaignId!, customerId!);
        if (!response.ok) {
            throw json({ message: `${t('displayCampaign.cancelError')}` }, { status: 500 });
        }
        else {
            return navigate('..');
        }
    }
    async function deleteHandler() {
        const response = await deleteCampaign(props.campaign.campaignId!, customerId!);
        if (!response.ok) {
            throw json({ message: 'Could not delete customer.' }, { status: 500 });
        }
        else {
            return navigate('..');
        }
    }
    //Transform SendDays to String
    const getSelectedDaysString = () => {
        const daysOfWeek = [`${t('displayCampaign.monday')}`, `${t('displayCampaign.tuesday')}`, `${t('displayCampaign.wednesday')}`, `${t('displayCampaign.thursday')}`, `${t('displayCampaign.friday')}`, `${t('displayCampaign.saturday')}`, `${t('displayCampaign.sunday')}`];
        const selectedDays = daysOfWeek.filter((day, index) => props.campaign.sendDays.charAt(index) === '1');
        return selectedDays.join(',');
    };

    const [campaignTime, setCampaignTime] = useState({
        dayStartTime: dayjs(props.campaign ? `2000-01-01T${props.campaign.dayStartTime}` : '2000-01-01T09:00'),
        dayEndTime: dayjs(props.campaign ? `2000-01-01T${props.campaign.dayEndTime}` : '2000-01-01T17:00'),
    });

    const handleTimeChange = (field: string, newValue: Dayjs) => {
        setCampaignTime((prevCampaignTime) => ({
            ...prevCampaignTime,
            [field]: newValue,
        }));
    }

    return (
        <>
            {confirmationDialog}
            <Box className={classes.card}>
                <Box className={classes.readOnly}>
                    <div>
                        <TextField id="name" label={t('displayCampaign.nameLabel')} name="name" margin="normal" type="text" fullWidth value={props.campaign.name} />
                    </div>
                    <CampaignDateInput campaign={props.campaign ? props.campaign : undefined} />
                    <CampaignTimeInput dayStartTime={campaignTime.dayStartTime} dayEndTime={campaignTime.dayEndTime} onTimeChange={handleTimeChange} campaign={props.campaign ? props.campaign : undefined} />
                    <div>
                        <TextField id="sendDays" label={t('displayCampaign.sendDaysLabel')} name="sendDays" margin="normal" type="text" fullWidth value={getSelectedDaysString()} />
                    </div>
                    <FormControlLabel control={<Switch name="baselineEnabled" disabled={true} checked={props.campaign.baselineEnabled}
                    />}
                        label={t('displayCampaign.enableBaseline')} />
                    {props.campaign.baselineEnabled &&
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label={t('displayCampaign.baselineEndDate')}
                                    format="DD.MM.YYYY"
                                    value={dayjs.utc(props.campaign.baselineEndDate)}
                                />
                            </LocalizationProvider>
                    }
                </Box>
            </Box>
            <Box className={classes.card}>
                <ReceiverGroupUpdate receiverGroups={props.receiverGroups} campaign={props.campaign} enableEdit={enableEdit} />
            </Box>
            <Box className={classes.card}>
                <DomainUpdate
                    campaignId={props.campaign.campaignId!}
                    campaignSenderDomains={campaignSenderDomains}
                    campaignStandardDomains={campaignStandardDomains}
                    campaignMailFromDomains={campaignMailFromDomains}
                    senderDomains={senderDomains}
                    standardDomains={standardDomains}
                    mailFromDomains={mailFromDomains}
                    enableEdit={enableEdit}
                />
            </Box>
            <Box className={classes.card}>
                <TemplateUpdate templates={props.templates} campaign={props.campaign} enableEdit={enableEdit} baselineEnabled={props.campaign.baselineEnabled} />
            </Box>
            <Box className={classes.card}>
                <Button type="button" variant="outlined" onClick={goBackHandler} disabled={isSubmitting}>{t('displayCampaign.backToOverview')}</Button>
                {(props.campaign.status === 'Running' || props.campaign.status === 'Paused') &&
                    <>
                        <Button type="button" variant="contained" onClick={confirmCancel}
                            disabled={isSubmitting}>{t('displayCampaign.cancelCampaign')}</Button>
                    </>
                }
                {(props.campaign.status === 'Cancelled' || props.campaign.status === 'In configuration') &&
                    <>
                        <Button type="button" variant="contained" onClick={confirmDelete}
                            disabled={isSubmitting}>{t('displayCampaign.deleteCampaign')}</Button>
                    </>
                }
            </Box>
        </>
    );
}

export default DisplayCampaign;