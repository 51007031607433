// ReceiverGroupUpdate.tsx
import React, {useState} from 'react';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import {Campaign, CampaignReceiverGroups} from '../../../model/Campaign';
import {updateCampaignReceiverGroups} from '../../../api/campaignClient';
import ConfirmReceiverGroupDialog from './ConfirmReceiverGroupDialog';
import {ReceiverGroup} from '../../../model/ReceiverGroup';
import CampaignReceiverGroupSelect from '../CampaignReceiverGroupSelect';
import { useTranslation } from 'react-i18next';
import {useParams} from "react-router-dom";

interface ReceiverGroupUpdateProps {
  receiverGroups: ReceiverGroup[];
  campaign: Campaign;
  enableEdit: boolean;
}

const ReceiverGroupUpdate: React.FC<ReceiverGroupUpdateProps> = ({ receiverGroups, campaign, enableEdit }) => {
  const {customerId} = useParams();
  const { t } = useTranslation();
  const [confirmReceiverGroupDialogOpen, setConfirmReceiverGroupDialogOpen] = useState<boolean>(false);
  const [checkedReceiverGroups, setCheckedReceiverGroups] = useState(
    receiverGroups.map((receiverGroup) => ({
      checked: campaign.receiverGroups.some(
        (campaignReceiverGroup) => campaignReceiverGroup.receiverGroupId === receiverGroup.receiverGroupId
      ), value: receiverGroup.receiverGroupId
    }))
  );
  const [updateReceiverGroupsError, setUpdateReceiverGroupsError] = useState<boolean>(false);
  const [updateReceiverGroupsSuccessfully, setUpdateReceiverGroupsSuccessfully] = useState<boolean>(false);

  const handleReceiverGroupChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const nextChecked = checkedReceiverGroups.map((c, i) => {
      if (i === index) {
        return { ...c, checked: event.target.checked };
      } else {
        return c;
      }
    });
    console.log(nextChecked);
    setCheckedReceiverGroups(nextChecked);
  };

  const updateReceiversHandler = () => {
    setConfirmReceiverGroupDialogOpen(true);
  };

  const handleReceiverGroupDialogClose = () => {
    setConfirmReceiverGroupDialogOpen(false);
  };

  const updateReceivers = async () => {
    setUpdateReceiverGroupsError(false);
    setUpdateReceiverGroupsSuccessfully(false);
    const updateReceiverGroups: CampaignReceiverGroups = checkedReceiverGroups
      .filter((item) => item.checked)
      .map((item) => ({ receiverGroupId: item.value })) as unknown as CampaignReceiverGroups;
    console.log(updateReceiverGroups);
    if (updateReceiverGroups.length > 0) {
      const response = await updateCampaignReceiverGroups(updateReceiverGroups, campaign.campaignId!, customerId!);
      if (!response.ok) {
        setUpdateReceiverGroupsError(true);
      } else {
        setUpdateReceiverGroupsSuccessfully(true);
      }
      setConfirmReceiverGroupDialogOpen(false);
    } else {
      setUpdateReceiverGroupsError(true);
      setConfirmReceiverGroupDialogOpen(false);
    }
  };

  return (
    <div>
      <CampaignReceiverGroupSelect receiverGroups={receiverGroups} checked={checkedReceiverGroups.map((receiverGroup) => receiverGroup.checked)} handleChange={handleReceiverGroupChange} enableEdit={enableEdit}/>
      <div>
        {enableEdit && <Button type="button" variant="outlined" onClick={updateReceiversHandler}>
          {t('receiverGroupUpdate.updateReceivers')}
        </Button>}
      </div>
      <ConfirmReceiverGroupDialog
        open={confirmReceiverGroupDialogOpen}
        onClose={handleReceiverGroupDialogClose}
        onContinue={updateReceivers}
        selectedReceiverGroups={checkedReceiverGroups
          .map((item, index) => item.checked && receiverGroups[index].name)
          .filter(Boolean)}
      />
      {updateReceiverGroupsSuccessfully && (
        <Alert onClose={() => setUpdateReceiverGroupsSuccessfully(false)} severity="success">
          {t('receiverGroupUpdate.updatedSuccessfully')}
        </Alert>
      )}
      {updateReceiverGroupsError && (
        <Alert onClose={() => setUpdateReceiverGroupsError(false)} severity="error">
          {t('receiverGroupUpdate.updatedError')}
        </Alert>
      )}
    </div>
  );
};

export default ReceiverGroupUpdate;

