import React from 'react';
import {DataGrid, GridColDef, GridRowSelectionModel, GridValueGetterParams} from '@mui/x-data-grid';
import {Template} from '../../model/Template';
import classes from '../../styling/components/Table.module.css';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {SendTestmailButton} from "./SendTestmailButton";
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';

interface Props {
    templates: Template[],
    selectedTemplateIds: string[],
    onSelectionChange?: (newSelection: string[]) => void,
    readonly?: boolean,
    extendedFields?: boolean
}

const TemplateTable: React.FC<Props> = (props) => {

    const {templates, selectedTemplateIds, onSelectionChange, readonly, extendedFields} = props;

    const {t} = useTranslation();

    const rows = templates.slice().sort((a, b) => {
        const aSelected = selectedTemplateIds.includes(a.templateId!);
        const bSelected = selectedTemplateIds.includes(b.templateId!);
        return aSelected === bSelected ? 0 : aSelected ? -1 : 1;
    })

    const extendedColumns: GridColDef[] = extendedFields ? [
        {field: 'replyToAddress', headerName: `${t('templateTable.replyToAddress')}`, width: 130},
        {
            field: 'testmail', headerName: `${t('templateTable.testmail')}`, width: 70,
            valueGetter: (params: GridValueGetterParams) => params.row.templateId,
            renderCell: (params) => (
                <SendTestmailButton templateId={params.value}/>
            )
        },
        {
            field: 'edit', headerName: `${t('templateTable.edit')}`, width: 70,
            valueGetter: (params: GridValueGetterParams) => params.row.templateId,
            renderCell: (params) => (
                <Link to={`${params.value}`}><EditIcon/></Link>
            )
        },
        {
            field: 'preview', headerName: `${t('templateTable.preview')}`, width: 70,
            valueGetter: (params: GridValueGetterParams) => params.row.templateId,
            renderCell: (params) => (
                <Link to={`${params.value}/preview`} target="_blank"><VisibilityIcon/></Link>
            )
        }
    ] : []

    const columns: GridColDef[] = [
        {field: 'name', headerName: `${t('templateTable.name')}`, width: 130},
        {field: 'subject', headerName: `${t('templateTable.subject')}`, width: 130},
        {field: 'senderAddress', headerName: `${t('templateTable.senderAddress')}`, width: 130},
        ...extendedColumns
    ];

    const handleSelectionChange = (newSelection: GridRowSelectionModel) => {
        if (!readonly && !!onSelectionChange) {
            onSelectionChange(newSelection as string[]);
        }
    }

    return (
        <>
            <div className={`${extendedFields ? classes.templateTableLarge : classes.templateTableSmall}`}>
                <DataGrid
                    rows={rows}
                    getRowId={(row) => row.templateId}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: {page: 0, pageSize: 5},
                        },
                    }}
                    pageSizeOptions={[5, 10]}
                    checkboxSelection={!readonly}
                    {...!readonly ? {
                        rowSelectionModel: selectedTemplateIds,
                        onRowSelectionModelChange: handleSelectionChange
                    } : {}}
                />
            </div>
        </>
    );
}

export default TemplateTable;