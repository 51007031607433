import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import classes from '../../styling/components/Editor.module.css';
import { TemplateTag } from '../../model/TemplateTag';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const TemplateTagMultiSelect: React.FC<{ id: string, label: string, name: string, value: string[], onChange: (newValue: string[]) => void,options: TemplateTag[] }> = (props) => {
    const [item, setItem] = React.useState<any>(props.value);
    React.useEffect(() => {
        setItem(props.value);
    }, [props.value]);
    const handleChange = (event: SelectChangeEvent<typeof item>) => {
        const {
            target: { value },
        } = event;

        setItem(value);
        props.onChange(value);
    };

    return (
        <FormControl className={classes.formControlSideBySide}>
            <InputLabel id={props.id + "label"}>{props.label}</InputLabel>
            <Select
                labelId={props.id + "label"}
                id={props.id}
                name={props.name}
                multiple
                value={item}
                onChange={handleChange}
                input={<OutlinedInput label={props.label} />}
                renderValue={(selected) => selected.map((selected:any) => props.options.filter((option) => selected === option.templateTagId).map((option) => option.name)).join(', ')}
                MenuProps={MenuProps}
            >
                {props.options.map((option) => (
                    <MenuItem key={option.templateTagId} value={option.templateTagId}>
                        <Checkbox checked={item.indexOf(option.templateTagId) > -1} />
                        <ListItemText primary={option.name} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

export default TemplateTagMultiSelect;