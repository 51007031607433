import React from "react";
import {Table, TableBody, TableCell, TableRow} from "@mui/material";
import classes from "../../../styling/components/analytics/ClickRateTable.module.css";
import {useTranslation} from "react-i18next";
import {toPct} from "../../../util/Calc";
import {ProgressBar} from "./ProgressBar";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import {Link, useParams} from "react-router-dom";

type Props = {
    stats: {
        name: string,
        templateId: string,
        sent: number,
        clicked: number
    }[],
    cutOff?: number
}

export const ClickRateTable: React.FC<Props> = (props) => {

    const {stats} = props
    const {t} = useTranslation()
    const {customerId} = useParams()
    const cutOff = props.cutOff ? props.cutOff : stats.length

    const rows = stats.map(stat => {
        return {
            name: stat.name,
            templateId: stat.templateId,
            data: {
                sent: stat.sent,
                clicked: stat.clicked,
                rate: toPct(stat.clicked, stat.sent)
            }
        }
    }).sort((a, b) => {
        if (a.data.rate < b.data.rate)
            return 1
        if (a.data.rate > b.data.rate)
            return -1
        return 0
    }).slice(0, cutOff)


    return (
        <Table className={classes.dataTable + (props.cutOff ? ` ${classes.collapsed}` : ` ${classes.extended}`)}>
            <TableBody>
                {rows.map((row, index) => {
                    return (
                        <TableRow key={row.name}>
                            <TableCell>{index + 1}.</TableCell>
                            <TableCell>
                                {row.name}
                                {row.templateId !== "demoTemplate" ?
                                    <Link to={`/customers/${customerId}/templates/${row.templateId}/preview`}
                                          target="_blank" rel="noopener noreferrer">
                                        <OpenInNewIcon fontSize={"inherit"}
                                                       style={{verticalAlign: "center", marginLeft: "7px"}}/>
                                    </Link> : null
                                }
                            </TableCell>
                            <TableCell>
                                <ProgressBar color={'click'} value={row.data.rate}
                                             textColor={"black"}
                                             tooltipContent={`${t("campaignAnalyticsView.clickRate")}: ${row.data.clicked}/${row.data.sent} (${row.data.rate}%)`}/>
                            </TableCell>
                        </TableRow>
                    )
                })}
            </TableBody>
        </Table>
    )
}