import { Alert, Box, Button, Typography } from "@mui/material";
import React, { useState } from "react";
import { json, useNavigate, useParams } from "react-router-dom";
import classes from "../../styling/components/CustomerLogoUpload.module.css";
import { useTranslation } from "react-i18next";
import { createCustomerLogo, deleteCustomerLogo } from "../../api/brandingClient";

interface CustomerLogoUploadProps {
    logo: any;
}


const CustomerLogoUpload: React.FC<CustomerLogoUploadProps> = (props) => {
    const { t } = useTranslation();
    const [uploadLogoButtonDisabled, setUploadLogoButtonDisabled] = useState(true);
    const [filetypeError, setFileTypeError] = useState(false);
    const { customerId } = useParams();
    const navigate = useNavigate();
    const [file, setFile] = useState<File | null>(null);

    const [logo, setLogo] = useState(props.logo);

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files![0].name.match(/.+\.(jpg|jpeg|png|svg)$/i)) {
            setUploadLogoButtonDisabled(false);
            setFileTypeError(false);
            setLogoUploadError(false);
            setLogoUploadSuccessful(false);
            setFile(event.target.files![0]);
        } else {
            setUploadLogoButtonDisabled(true);
            setFileTypeError(true);
        }

    };

    const [logoUploadError, setLogoUploadError] = useState<boolean>(false);
    const [logoUploadErrorText, setLogoUploadErrorText] = useState<string>(`${t('customerLogoUpload.uploadLogoError')}`);
    const [logoUploadSuccessful, setLogoUploadSuccessful] = useState<boolean>(false);
    const [logoUploadSuccessfulText, setLogoUploadSuccessfulText] = useState<string>(`${t('customerLogoUpload.uploadLogoSuccessful')}`);
    const handleOnSubmit = async (event: React.MouseEvent) => {
        if (file) {
            const formData = new FormData();
            formData.append('file', file);
            const response = await createCustomerLogo(customerId!, formData);
            if (!response.ok) {
                throw json({ message: `${t('customerLogoUpload.uploadLogoError')}` }, { status: 500 });
            } else {
                setLogoUploadSuccessful(true);
                const logo = await response.blob();
                setLogo(logo);
            }
        }
    };

    const deleteLogo = async () => {
        const response = await deleteCustomerLogo(customerId!);
        if (!response.ok) {
            throw json({ message: 'Could not delete customerLogo.' }, { status: 500 });
        }
        else {
            setLogo(null);
            return;
        }
    }

    return (
        <Box className={classes.container}>
            {logo != null ? (
                logo.type === 'image/svg+xml' ? (<>
                    <Box>
                        <object className={classes.logo} type="image/svg+xml" data={URL.createObjectURL(logo)}>
                            {t('customerLogoUpload.svgNotSupported')}
                        </object>
                    </Box>
                    <Button variant="contained" className={classes.delete}
                        onClick={deleteLogo}
                    >
                        {t('customerLogoUpload.deleteLogo')}
                    </Button>
                </>
                ) : (
                    <>
                        <Box>
                            <img className={classes.logo} src={URL.createObjectURL(logo)} alt="Logo" />
                        </Box>
                        <Button variant="contained" className={classes.delete}
                            onClick={deleteLogo}
                        >
                            {t('customerLogoUpload.deleteLogo')}
                        </Button>
                    </>
                )
            ) : (
                <p>{t('customerLogoUpload.noLogoYet')}</p>
            )}
            <input
                type={"file"}
                id={"customerLogo"}
                accept={"image/*"}
                onChange={handleOnChange}
            />
            <Button variant="contained"
                disabled={uploadLogoButtonDisabled}
                onClick={(event) => {
                    handleOnSubmit(event);
                }}
            >
                {t('customerLogoUpload.uploadLogo')}
            </Button>
            {logoUploadError && <Alert onClose={() => { setLogoUploadError(false) }} severity="error">{logoUploadErrorText}</Alert>}
            {logoUploadSuccessful && <Alert onClose={() => {
                setLogoUploadSuccessful(false)
            }} severity="success">{logoUploadSuccessfulText}</Alert>}
            {filetypeError && <Alert className={classes.alert} severity="error">{t('customerLogoUpload.filetypeError')}</Alert>}
        </Box>
    );
}

export default CustomerLogoUpload;