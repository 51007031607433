import React, {useContext, useEffect, useState} from "react";
import {OrganizationLevel} from "../../model/enum/OrganizationLevel";
import {User} from "../../model/User";
import {Box, Button, Card, CardContent, TextField, ToggleButton, ToggleButtonGroup} from "@mui/material";
import {Form, json, redirect, useActionData, useNavigation} from "react-router-dom";
import classes from "../../styling/components/UserEditor.module.css";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select, {SelectChangeEvent} from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {
    createCustomerUser,
    createInstanceUser,
    createSystemUser,
    editCustomerUser,
    editInstanceUser,
    editSystemUser
} from "../../api/userClient";
import {UserContext} from "../../App";
import {useSnackbarAlert} from "../../hooks/useSnackbarAlert";
import {useTranslation} from "react-i18next";
import {NavigationHeader} from "../Navigation/NavigationHeader";
import Divider from "@mui/material/Divider";

interface Props {
    method: string,
    availableRoles: string[],
    level: OrganizationLevel,
    user?: User
}

export const UserEditor: React.FC<Props> = (props) => {
    const {method, availableRoles, level, user} = props

    const {t} = useTranslation()
    const navigation = useNavigation();
    const isSubmitting = navigation.state === 'submitting';
    const [selectedRole, setSelectedRole] = useState(user?.role)

    const handleRoleChange = (event: SelectChangeEvent) => {
        setSelectedRole(event.target.value as string);
    };

    const availableLanguages = ["en", "de"]
    const [language, setLanguage] = useState(user?.language ?? "de")
    const handleLanguageChange = (event: SelectChangeEvent) => {
        setLanguage(event.target.value)
    }

    const {user: loggedInUser, setUser} = useContext(UserContext)
    const actionData = useActionData();
    const [snackbar, openSnackbarAlert] = useSnackbarAlert();

    useEffect(() => {
        if (actionData) {
            openSnackbarAlert(t('userEditor.userUpdated'), "success")
        }
    }, [actionData]);

    const navigationPath = [
        {url: "", label: "Account"}
    ]

    return (
        <div className={classes.userEditor}>
            <NavigationHeader path={navigationPath}/>
            {snackbar}
            <Card>
                <CardContent>
                    <Box component={Form} method={method as any} className={classes.form} autoComplete='off'>
                        <div className={classes.subtitle}>
                            {t('userEditor.accountInfo')}
                        </div>
                        <div className={classes.doubleInputArea}>
                            <TextField disabled={method === "PUT"} id="username" label="Username" name="username"
                                       margin="normal"
                                       type="text" fullWidth defaultValue={user?.username ?? ''}/>
                            <TextField disabled={method === "PUT"} id="emailaddress" label={t('userEditor.emailAdress')}
                                       name="emailaddress"
                                       margin="normal" type="text" fullWidth defaultValue={user?.email ?? ''}/>
                        </div>
                        <Divider/>
                        <div className={classes.subtitle}>
                            {t('userEditor.personalInfo')}
                        </div>
                        <div className={classes.doubleInputArea}>
                            <TextField id="firstname" label={t('userEditor.firstName')} name="firstname"
                                       margin="normal" type="text" fullWidth defaultValue={user?.firstName ?? ''}/>
                            <TextField id="lastname" label={t('userEditor.lastName')} name="lastname"
                                       margin="normal" type="text" fullWidth defaultValue={user?.lastName ?? ''}/>
                        </div>
                        <Divider/>
                        <div className={classes.subtitle}>
                            {t('userEditor.language')}
                        </div>
                        <div>
                            <ToggleButtonGroup
                                value={language}
                                exclusive
                                onChange={(event, newValue) => {
                                    if (newValue !== null)
                                        setLanguage(newValue)
                                }}

                            >
                                <ToggleButton value={"de"} color={"primary"}>
                                    Deutsch
                                </ToggleButton>
                                <ToggleButton value={"en"} color={"primary"}>
                                    English
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </div>
                        <div>
                            {availableRoles.length === 0 ? null :
                                <FormControl fullWidth>
                                    <InputLabel id="role-label">Roles</InputLabel>
                                    <Select
                                        labelId="role-label"
                                        id="role"
                                        name="role"
                                        value={selectedRole}
                                        label="Role"
                                        onChange={handleRoleChange}
                                    >
                                        {availableRoles.map(role => {
                                                return <MenuItem value={role}>{role}</MenuItem>
                                            }
                                        )}
                                    </Select>
                                </FormControl>
                            }
                        </div>

                        <Button type="submit" variant="contained" disabled={isSubmitting}
                                className={classes.saveButton}>{t('customerEditor.save')}</Button>

                        <input type={"hidden"} id={"language"} name={"language"} value={language}/>
                        <input type={"hidden"} id={"org"} name={"org"} value={level}/>
                    </Box>
                </CardContent>
            </Card>

        </div>
    );
}

export async function action({request, params}: { request: Request, params: any }) {
    const method = request.method;
    const data = await request.formData();
    const level = data.get('org');
    const customerId = params.customerId;
    const userId = params.userId;

    const userData = {
        username: data.get('username') as string,
        email: data.get('emailaddress') as string,
        firstName: data.get('firstname') as string,
        lastName: data.get('lastname') as string,
        language: data.get('language') as string,
        role: data.get('role') as string,
    };

    if (method === 'POST') {
        let response;
        if (level === OrganizationLevel.CUSTOMER)
            response = await createCustomerUser(userData, customerId);
        else if (level === OrganizationLevel.INSTANCE)
            response = await createInstanceUser(userData, "1")
        else
            response = await createSystemUser(userData)

        if (!response.ok) {
            throw json({message: 'Could not add user'}, {status: response.status});
        } else {
            return redirect('/');
        }
    }

    if (method === 'PUT') {
        let response;
        if (level === OrganizationLevel.CUSTOMER)
            response = await editCustomerUser(userData, customerId, userId);
        else if (level === OrganizationLevel.INSTANCE)
            response = await editInstanceUser(userData, "1", userId)
        else
            response = await editSystemUser(userData, userId)

        if (!response.ok) {
            throw json({message: 'Could not update user'}, {status: response.status});
        } else {
            return await response.json();
        }
    }
}