import React, {useState} from "react";
import {Button, TextField} from "@mui/material";
import {ActuatorEndpoint, getActuatorEndpoint, getHeapDumpUrl, setLoggerLevel} from "../../api/actuatorClient";
import {json} from "react-router-dom";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";


const LoggingLevels = [
    "OFF", "ERROR", "WARN", "INFO", "DEBUG", "TRACE"
]

export const MonitoringOverview: React.FC = () => {
    const [responseText, setResponseText] = useState("");
    const [responseTitle, setResponseTitle] = useState("");
    const [logger, setLogger] = useState("");
    const [loggingLevel, setLoggingLevel] = useState("");

    const handleFetch = async (endpoint: ActuatorEndpoint) => {
        const response = await getActuatorEndpoint(endpoint)
        if (!response.ok)
            throw json({message: 'Could not fetch monitoring endpoint.'}, {status: response.status});
        const data = await response.json();
        setResponseTitle("/actuator/" + endpoint);
        setResponseText(JSON.stringify(data, null, "\t"));
    }

    const handleDownload = () => {
        const blob = new Blob([responseText], {type: "text/plain"})
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        const name = responseTitle.slice(1)
        link.download = name + '.json';
        link.href = url;
        link.click();
    }

    const handleSetLoglevel = async () => {
        if (!!logger && !!loggingLevel) {
            const response = await setLoggerLevel(logger, loggingLevel);

        }
    }

    return (
        <div style={{backgroundColor: "lightgrey", display: "flex", flexDirection: "column", alignItems: "center"}}>
            <hr/>
            <div style={{border: "solid 1px blue", backgroundColor: "white", borderRadius: "5px"}}>
                <a style={{margin: "2px", textDecoration: "none"}} href={getHeapDumpUrl()} download="heapdump.hprof">Download
                    Heapdump</a>
            </div>
            <hr/>
            <div style={{display: "flex"}}>
                <TextField label="Logger Name" value={logger} onChange={(event) => setLogger(event.target.value)}/>
                <Select label="Logging Level" onChange={(event) => setLoggingLevel(event.target.value as string)}>
                    {LoggingLevels.map(level => <MenuItem value={level}>{level}</MenuItem>)}
                </Select>
            </div>
            <Button onClick={handleSetLoglevel}>Set Level</Button>
            <hr/>
            <div>
                <Button onClick={() => handleFetch(ActuatorEndpoint.threaddump)}>Threaddump</Button>
                <Button onClick={() => handleFetch(ActuatorEndpoint.loggers)}>Loggers</Button>
                <Button onClick={() => handleFetch(ActuatorEndpoint.quartzjobs)}>Quartz Jobs</Button>
                <Button onClick={() => handleFetch(ActuatorEndpoint.quartztriggers)}>Quartz Triggers</Button>
                <Button onClick={() => handleFetch(ActuatorEndpoint.flyway)}>Flyway Migrations</Button>
            </div>
            {!!responseText && !!responseTitle ?
                <div style={{backgroundColor: "white", width: "fit-content", border: "solid 1px black"}}>
                    <h3 style={{margin: "5px", textAlign: "center"}}>{responseTitle}</h3>
                    <Button type="button" variant="outlined" onClick={handleDownload}>Download</Button>
                    <p style={{margin: "5px", textAlign: "left"}}>
                        <pre>{responseText}</pre>
                    </p>
                </div> : null}

        </div>
    )
}