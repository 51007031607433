import { Form, json, redirect, useNavigate, useNavigation } from 'react-router-dom';
import { createTemplateTag, deleteTemplateTag, editTemplateTag } from '../../api/templateTagClient';

import classes from '../../styling/components/Editor.module.css';
import { TemplateTag, TemplateTagGroup } from '../../model/TemplateTag';
import React, { useState } from "react";
import { Autocomplete, Box, Button, TextField } from '@mui/material';
import CustomTextField from '../Input/CustomTextfield';
import { useTranslation } from 'react-i18next';

interface Props {
    method: string,
    templateTag?: TemplateTag,
    templateTagGroups: TemplateTagGroup[]
}

const TemplateTagEditor: React.FC<Props> = (props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const navigation = useNavigation();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    function cancelHandler() {
        navigate('..');
    }

    const [errorStates, setErrorStates] = useState({});
    const [hasError, setHasError] = useState<boolean>(false);

    // Callback function to update the error state for a specific field
    const handleTextFieldError = (name: string, errorState: boolean) => {
        setErrorStates(prevErrorStates => {
            const updatedErrorStates = {
                ...prevErrorStates,
                [name]: errorState,
            };
    
            // Now checking inside the update function, where we have the updated state
            const anyError = Object.values(updatedErrorStates).some(error => error);
            setHasError(anyError);
    
            return updatedErrorStates;
        });
    };

    return (
        <Box component={Form} method={props.method as any} className={classes.form} onSubmit={() => setIsSubmitting(true)} autoComplete='off'>
            <CustomTextField name="name" label={t('templateTagEditor.nameLabel')} defaultValue={props.templateTag ? props.templateTag.name : ''} regEx={/^[a-zA-ZÀ-ÖØ-öø-ÿ-\s]+$/} helper={t('templateTagEditor.nameHelper')} onErrorChange={handleTextFieldError}/>
                <Autocomplete
                    id="type"
                    freeSolo
                    options={props.templateTagGroups.map((option) => option.type)}
                    renderInput={(params) => <TextField {...params} name="type" label={t('templateTagEditor.typeLabel')} required fullWidth />}
                    defaultValue={props.templateTag ? props.templateTag.type : ''}
                />
            <div>
                <Button type="button" variant="outlined" onClick={cancelHandler} disabled={isSubmitting}>{t('templateTagEditor.cancel')}</Button>
                <Button type="submit" variant="contained" disabled={isSubmitting || hasError}>{t('templateTagEditor.save')}</Button>
            </div>
        </Box>
    );
}

export default TemplateTagEditor;

export async function action({ request, params }: { request: Request, params: any }) {
    const method = request.method;
    const data = await request.formData();

    const templateTagData = {
        name: data.get('name') as string,
        type: data.get('type') as string,
    };

    if (method === 'POST') {
        const response = await createTemplateTag(templateTagData);

        if (!response.ok) {
            throw json({ message: 'Could not add TemplateTag' }, { status: response.status });
        }
        else {
            return redirect('..');
        }
    }

    if (method === 'PUT') {
        const templateTagId = params.templateTagId;
        const response = await editTemplateTag(templateTagData, templateTagId);

        if (!response.ok) {
            throw json({ message: 'Could not update TemplateTag' }, { status: response.status });
        } else {
            return redirect('..');
        }
    }
}