import React, {Suspense} from "react";
import ReceiverEditor from "../../components/Receiver/ReceiverEditor";
import {Await, defer, useLoaderData} from "react-router-dom";
import {loadCustomerReceiverGroups} from "./ReceiverManager";
import {ReceiverGroup} from "../../model/ReceiverGroup";
import {Box, CircularProgress} from "@mui/material";

const NewReceiverPage: React.FC = (props) => {
    const {receiverGroups} = useLoaderData() as { receiverGroups: ReceiverGroup[] };
    return (
        <Box>
            <Suspense fallback={<CircularProgress/>}>
                <Await resolve={receiverGroups}>
                    {receiverGroups => <ReceiverEditor receiverGroups={receiverGroups} method="POST"/>}
                </Await>
            </Suspense>
        </Box>
    );
}

export default NewReceiverPage;

export async function loader({request, params}: { request: Request, params: { customerId: string } }) {
    const id = params.customerId;

    return defer({
        receiverGroups: loadCustomerReceiverGroups(id),
    })
}