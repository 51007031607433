import {TemplateTag} from "../model/TemplateTag";
import {fetchWithToken} from "./fetchWithToken";

const getBaseUrlSystem = () => {
    let url;
    switch (process.env.NODE_ENV) {
        case 'production':
            url = '/api/system/templatetags';
            break;
        case 'development':
            url = 'http://localhost:5000/api/system/templatetags';
            break;
        default:
            url = '/api/system/templatetags';
    }

    return url;
}

const getBaseUrlCustomer = (customerId: string) => {
    let url;
    switch (process.env.NODE_ENV) {
        case 'production':
            url = `/api/customers/${customerId}/templatetags`;
            break;
        case 'development':
            url = `http://localhost:5000/api/customers/${customerId}/templatetags`;
            break;
        default:
            url = `/api/customers/${customerId}/templatetags`;
    }

    return url;
}

export async function getCustomerTemplateTags(customerId: string) {
    try {
        const response = await fetchWithToken(getBaseUrlCustomer(customerId), {
            method: "GET"
        })
        return response
    } catch (error) {
        throw new Error("Error while loading TemplateTags")
    }
}

export async function listTemplateTags() {
    try {
        const response = await fetchWithToken(getBaseUrlSystem(), {
            method: "GET"
        })
        return response
    } catch (error) {
        throw new Error("Error while loading TemplateTags")
    }
}

export async function getTemplateTag(templateTagId: string, customerId: string) {
    try {
        const response = await fetchWithToken(getBaseUrlCustomer(customerId) + `/${templateTagId}`, {
            method: "GET"
        })
        return response
    } catch (error) {
        throw new Error("Error while loading TemplateTag")
    }
}

export async function createTemplateTag(templateTag: TemplateTag) {
    const { ...sendTemplateTag } = templateTag;
    try {
        const response = await fetchWithToken(getBaseUrlSystem(), {
            method: "POST",
            body: JSON.stringify(sendTemplateTag),
            headers: {
                "Content-Type": "application/json",
            },
        })
        return response
    } catch (error) {
        throw new Error("Error while posting TemplateTag")
    }
}

export async function editTemplateTag(templateTag: TemplateTag, templatTagId: string) {
    try {
        const response = await fetchWithToken(getBaseUrlSystem() + `/${templatTagId}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(templateTag)
        })
        return response
    } catch (error) {
        throw new Error("Error while Editing templateTag")
    }
}

export async function deleteTemplateTag(templateTagId: string){
    try {
        const response = await fetchWithToken(getBaseUrlSystem() + `/${templateTagId}`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
            },
        })
        return response
    } catch (error){
        throw new Error("Error while deleting templateTag")
    }
}