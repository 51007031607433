import {fetchWithToken} from "./fetchWithToken";

const getBaseUrl = () => {
    let url;
    switch (process.env.NODE_ENV) {
        case 'production':
            url = '/api/testmails';
            break;
        case 'development':
            url = 'http://localhost:5000/api/testmails';
            break;
        default:
            url = '/api/testmails';
    }

    return url;
}

export async function sendTestmail(templateId: string) {
    try {
        const response = await fetchWithToken(getBaseUrl() + `/${templateId}`, {
            method: "POST"
        })
        return response
    } catch (error) {
        throw new Error("Error while sending Testmail")
    }
}

export async function sendCampaignTestmails(campaignId: string) {
    try {
        const response = await fetchWithToken(getBaseUrl() + `/campaign/${campaignId}`, {
            method: "POST"
        })
        return response
    } catch (error) {
        throw new Error("Error while sending Testmails")
    }
}

export async function generateCampaignLearningLinks(campaignId: string) {
    try {
        const response = await fetchWithToken(getBaseUrl() + `/campaign/${campaignId}/links`, {
            method: "POST"
        })
        return response
    } catch (error) {
        throw new Error("Error while sending Testmails")
    }
}


export async function generateLearningLinks(templateIds: string[]) {
    try {
        const response = await fetchWithToken(getBaseUrl() + `/links`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(templateIds)
        })
        return response
    } catch (error) {
        throw new Error("Error while generating Learninglinks")
    }
}