export type SelfUser = {
    userId: string
    organizationLevel: OrganizationLevel | null,
    organizationId: string,
    firstName: string,
    lastName: string,
    language: string,
    role: string
}

export enum OrganizationLevel {
    CUSTOMER = "CUSTOMER",
    INSTANCE = "INSTANCE",
    SYSTEM = "SYSTEM"
}