import {Await, useLoaderData} from "react-router-dom";
import React, {Suspense} from "react";
import {TemplateTagGroup} from "../../model/TemplateTag";
import TemplateTagEditor from "../../components/TemplateTag/TemplateTagEditor";
import {CircularProgress} from "@mui/material";

const NewTemplateTagPage: React.FC = (props) => {
    const { templateTagGroups } = useLoaderData() as { templateTagGroups: TemplateTagGroup[] };

    return (
        <Suspense fallback={<CircularProgress/>}>
            <Await resolve={templateTagGroups}>
                {templateTagGroups => <TemplateTagEditor method="POST" templateTagGroups={templateTagGroups} />}
            </Await>
        </Suspense>
    )
}

export default NewTemplateTagPage;