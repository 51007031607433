import {Outlet, useLocation} from "react-router-dom";
import React, {useContext, useMemo} from "react";
import {UserContext} from "../../App";
import {OrganizationLevel} from "../../model/SelfUser";

const RootLayout:React.FC = (props) => {

    const route = useLocation()
    const {user} = useContext(UserContext)

    const authorized = useMemo(() => {
        if (route.pathname === "/")
            return true
        if (user.role === "Demo Viewer") {
            if (route.pathname.startsWith(`/customers/${user.organizationId}/demo/analytics`))
                return true
            if (route.pathname === `/customers/${user.organizationId}/settings`)
                return true
            if (route.pathname.startsWith(`/customers/${user.organizationId}/templates`) && route.pathname.endsWith("preview"))
                return true
            return false
        }
        if (user.organizationLevel === OrganizationLevel.CUSTOMER) {
            if (route.pathname.startsWith(`/customers/${user.organizationId}/analytics`))
                return true
            if (route.pathname === `/customers/${user.organizationId}/settings`)
                return true
            if (route.pathname.startsWith(`/customers/${user.organizationId}/templates`) && route.pathname.endsWith("preview"))
                return true
        }
        if (user.organizationLevel === OrganizationLevel.SYSTEM) {
            return true
        }
        return false
    }, [route, user])

    if (!authorized) {
        throw new Error()
    }


    return <>
        <main>
            {authorized ? <Outlet/> : null}
        </main>
    </>
}

export default RootLayout;