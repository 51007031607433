import {fetchWithToken} from "./fetchWithToken";
import {User} from "../model/User";


const getBaseUrl = () => {
    switch (process.env.NODE_ENV) {
        case 'production':
            return `/api/users`;
        case 'development':
            return `http://localhost:5000/api/users`;
        default:
            return `/api/users`;
    }
}

const getBaseUrlOrganisation = (id: string, org: string) => {
    let url;
    switch (process.env.NODE_ENV) {
        case 'production':
            url = `/api/${org}/${id}/users`;
            break;
        case 'development':
            url = `http://localhost:5000/api/${org}/${id}/users`;
            break;
        default:
            url = `/api/${org}/${id}/users`;
    }
    return url;
}

const getBaseUrlSystem = () => {
    let url;
    switch (process.env.NODE_ENV) {
        case 'production':
            url = `/api/system/users`;
            break;
        case 'development':
            url = `http://localhost:5000/api/system/users`;
            break;
        default:
            url = `/api/system/users`;
    }
    return url;
}

export async function getSelfUser() {
    try {
        const response = await fetchWithToken(process.env.REACT_APP_API_URL + "/users/self", {
            method: "GET",
        })
        return response
    } catch (error) {
        throw new Error("Error fetching user info")
    }
}


export async function getCustomerUser(userId: string, customerId: string) {
    return getUser(getBaseUrlOrganisation(customerId, 'customers') + `/${userId}`)
}

export async function getInstanceUser(userId: string, instanceId: string) {
    return getUser(getBaseUrlOrganisation(instanceId, 'instances') + `/${userId}`)
}

export async function getSystemUser(userId: string) {
    return getUser(getBaseUrlSystem() + `/${userId}`)
}

export async function getUser(path: string) {
    try {
        const response = await fetchWithToken(path, {
            method: "GET",
        })
        return response
    } catch (error) {
        throw new Error("Error")
    }
}

export async function listCustomerUsers(customerId: string) {
    return listUsers(getBaseUrlOrganisation(customerId, 'customers'))
}

export async function listInstanceUsers(instanceId: string) {
    return listUsers(getBaseUrlOrganisation(instanceId, 'instances'))
}

export async function listSystemUsers() {
    return listUsers(getBaseUrlSystem())
}

export async function listUsers(path: string) {
    try {
        const response = await fetchWithToken(path, {
            method: "list",
        })
        return response
    } catch (error) {
        throw new Error("Error")
    }
}

export async function createCustomerUser(user: User, customerId: string) {
    return createUser(user, getBaseUrlOrganisation(customerId, "customers"))
}

export async function createInstanceUser(user: User, instanceId: string) {
    return createUser(user, getBaseUrlOrganisation(instanceId, "instances"))
}

export async function createSystemUser(user: User) {
    return createUser(user, getBaseUrlSystem())
}

export async function createUser(user: User, path: string) {
    try {
        const response = await fetchWithToken(path, {
            method: "POST",
            body: JSON.stringify(user),
            headers: {
                "Content-Type": "application/json",
            },
        })
        return response
    } catch (error) {
        throw new Error("Error while loading campaigns")
    }
}

export async function editCustomerUser(user: User, customerId: string, userId: string) {
    return editUser(user, getBaseUrlOrganisation(customerId, "customers") + `/${userId}`)
}

export async function editInstanceUser(user: User, instanceId: string, userId: string) {
    return editUser(user, getBaseUrlOrganisation(instanceId, "instances") + `/${userId}`)
}

export async function editSystemUser(user: User, userId: string) {
    return editUser(user, getBaseUrlSystem() + `/${userId}`)
}

export async function editUser(user: User, path: string) {
    try {
        const response = await fetchWithToken(path, {
            method: "PUT",
            body: JSON.stringify(user),
            headers: {
                "Content-Type": "application/json",
            },
        })
        return response
    } catch (error) {
        throw new Error("Error")
    }
}

export async function editSelfUser(user: { firstName: string, lastName: string, language: string }) {
    try {
        const response = await fetchWithToken(getBaseUrl() + "/self", {
            method: "PUT",
            body: JSON.stringify(user),
            headers: {
                "Content-Type": "application/json",
            },
        })
        return response
    } catch (error) {
        throw new Error("Error")
    }
}

export async function deleteCustomerUser(userId: string, customerId: string) {
    return deleteUser(getBaseUrlOrganisation(customerId, 'customers') + `${userId}`)
}

export async function deleteInstanceUser(userId: string, instanceId: string) {
    return deleteUser(getBaseUrlOrganisation(instanceId, 'instances') + `${userId}`)
}

export async function deleteSystemUser(userId: string) {
    return deleteUser(getBaseUrlSystem() + `${userId}`)
}

export async function deleteUser(path: string) {
    try {
        const response = await fetchWithToken(path, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
            },
        })
        return response
    } catch (error) {
        throw new Error("Error")
    }
}


