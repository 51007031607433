import {Await, defer, useLoaderData} from "react-router-dom";
import {loadTemplateTags} from "./EditTemplate";
import React, {Suspense} from "react";
import {TemplateTag} from "../../model/TemplateTag";
import TemplateEditor from "../../components/Template/TemplateEditor";
import {CircularProgress} from "@mui/material";

const NewTemplatePage: React.FC = (props) => {
    const {templateTags} = useLoaderData() as { templateTags: TemplateTag[] };

    return (
        <Suspense fallback={<CircularProgress/>}>
            <Await resolve={templateTags}>
                {templateTags => <TemplateEditor method="POST" templateTags={templateTags}/>}
            </Await>
        </Suspense>
    )
}

export default NewTemplatePage;

export async function loader({request, params}: { request: Request, params: { customerId: string } }) {
    const customerId = params.customerId;
    return defer({
        templateTags: loadTemplateTags(customerId)
    })
}