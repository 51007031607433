import {Customer} from "../model/Customer";
import {fetchWithToken} from "./fetchWithToken";


const getBaseUrl = () => {
    let url;
    switch (process.env.NODE_ENV) {
        case 'production':
            url = '/api';
            break;
        case 'development':
            url = 'http://localhost:5000/api';
            break;
        default:
            url = '/api';
    }

    return url;
}

export async function getCustomer(customer_id: string){
    try {
        const response = await fetchWithToken(getBaseUrl() + `/customers/${customer_id}`, {
            method: "GET",
        })
        return response
    } catch (error) {
        throw new Error("Error")
    }
}

export async function checkCustomerDemoFlag(customer_id: string) {
    try {
        const response = await fetchWithToken(getBaseUrl() + `/customers/${customer_id}/demo`, {
            method: "GET",
        })
        return response
    } catch (error){
        throw new Error("Error")
    }
}

export async function listCustomers(instanceId: string) {
    try {
        const response = await fetchWithToken(getBaseUrl() + `/instance/${instanceId}/customersnames`, {
            method: "GET"
        })
        return response
    } catch (error) {
        throw new Error("Error")
    }
}

export async function deleteCustomer(customer_id: string){
    try {
        const response = await fetchWithToken(getBaseUrl() + `/customers/${customer_id}`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
            },

        })
        return response
    } catch (error){
        throw new Error("Error")
    }
}

export async function createCustomer(customer: Customer, instanceId: string) {
    try {
        const response = await fetchWithToken(getBaseUrl() + `/instance/${instanceId}/customers`, {
            method: "POST",
            body: JSON.stringify(customer),
            headers: {
                "Content-Type": "application/json",
            },
        })
        return response
    } catch (error) {
        throw new Error("Error")
    }
}

export async function editCustomer(customer: Customer, customer_id: string){
    try {
        const response = await fetchWithToken(getBaseUrl() + `/customers/${customer_id}`, {
            method: "PUT",
            body: JSON.stringify(customer),
            headers: {
                "Content-Type": "application/json",
            },
        })
        return response
    } catch (error){
        throw new Error("Error")
    }
}

