import * as React from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import classes from '../../styling/components/List.module.css'
import { IconButton, Typography } from '@mui/material';
import { TemplateTag, TemplateTagGroup } from '../../model/TemplateTag';
import { Link, json, useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import { deleteTemplateTag } from '../../api/templateTagClient';
import { useTranslation } from 'react-i18next';

interface Props {
  templateTagGroup: TemplateTagGroup
}

const TemplateTagList: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleDelete = async (templateTagId: string) => {
    const proceed = window.confirm(`${t('templateTagList.confirmDelete')}`);

    if (proceed) {
      const response = await deleteTemplateTag(templateTagId);
      if (!response.ok) {
        throw json({ message: `${t('templateTagList.deleteError')}` }, { status: response.status });
      }
      else {
        navigate('');
        return;
      }
    }
  }
  return (
    <Box sx={{ width: '300px', margin: '0 auto' }} className={classes.mainContainer}>
      <Typography variant="h4">{props.templateTagGroup.type} tags</Typography>
      <List>
        {props.templateTagGroup.tags.map((templateTag) => (
          <ListItem key={templateTag.templateTagId} disablePadding>
            <ListItemText primary={templateTag.templateTagId} />
            <Link to={`${templateTag.templateTagId}`}>
              <ListItemText primary={templateTag.name} />
            </Link>
            <IconButton
              edge="end"
              aria-label="delete"
              onClick={() => handleDelete(templateTag.templateTagId!.toString())}
            >
              <DeleteIcon />
            </IconButton>
          </ListItem>))}
      </List>
    </Box>
  );
}

export default TemplateTagList;