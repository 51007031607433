import {CircularProgress} from "@mui/material";
import React, {Suspense} from "react";
import {Await, defer, json, useLoaderData} from "react-router-dom";
import {getCustomer} from "../../api/customerClient";
import {getCustomerTemplates} from "../../api/templateClient";
import {Template} from "../../model/Template";
import {loadCustomers} from "../Customer/CustomerManager";
import {Customer} from "../../model/Customer";
import {TemplateOverview} from "../../components/Template/TemplateOverview";

const TemplateManagerPage: React.FC = (props) => {
    const { templates, customers } = useLoaderData() as {  templates: Template[], customers: Customer[] };
    return (
        <>
            <Suspense fallback={<CircularProgress/>}>
                <Await resolve={Promise.all([templates, customers])}>
                    {data => <TemplateOverview templates={data[0]} customers={data[1]}/>}
                </Await>
            </Suspense>
        </>
    );
}

export default TemplateManagerPage;

export async function loadCustomerTemplates(id: string) {
    const response = await getCustomerTemplates(id);

    if (!response.ok) {
        throw json({ message: 'Could not fetch customertemplates.' }, { status: 500 });
    }
    else {
        const resData = await response.json();
        return resData
    }
}

export async function loader({request, params}: { request: Request, params: { customerId: string } }) {
    const id = params.customerId;

    return defer({
        templates: loadCustomerTemplates(id),
        customers: loadCustomers(),
    })
}

export async function customerLoader({request, params}: { request: Request, params: { customerId: string } }) {
    const id = params.customerId;

    return defer({
        customer: getCustomer(id)
    })
}