import React, {Suspense} from "react";
import {Await, defer, json, useLoaderData} from "react-router-dom";
import {getCustomer} from "../../api/customerClient";
import CustomerEditor from "../../components/Customer/CustomerEditor";
import {Customer} from "../../model/Customer";
import {getCustomerLogo} from "../../api/brandingClient";
import {CircularProgress} from "@mui/material";

const EditCustomerPage: React.FC = (props) => {
    const {customer, customerLogo} = useLoaderData() as { customer: Customer, customerLogo: any };

    return (
        <Suspense fallback={<CircularProgress/>}>
            <Await resolve={Promise.all([customer, customerLogo])}>
                {data => <CustomerEditor method="PUT" customer={data[0]} customerLogo={data[1]} />}
            </Await>
        </Suspense>
    )
}

export default EditCustomerPage;

export async function loadCustomer(customerId: string) {
    const response = await getCustomer(customerId);

    if (!response.ok) {
        throw json({message: 'Could not fetch CustomerData.'}, {status: 500});
    } else {
        const resData = await response.json();
        return resData
    }
}

export async function loadCustomerLogo(customerId: string) {
    const response = await getCustomerLogo(customerId);
    if (!response.ok) {
        if (response.status === 404) {
            return null;
        }
        throw json({message: 'Could not fetch Customerlogo.'}, {status: 500});
    } else {
        const logo = await response.blob();
        return logo;
    }
}

export async function loader({request, params}: { request: Request, params: { customerId: string } }) {
    const customerId = params.customerId;

    return defer({
        customer: loadCustomer(customerId),
        customerLogo: loadCustomerLogo(customerId),
    })
}