import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { Domain } from '../../model/Domain';
import classes from '../../styling/components/Editor.module.css';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const DomainMultiSelect: React.FC<{ id: string, label: string, name: string, defaultValue:number[],options: Domain[],onSelectChange?: (selectedValues: Domain[]) => void, enableEdit: boolean }> = (props) => {
    const [item, setItem] = React.useState<any>(props.defaultValue);
    const handleChange = (event: SelectChangeEvent<typeof item>) => {
        const {
          target: { value },
        } = event;
    
        // Convert the selected values to an array of Domain objects
        const selectedDomains: Domain[] = value.map((domainId:string) => {
          const selectedDomain = props.options.find((option) => option.domainId === domainId);
          return selectedDomain || { domainId, domain: '' };
        });
    
        setItem(value);
    
        // Call the parent component's onSelectChange with the selected values
        if (props.onSelectChange) props.onSelectChange(selectedDomains);
      };

    return (
        <FormControl className={classes.formControlSideBySide}>
            <InputLabel id={props.id + "label"}>{props.label}</InputLabel>
            <Select
                labelId={props.id + "label"}
                id={props.id}
                name={props.name}
                disabled={!props.enableEdit}
                multiple
                value={item}
                onChange={handleChange}
                input={<OutlinedInput label={props.label} />}
                renderValue={(selected) => selected.map((selected:any) => props.options.filter((option) => selected === option.domainId).map((option) => option.domain)).join(', ')}
                MenuProps={MenuProps}
            >
                {props.options.map((option) => (
                    <MenuItem key={option.domainId} value={option.domainId as string}>
                        <Checkbox checked={item.indexOf(option.domainId!) > -1} />
                        <ListItemText primary={option.domain} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

export default DomainMultiSelect;