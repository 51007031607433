import * as React from 'react';
import {useContext, useMemo} from 'react';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import {NavLink, useLocation, useParams} from 'react-router-dom';
import classes from '../../styling/components/NavigationDrawer.module.css'
import {UserContext} from "../../App";
import GridViewIcon from '@mui/icons-material/GridView';
import LogoutIcon from '@mui/icons-material/Logout';
import GroupsIcon from '@mui/icons-material/Groups';
import CampaignIcon from '@mui/icons-material/Campaign';
import SettingsIcon from '@mui/icons-material/Settings';
import {Button} from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import {OrganizationLevel} from '../../model/SelfUser';
import {UserPanel} from "../Base/UserPanel";
import {useTranslation} from "react-i18next";

interface Props {
    demo: boolean
}

const CustomerNavigation: React.FC<Props> = (props) => {
    const {t} = useTranslation()
    const {customerId} = useParams();
    const {user, signOut} = useContext(UserContext)
    const route = useLocation()

    const location = useMemo(() => {
        const pathParts = route.pathname.split("/").filter(part => part)
        if (pathParts[0] !== "customers")
            return ""
        if (pathParts[1] !== customerId)
            return ""
        return pathParts[2]
    }, [route, customerId])

    const editUserPath = user.organizationLevel === OrganizationLevel.CUSTOMER ? `/customers/${user.organizationId}/settings` :
        user.organizationLevel === OrganizationLevel.INSTANCE ? `/instances/${user.organizationId}/users/settings` :
            `/system/users/settings`


    return (
        <>
            <Drawer
                anchor="left"
                variant="permanent"
            >
                <div className={classes.sidebar}>
                    <div>
                        <div className={classes.logo}>
                            <NavLink to={"/"}><img src={"/assets/img/look2x-logo-farbig.svg"}/> </NavLink>
                        </div>
                        <Divider/>
                        <div className={classes.navigation}>
                            <List>
                                {user.organizationLevel === OrganizationLevel.CUSTOMER ? null : <>
                                    <ListItem key={"title"}>Verwaltung</ListItem>
                                    <ListItem
                                        className={`${classes.navItem} ${location === "details" ? classes.selected : ""}`}
                                        key={"customerDetails"} component={NavLink}
                                        to={`/customers/${customerId}/details`}>
                                        <GroupsIcon className={classes.liIcon}/>
                                        <ListItemText className={classes.liText} primary={"Details"}/>
                                    </ListItem>
                                    <ListItem
                                        className={`${classes.navItem} ${location === "testmailconfig" ? classes.selected : ""}`}
                                        key={"testmailConfig"} component={NavLink}
                                        to={`/customers/${customerId}/testmailconfig`}>
                                        <SettingsIcon className={classes.liIcon}/>
                                        <ListItemText className={classes.liText} primary={"Testmail Config"}/>
                                    </ListItem>
                                    <ListItem
                                        className={`${classes.navItem} ${location === "templates" ? classes.selected : ""}`}
                                        key={"templates"} component={NavLink}
                                        to={`/customers/${customerId}/templates`}>
                                        <EmailIcon className={classes.liIcon}/>
                                        <ListItemText className={classes.liText} primary={"Templates"}/>
                                    </ListItem>
                                    <ListItem
                                        className={`${classes.navItem} ${location === "receivers" ? classes.selected : ""}`}
                                        key={"receivers"} component={NavLink}
                                        to={`/customers/${customerId}/receivers`}>
                                        <GroupsIcon className={classes.liIcon}/>
                                        <ListItemText className={classes.liText} primary={"Receivers"}/>
                                    </ListItem>
                                    <ListItem
                                        className={`${classes.navItem} ${location === "campaigns" ? classes.selected : ""}`}
                                        key={"campaigns"} component={NavLink}
                                        to={`/customers/${customerId}/campaigns`}>
                                        <CampaignIcon className={classes.liIcon}/>
                                        <ListItemText className={classes.liText} primary={"Kampagnenconf"}/>
                                    </ListItem>
                                </>}
                                {props.demo ?
                                    <ListItem
                                        className={`${classes.navItem} ${location === "demo" ? classes.selected : ""}`}
                                        key={"campaignAnalytics"} component={NavLink}
                                        to={`/customers/${customerId}/demo/analytics`}>
                                        <GridViewIcon className={classes.liIcon}/>
                                        <ListItemText className={classes.liText}
                                                      primary={t("navigationSidebar.campaigns")}/>
                                    </ListItem>
                                    :
                                    <ListItem
                                        className={`${classes.navItem} ${location === "analytics" ? classes.selected : ""}`}
                                        key={"campaignAnalytics"} component={NavLink}
                                        to={`/customers/${customerId}/analytics`}>
                                        <GridViewIcon className={classes.liIcon}/>
                                        <ListItemText className={classes.liText}
                                                      primary={t("navigationSidebar.campaigns")}/>
                                    </ListItem>
                                }
                                <Divider/>
                                <ListItem
                                    className={`${classes.navItem} ${location === "settings" ? classes.selected : ""}`}
                                    key={"title"} component={NavLink}
                                          to={editUserPath}>
                                    <ManageAccountsIcon className={classes.liIcon}/>
                                    <ListItemText className={classes.liText} primary={t("navigationSidebar.settings")}/>
                                </ListItem>
                                <ListItem className={classes.navItem} key={"logout"} component={Button}
                                          onClick={signOut}>
                                    <LogoutIcon className={classes.liIcon}/>
                                    <ListItemText className={classes.liText}>Logout</ListItemText>
                                </ListItem>
                            </List>
                        </div>
                    </div>
                    <div className={classes.userArea}>
                        <UserPanel/>
                    </div>
                </div>
            </Drawer>
        </>
    )
}

export default CustomerNavigation;
