import React from 'react';
import { FormGroup, FormControlLabel, Checkbox, FormControl } from '@mui/material';
import { ReceiverGroup } from '../../model/ReceiverGroup';
import { useTranslation } from 'react-i18next';

interface CampaignReceiverGroupSelectProps {
  receiverGroups: ReceiverGroup[];
  checked: boolean[];
  handleChange: (event: React.ChangeEvent<HTMLInputElement>, index: number) => void;
  enableEdit: boolean;
}

const CampaignReceiverGroupSelect: React.FC<CampaignReceiverGroupSelectProps> = (props) => {
  const { t } = useTranslation();
  const isDefaultChecked = props.checked.some((isChecked, index) => isChecked && props.receiverGroups[index].name === 'Default');
  const isDefaultDisabled = props.checked.some((isChecked, index) => isChecked && props.receiverGroups[index].name !== 'Default');
  return (
    <FormControl component="fieldset">
      <legend>{t('campaignReceiverGroupSelect.receiverGroups')}</legend>
      <FormGroup>
        {props.receiverGroups.length > 0 &&
          props.receiverGroups.map((receiverGroup, index) => (
            <FormControlLabel
              key={receiverGroup.receiverGroupId}
              label={receiverGroup.name}
              control={
                <Checkbox
                  onChange={(event) => props.handleChange(event, index)}
                  checked={props.checked[index]}
                  disabled={ props.enableEdit ? (receiverGroup.name === 'Default' ? isDefaultDisabled : isDefaultChecked) : true}
                  inputProps={{ 'aria-label': 'controlled' }}
                  name={`receiverGroupId_${receiverGroup.receiverGroupId}`}
                  key={receiverGroup.receiverGroupId}
                  value={receiverGroup.receiverGroupId}
                />
              }
            />
          ))}
      </FormGroup>
    </FormControl>
  );
};

export default CampaignReceiverGroupSelect;
