import { TestmailConfig } from "../model/TestmailConfig";
import { fetchWithToken } from "./fetchWithToken";

const getBaseUrl = () => {
    let url;
    switch (process.env.NODE_ENV) {
        case 'production':
            url = '/api/customers';
            break;
        case 'development':
            url = 'http://localhost:5000/api/customers';
            break;
        default:
            url = '/api/customers';
    }

    return url;
}

export async function getCustomerTestmailConfig(customerId: string) {
    try {
        const response = await fetchWithToken(getBaseUrl() + `/${customerId}` + "/testmailConfig", {
            method: "GET"
        })
        return response
    } catch (error) {
        throw new Error("Error while loading testmailConfig")
    }
}

export async function createTestmailConfig(testmailConfig: TestmailConfig, customerId: string) {
    const { ...sendTestmailConfig } = testmailConfig;
    try {
        const response = await fetchWithToken(getBaseUrl() + `/${customerId}` + "/testmailConfig", {
            method: "POST",
            body: JSON.stringify(sendTestmailConfig),
            headers: {
                "Content-Type": "application/json",
            },
        })
        return response
    } catch (error) {
        throw new Error("Error while posting testmailConfig")
    }
}

export async function editTestmailConfig(testmailConfig: TestmailConfig, customerId: string) {
    try {
        const response = await fetchWithToken(getBaseUrl()  + `/${customerId}` + "/testmailConfig", {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(testmailConfig)
        })
        return response
    } catch (error) {
        throw new Error("Error while Editing testmailConfig")
    }
}