import {Template} from "../model/Template";
import {Customer} from "../model/Customer";
import {copyTemplatesToCustomer} from "../api/templateClient";
import {json} from "react-router-dom";
import {useTranslation} from "react-i18next";
import React, {useState} from "react";
import CopyTemplateDialog from "../components/Template/TemplateTableDialog/CopyTemplateDialog";

const emptyData: {
    customerId: string,
    selectedTemplateIds: string[],
    customers: Customer[],
    onCopyTemplateResult: (data: Template[], targetCustomerId: string) => void
} = {
    customerId: "",
    selectedTemplateIds: [],
    customers: [],
    onCopyTemplateResult: () => {
    }
}

export function useCopyTemplateDialog(): [JSX.Element, (onCopyTemplateResult: (data: Template[], targetCustomerId: string) => void, customerId: string, selectedTemplateIds: string[], customers: Customer[]) => void] {
    const {t} = useTranslation();

    const [data, setData] = useState(emptyData);
    const [open, setOpen] = useState(false)

    const copyTemplates = (templateIds: string[], targetCustomerId: string, onResult: (data: Template[], targetCustomerId: string) => void) => {
        copyTemplatesToCustomer(templateIds, targetCustomerId)
            .then((res) => {
                if (!res.ok) {
                    throw json({message: `${t('templateTable.copyTemplatesError')}`}, {status: 500});
                }
                return res.json()
            })
            .then((data) => {
                onResult(data, targetCustomerId);
            })
            .catch((exception) => {
                console.log(exception.message);
            });
    }

    const openDialog = (onCopyTemplateResult: (data: Template[], targetCustomerId: string) => void, customerId: string, selectedTemplateIds: string[], customers: Customer[]) => {
        setData({
            customerId: customerId,
            selectedTemplateIds: selectedTemplateIds,
            customers: customers,
            onCopyTemplateResult: onCopyTemplateResult
        })
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const executeCopyTemplates = (targetCustomerId: string) => {
        copyTemplates(data.selectedTemplateIds, targetCustomerId, data.onCopyTemplateResult);
        handleClose()
    }

    const dialog = <CopyTemplateDialog
        open={open}
        onClose={handleClose}
        onSelect={executeCopyTemplates}
        customers={data.customers}
    />

    return [dialog, openDialog]
}