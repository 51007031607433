import React from 'react';
import Dialog from '@mui/material/Dialog';
import {Template} from '../../../model/Template';
import {Box, Button, DialogActions, DialogContent, DialogTitle, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import TemplateTable from "../../Template/TemplateTable";

interface ConfirmTemplatesDialogProps {
    open: boolean;
    onClose: () => void;
    onContinue: () => void;
    templates: Template[];
    baselineEnabled: boolean;
    selectedBaselineTemplateIds: string[];
    selectedFollowUpTemplateIds: string[];
}

const ConfirmTemplatesDialog: React.FC<ConfirmTemplatesDialogProps> = (props) => {
    const {
        open,
        onClose,
        onContinue,
        templates,
        baselineEnabled,
        selectedBaselineTemplateIds,
        selectedFollowUpTemplateIds,
    } = props;
    const { t } = useTranslation();

    const baselineTemplates = templates.filter(template => selectedBaselineTemplateIds.includes(template.templateId!));
    const followupTemplates = templates.filter(template => selectedFollowUpTemplateIds.includes(template.templateId!));

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{t('confirmTemplatesDialog.confirmTemplateSelection')}</DialogTitle>
            <DialogContent>
                <p>{t('confirmTemplatesDialog.areYouSure')}</p>
                {baselineEnabled &&
                    <Box>
                        <Typography variant="h6">{t('confirmTemplatesDialog.baselineTemplates')}</Typography>
                        <TemplateTable
                            templates={baselineTemplates}
                            selectedTemplateIds={selectedBaselineTemplateIds}
                            readonly
                        />
                    </Box>
                }
                <Typography variant="h6">{t('confirmTemplatesDialog.followUpTemplates')}</Typography>
                <TemplateTable
                    templates={followupTemplates}
                    selectedTemplateIds={selectedFollowUpTemplateIds}
                    readonly
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    {t('confirmTemplatesDialog.cancel')}
                </Button>
                <Button onClick={onContinue} color="primary">
                    {t('confirmTemplatesDialog.continue')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmTemplatesDialog;