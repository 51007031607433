import React from 'react';
import { Select, FormControl, InputLabel, MenuItem } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { Gender } from '../../model/Receiver';
import { useTranslation } from 'react-i18next';

interface GenderSelectProps {
  gender: string;
  handleGenderChange: (event: SelectChangeEvent) => void;
}

const GenderSelect: React.FC<GenderSelectProps> = ({ gender, handleGenderChange }) => {
  const { t } = useTranslation();
  return (
    <FormControl fullWidth>
      <InputLabel id="gender-label">{t('genderSelect.genderLabel')}</InputLabel>
      <Select
        labelId="gender-label"
        id="gender"
        name="gender"
        value={gender}
        label={t('genderSelect.genderLabel')}
        onChange={handleGenderChange}
      >
        <MenuItem value={Gender.MALE}>{t('genderSelect.male')}</MenuItem>
        <MenuItem value={Gender.FEMALE}>{t('genderSelect.female')}</MenuItem>
        <MenuItem value={Gender.DIVERS}>{t('genderSelect.divers')}</MenuItem>
      </Select>
    </FormControl>
  );
};

export default GenderSelect;