import {fetchWithToken} from "./fetchWithToken";

const getBaseUrl = (customerId: string) => {
    let url;
    switch (process.env.NODE_ENV) {
        case 'production':
            url = `/api/customers/${customerId}`;
            break;
        case 'development':
            url = `http://localhost:5000/api/customers/${customerId}`;
            break;
        default:
            url = `/api/customers/${customerId}`;
    }

    return url;
}

export async function getCampaignAnalytics(customerId: string, campaignId: string) {
    try {
        const response = await fetchWithToken(getBaseUrl(customerId) + `/campaigns/${campaignId}/analytics`, {
            method: "GET"
        })
        return response
    } catch (error) {
        throw new Error("Error")
    }
}

export async function getCustomerOverviewAnalytics(customerId: string) {
    try {
        const response = await fetchWithToken(getBaseUrl(customerId) + `/analytics`, {
            method: "GET"
        })
        return response
    } catch (error) {
        throw new Error("Error")
    }
}