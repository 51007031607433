import {LinearProgress} from "@mui/material";
import React from "react";
import classes from '../../../styling/components/analytics/ProgressBar.module.css';
import Tooltip from "@mui/material/Tooltip";

interface Props {
    value: number,
    color: string,
    textColor: string,
    tooltipContent?: string | React.ReactElement,
    secondaryValue?: number;
}

export const ProgressBar: React.FC<Props> = (props) => {

    const variant = props.secondaryValue ? "buffer" : "determinate";
    const totalValue = props.value + (props.secondaryValue ?? 0);

    return (
        <div className={classes.progressbar}>
            <Tooltip title={props.tooltipContent} placement={"top"} arrow>
                {/* @ts-ignore*/}
                <LinearProgress variant={variant} color={props.color}
                                value={props.value} valueBuffer={totalValue}/>
            </Tooltip>

            <span className={classes.text} style={{color: props.textColor}}>{totalValue}%</span>
        </div>
    )

}