import React, {useContext} from "react";
import {UserContext} from "../../App";
import classes from "../../styling/components/UserPanel.module.css"

export const UserPanel: React.FC = () => {

    const {user} = useContext(UserContext)

    return (
        user.firstName && user.lastName && user.role ?
            <div className={classes.userPanel}>
                <div className={classes.userAvatar}>
                    <span>{user.firstName.at(0)}{user.lastName.at(0)}</span>
                </div>
                <div>
                    <div>{user.firstName} {user.lastName.at(0)}.</div>
                    <div className={classes.userRoleText}>{user.role}</div>
                </div>
            </div> : null
    )
}