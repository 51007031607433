import * as React from 'react';
import {DataGrid, GridColDef, GridRenderCellParams} from '@mui/x-data-grid';
import {Dispatch} from '../../model/Dispatch';
import classes from '../../styling/components/Table.module.css';
import {Receiver} from '../../model/Receiver';

const DispatchTable: React.FC<{ dispatches: Dispatch[] }> = (props) => {
    const columns: GridColDef[] = [
        {field: 'dispatchId', headerName: 'ID', width: 70},
        {
            field: 'receiver', headerName: 'Receiver', width: 130,
            renderCell: (params: GridRenderCellParams) => (
                params.value.receiverId
            )
        },
        {
            field: 'template', headerName: 'Template', width: 130,
            renderCell: (params: GridRenderCellParams) => (
                params.value.templateId
            )
        },
        {
            field: 'campaign', headerName: 'Campaign', width: 130,
            renderCell: (params: GridRenderCellParams) => (
                params.value.campaignId
            )
        },
        {field: 'sendTime', headerName: 'ETA', width: 250},
        {field: 'sent', headerName: 'Sent', width: 70},
        {field: 'delivered', headerName: 'Delivered', width: 70},
        {field: 'opened', headerName: 'Opened', width: 70},
        {field: 'clicked', headerName: 'Clicked', width: 70},
        {field: 'replied', headerName: 'Replied', width: 70},
        {field: 'baseline', headerName: 'Baseline', width: 70},
        {field: 'interacted', headerName: 'Interacted', width: 70},
        {field: 'interactedCritical', headerName: 'InteractedCritical', width: 70},
    ];
      
      const rows = props.dispatches;
    return (
        <div className={classes.dispatchtable}>
          <DataGrid
              rows={rows}
              getRowId={(row) => row.dispatchId}
              columns={columns}
              initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 10 },
              },
            }}
              pageSizeOptions={[10, 50]}
          />
        </div>
      );
}
  
export default DispatchTable;