import React from 'react';
import { FormGroup, FormControlLabel, Checkbox, FormControl } from '@mui/material';
import { Campaign } from '../../model/Campaign';
import { useTranslation } from 'react-i18next';

interface CampaignSendDayInputProps {
  campaign?: Campaign;
  onCheckboxChange: (index: number) => void;
}

const CampaignSendDayInput: React.FC<CampaignSendDayInputProps> = (props) => {
  const { t } = useTranslation();
  const daysLabel = [`${t('campaignSendDayInput.monday')}`, `${t('campaignSendDayInput.tuesday')}`, `${t('campaignSendDayInput.wednesday')}`, `${t('campaignSendDayInput.thursday')}`, `${t('campaignSendDayInput.friday')}`, `${t('campaignSendDayInput.saturday')}`, `${t('campaignSendDayInput.sunday')}`];
  const days = ['Monday','Tuesday','Wednesday','Thursday','Friday','Saturday', 'Sunday'];
  const getCheckboxDefaultChecked = (index: number): boolean => {
    if (props.campaign) {
      return props.campaign.sendDays.charAt(index) === '1';
    } else {
      // Set default values if campaign prop is not provided
      return index < 5; // Monday to Friday checked by default, Saturday and Sunday unchecked
    }
  };

  return (
    <FormControl component="fieldset">
      <legend>{t('campaignSendDayInput.senddays')}</legend>
      <FormGroup>
        {days.map((day, index) => (
          <FormControlLabel
            key={day}
            label={`${t(`campaignSendDayInput.${day.toLowerCase()}`)}`}
            control={
              <Checkbox
                name={day.toLowerCase()}
                value="1"
                defaultChecked={getCheckboxDefaultChecked(index)}
                onChange={() => props.onCheckboxChange(index)}
              />
            }
          />
        ))}
      </FormGroup>
    </FormControl>
  );
};

export default CampaignSendDayInput;
