import { Dialog, DialogTitle, DialogContentText, DialogContent, DialogActions, Button } from "@mui/material";
import { useState } from "react";
import CustomTextField from "../../Input/CustomTextfield";
import { useTranslation } from "react-i18next";


const MonthDialog: React.FC<{ open: boolean, onClose: any }> = (props) => {
  const { t } = useTranslation();
    const { open, onClose } = props;
    const handleClose = () => {
      onClose("cancelled");
    };

    const [errorStates, setErrorStates] = useState({});
    const [hasError, setHasError] = useState<boolean>(false);

    // Callback function to update the error state for a specific field
    const handleTextFieldError = (name: string, errorState: boolean) => {
        setErrorStates(prevErrorStates => {
            const updatedErrorStates = {
                ...prevErrorStates,
                [name]: errorState,
            };
    
            // Now checking inside the update function, where we have the updated state
            const anyError = Object.values(updatedErrorStates).some(error => error);
            setHasError(anyError);
    
            return updatedErrorStates;
        });
    };

    const handleAdd = (value: string) => {
      if (value.match(/^-?\d+$/)) {
        onClose(value);
        setMonthCount("");
      }
    }

    const [monthCount, setMonthCount] = useState("");
    const handleMonthCountChange = (event: any) => {
      setMonthCount(event.target.value);
    }

return (
    <Dialog open={open} onClose={handleClose}>
    <DialogTitle>{t('monthDialog.title')}</DialogTitle>
    <DialogContent>
      <DialogContentText>
      {t('monthDialog.text')}
      </DialogContentText>
      <CustomTextField name="dayCount" label={t('monthDialog.label')} defaultValue="" value={monthCount} onChange={handleMonthCountChange} regEx={/^-?\d+$/} helper={t('monthDialog.helper')} onErrorChange={handleTextFieldError}/>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose}>{t('monthDialog.cancel')}</Button>
      <Button disabled={hasError} onClick={() => handleAdd(monthCount)}>{t('monthDialog.addPlaceholder')}</Button>
    </DialogActions>
  </Dialog>
)
}

export default MonthDialog;