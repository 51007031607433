import {Box, Button, CircularProgress} from "@mui/material";
import React, {Suspense} from "react";
import {Await, defer, json, Link, useLoaderData} from "react-router-dom";
import {getCustomerReceivers} from "../../api/receiverClient";
import {getCustomerReceiverGroups} from "../../api/receiverGroupClient";
import ReceiverTable from "../../components/Receiver/ReceiverTable";
import ReceiverGroupList from "../../components/ReceiverGroup/ReceiverGroupList";
import {Receiver} from "../../model/Receiver";
import {ReceiverGroup} from "../../model/ReceiverGroup";
import classes from "../../styling/pages/Manager.module.css";
import ReceiverCsvUpload from "../../components/Receiver/ReceiverCsvUpload";
import {useTranslation} from "react-i18next";

const ReceiverManagerPage: React.FC = (props) => {
    const { t } = useTranslation();
    const { receivers, receiverGroups } = useLoaderData() as { receivers: Receiver[], receiverGroups: ReceiverGroup[] };
    return (
        <><Box className={classes.buttonContainer}>
            <Link to={"new-receiver"}>
                <Button variant="contained">
                    {t('receiverManager.newReceiver')}
                </Button>
            </Link>
            <ReceiverCsvUpload />
        </Box>
            <Suspense fallback={<CircularProgress/>}>
                <Await resolve={receiverGroups}>
                    {loadedReceiverGroups => <ReceiverGroupList receiverGroups={loadedReceiverGroups} />}
                </Await>
            </Suspense>
            <Suspense fallback={<></>}>
                <Await resolve={receivers}>
                    {loadedReceivers => <ReceiverTable receivers={loadedReceivers} fromReceiverGroupEditor={false} />}
                </Await>
            </Suspense>

        </>
    );
}

export default ReceiverManagerPage;

export async function loadCustomerReceivers(id: string) {
    const response = await getCustomerReceivers(id);

    if (!response.ok) {
        throw json({ message: 'Could not fetch receivers.' }, { status: 500 });
    }
    else {
        const resData = await response.json();
        return resData
    }
}

export async function loadCustomerReceiverGroups(id: string) {
    const response = await getCustomerReceiverGroups(id);

    if (!response.ok) {
        throw json({ message: 'Could not fetch receiver groups.' }, { status: 500 });
    } else {
        const resData = await response.json();
        return resData
    }
}

export async function loader({ request, params }: { request: Request, params: { customerId: string } }) {
    const id = params.customerId;

    return defer({
        receivers: loadCustomerReceivers(id),
        receiverGroups: loadCustomerReceiverGroups(id),
    })
}