import {isRouteErrorResponse, useRouteError} from "react-router-dom";
import MainHeader from "./MainHeader";
import React from "react";
import { useTranslation } from "react-i18next";

const ErrorPage: React.FC = (props) => {
    const { t } = useTranslation();
    const error = useRouteError();

    let title = `${t('errorPage.defaultTitle')}`;
    let message = `${t('errorPage.defaultMessage')}`;

    if (isRouteErrorResponse(error)) {
        if (error.status === 500) {
            message = error.data.message;
        }

        if (error.status === 404) {
            title = `${t('errorPage.notFoundTitle')}`;;
            message = `${t('errorPage.notFoundMessage')}`;;
        }
    }
    return (
        <>
            <MainHeader />
            <div>
                <h1>{title}</h1>
                <p>{message}</p>
            </div>
        </>
    );
}

export default ErrorPage;