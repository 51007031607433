import {fetchWithToken} from "./fetchWithToken";

const getBaseUrl = (id: string, org: string) => {
    let url;
    switch (process.env.NODE_ENV) {
        case 'production':
            url = `/api/${org}/${id}/roles`;
            break;
        case 'development':
            url = `http://localhost:5000/api/${org}/${id}/roles`;
            break;
        default:
            url = `/api/${org}/${id}/roles`;
    }
    return url;
}

const getBaseUrlSystem = () => {
    let url;
    switch (process.env.NODE_ENV) {
        case 'production':
            url = `/api/system/roles`;
            break;
        case 'development':
            url = `http://localhost:5000/api/system/roles`;
            break;
        default:
            url = `/api/system/roles`;
    }
    return url;
}

export async function listCustomerRoles(customerId: string) {
    return listRoles(getBaseUrl(customerId, 'customers'))
}

export async function listInstanceRoles(instanceId: string) {
    return listRoles(getBaseUrl(instanceId, 'instances'))
}

export async function listSystemRoles() {
    return listRoles(getBaseUrlSystem())
}

export async function listRoles(path: string) {
    try {
        const response = await fetchWithToken(path, {
            method: "GET",
        })
        return response
    } catch (error) {
        throw new Error("Error")
    }
}