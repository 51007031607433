import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import HomeIcon from '@mui/icons-material/Home';
import { NavLink } from 'react-router-dom';

const MainHeader: React.FC = (props) => {
  return (
    <Box>
      <AppBar position="static">
        <Toolbar>
        <NavLink
              to="/"
              end
            >
            <HomeIcon />
          </NavLink>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

export default MainHeader;