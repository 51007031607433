import {Await, defer, json, useLoaderData} from "react-router-dom";
import React, {Suspense} from "react";
import {UserEditor} from "../../components/User/UserEditor";
import {OrganizationLevel} from "../../model/enum/OrganizationLevel";
import {User} from "../../model/User";
import {getSystemUser} from "../../api/userClient";
import {listSystemRoles} from "../../api/roleClient";
import {CircularProgress} from "@mui/material";

const EditSystemUserPage: React.FC = (props) => {
    const {user, availableRoles} = useLoaderData() as { user: User, availableRoles: string[] };


    return (
        <Suspense fallback={<CircularProgress/>}>
            <Await resolve={Promise.all([user, availableRoles])}>
                {data => <UserEditor method="PUT" user={data[0]} availableRoles={data[1]}
                                     level={OrganizationLevel.SYSTEM}/>}
            </Await>
        </Suspense>
    )
}

export default EditSystemUserPage;

async function loadSystemUser(userId: string) {
    const response = await getSystemUser(userId);

    if (!response.ok) {
        throw json({message: 'Could not fetch user.'}, {status: response.status});
    } else {
        const resData = await response.json();
        return resData
    }
}

export async function loadSystemRoles() {
    const response = await listSystemRoles();

    if (!response.ok) {
        throw json({message: 'Could not fetch user.'}, {status: response.status});
    } else {
        const resData = await response.json();
        return resData
    }
}

export async function editSystemUserPageLoader({request, params}: { request: Request, params: { userId: string } }) {
    const userId = params.userId;
    return defer({
        user: loadSystemUser(userId),
        availableRoles: loadSystemRoles()
    })
}