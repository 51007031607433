import React, {Suspense} from "react";
import {Await, defer, json, useLoaderData} from "react-router-dom";
import {getCampaign} from "../../api/campaignClient";
import CampaignEditor from "../../components/Campaign/CampaignEditor";
import {loadCustomerReceiverGroups} from "../Receiver/ReceiverManager";
import {Campaign} from "../../model/Campaign";
import {ReceiverGroup} from "../../model/ReceiverGroup";
import {loadCustomerTemplates} from "../Template/TemplateManager";
import {Template} from "../../model/Template";
import {listDomains} from "../../api/domainClient";
import {Domain} from "../../model/Domain";
import DisplayCampaign from "../../components/Campaign/DisplayCampaign";
import {CircularProgress} from "@mui/material";

const EditCampaignPage: React.FC = (props) => {
    const {
        campaign,
        receiverGroups,
        templates,
        domains
    } = useLoaderData() as { campaign: Campaign, receiverGroups: ReceiverGroup[], templates: Template[], domains: Domain[] };

    return (
        <Suspense fallback={<CircularProgress/>}>
            <Await resolve={Promise.all([campaign, receiverGroups, templates, domains])}>
                {data => {
                    const campaignStatus = data[0].status;
                    if (campaignStatus === 'In configuration') {
                        return <CampaignEditor method="PUT" campaign={data[0]} receiverGroups={data[1]} templates={data[2]} domains={data[3]} />;
                    } else {
                        return <DisplayCampaign campaign={data[0]} receiverGroups={data[1]} templates={data[2]} domains={data[3]} />
                    } }}
            </Await>
        </Suspense>
    )
}

export default EditCampaignPage;

export async function loadCampaign(campaignId: string, customerId: string) {
    const response = await getCampaign(campaignId, customerId);

    if (!response.ok) {
        throw json({message: 'Could not fetch Campaign.'}, {status: 500});
    } else {
        const resData = await response.json();
        return resData
    }
}

export async function loadDomains(customerId: string) {
    const response = await listDomains(customerId);

    if (!response.ok) {
        throw json({message: 'Could not fetch Domains.'}, {status: 500});
    } else {
        const resData = await response.json();
        return resData
    }
}

export async function loader({
                                 request,
                                 params
                             }: { request: Request, params: { customerId: string, campaignId: string } }) {
    const campaignId = params.campaignId;
    const customerId = params.customerId;

    return defer({
        campaign: loadCampaign(campaignId, customerId),
        receiverGroups: loadCustomerReceiverGroups(customerId),
        templates: loadCustomerTemplates(customerId),
        domains: loadDomains(customerId),
    })
}