export function translateStatus(status: string, t: (key: string) => string) {
    switch (status) {
        case "In configuration":
            return t("campaignAnalyticsView.currentStatus.inconfiguration")
        case "Ready":
            return t("campaignAnalyticsView.currentStatus.ready")
        case "Running":
            return t("campaignAnalyticsView.currentStatus.running")
        case "Paused":
            return t("campaignAnalyticsView.currentStatus.paused")
        case "Finished":
            return t("campaignAnalyticsView.currentStatus.finished")
        case "Cancelled":
            return t("campaignAnalyticsView.currentStatus.cancelled")
        default:
            return ""
    }
}