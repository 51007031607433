import React from "react";
import {Card, CardContent, Grid} from "@mui/material";
import classes from '../../styling/components/analytics/HeaderCard.module.css';
import {ProgressBar} from "../Analytics/Base/ProgressBar";

interface Props {
    headline: JSX.Element,
    body: JSX.Element,
    progress?: number
}

export const HeaderCard: React.FC<Props> = (props) => {
    const {headline, body, progress} = props
    return (
        <Card className={classes.headercard}>
            <CardContent>
                <Grid container>
                    <Grid item xs={12} md={8}>
                        <div className={classes.textarea}>
                            <h2>{headline}</h2>
                            <p>{body}</p>
                            {progress ?
                                <ProgressBar color={"primary"} value={props.progress!} textColor={"white"}/> : null}
                        </div>
                    </Grid>
                </Grid>

            </CardContent>
        </Card>
    )
}