import {Await, useLoaderData} from "react-router-dom";
import ReceiverGroupEditor from "../../components/ReceiverGroup/ReceiverGroupEditor";
import React, {Suspense} from "react";
import {Receiver} from "../../model/Receiver";
import {ReceiverGroup} from "../../model/ReceiverGroup";
import {CircularProgress} from "@mui/material";

const EditReceiverGroupPage: React.FC = (props) => {
    const { receivers, receiverGroups } = useLoaderData() as { receivers: Receiver[], receiverGroups: ReceiverGroup[] };
    return (
        <Suspense fallback={<CircularProgress/>}>
            <Await resolve={Promise.all([receivers, receiverGroups])}>
                {data => <ReceiverGroupEditor method="PUT" receivers={data[0]} receiverGroups={data[1]} />}
            </Await>
        </Suspense>
    )
}

export default EditReceiverGroupPage;