import {Box, CircularProgress, Typography} from "@mui/material";
import React, {Suspense, useState} from "react";
import {Await, defer, json, useLoaderData} from "react-router";
import {Domain, DomainGroup} from "../../model/Domain";
import {getCampaignDomains} from "../../api/domainClient";
import {useTranslation} from "react-i18next";
import DisplayWhitelistingInfo from "../../components/Campaign/DisplayWhitelistingInfo";


const WhitelistingInfoPage: React.FC = (props) => {
    const { t } = useTranslation();
    const { campaignDomainGroups } = useLoaderData() as { campaignDomainGroups: DomainGroup[] };

    const [mailserverIp, setMailserverIp] = useState<string>(process.env.REACT_APP_MAILSERVER_IP!);
    return (<>
            <Suspense fallback={<CircularProgress/>}>
            <Await resolve={campaignDomainGroups}>
                {loadedDomainGroups => loadedDomainGroups.filter((group: DomainGroup) => group.type.match(/^(Sender|mailFrom)$/)).map((group: DomainGroup) =>
                    <DisplayWhitelistingInfo key={group.type} domainGroup={group} />
                )}
            </Await>
        </Suspense>
        <Box>
        <Typography variant="h4">Mailserver-IP</Typography> {mailserverIp}
        </Box>
    </>
    );

}

export default WhitelistingInfoPage;

export async function loadCampaignDomains(campaignId: string, customerId: string) {
    const response = await getCampaignDomains(campaignId, customerId);
    if (!response.ok) {
        throw json({ message: 'Could not fetch campaign Domains.' }, { status: response.status });
    } else {
        const resData: Domain[] = await response.json();
        const DomainGroups: DomainGroup[] = resData.reduce((domainGroups, domain) => {
            // Find existing group for the tag type
            const existingGroup = domainGroups.find(group => group.type === domain.type);
            // If a group exists, add the tag to the existing group, otherwise create a new group
            if (existingGroup) {
                existingGroup.domains.push(domain);
            } else {
                domainGroups.push({ type: domain.type, domains: [domain] });
            }

            return domainGroups;
        }, [] as DomainGroup[]);
        return DomainGroups;
    }
}

export async function loader({ request, params }: { request: Request, params: { campaignId: string, customerId: string } }) {

    return defer({
        campaignDomainGroups: loadCampaignDomains(params.campaignId, params.customerId),
    })
}