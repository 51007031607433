import { TextField, TextFieldProps } from '@mui/material';
import React, { useEffect, useState } from 'react';

interface CustomTextFieldProps extends Omit<TextFieldProps, 'name' | 'label' | 'defaultValue'> {
  name: string;
  label: string;
  defaultValue: string;
  maxLength?: number;
  required?: boolean
  regEx: RegExp;
  helper: string;
  onErrorChange: (name: string, errorState: boolean) => void;
}

const CustomTextField: React.FC<CustomTextFieldProps> = ({ name, label, defaultValue, maxLength, required, regEx, helper, onErrorChange, ...otherProps }) => {
  const [error, setError] = useState<boolean>(false);
  const [helperText, setHelperText] = useState<string>("");

  const changeHandler = (event: any) => {
    const value: string = event.target.value;
    let errorState: boolean = false;
    if (value.trim() === '') {
      errorState = true;
      setError(errorState);
      setHelperText(helper);
      onErrorChange(name, errorState);
    } else {
      errorState = false;
      setError(errorState);
      setHelperText("");
      onErrorChange(name, errorState);
    }
  }
  const blurHandler = (event: any) => {
    const value: string = event.target.value;
    let errorState: boolean = false;
    if (!value.match(regEx)) {
      errorState = true;
      setError(errorState);
      setHelperText(helper);
      onErrorChange!(name, errorState);
    } else {
      errorState = false;
      setError(errorState);
      setHelperText("");
      onErrorChange!(name, errorState);
    }
  }
  return (
    <TextField
      id={name}
      label={label}
      name={name}
      margin="normal"
      type="text"
      required = {required === false ? false : true}
      inputProps={{ maxLength: maxLength }}
      fullWidth
      defaultValue={defaultValue}
      error={error}
      onChange={changeHandler}
      onBlur={blurHandler}
      helperText={helperText}
      {...otherProps} // Spread other valid props
    />
  );
};

export default CustomTextField;
