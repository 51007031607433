import {ReceiverGroup} from "./ReceiverGroup"
import {Template} from "./Template"
import {Domain} from "./Domain"

export type Campaign = {
    campaignId?: string,
    name: string,
    startDate: Date,
    endDate: Date,
    dayStartTime: Date,
    dayEndTime: Date,
    sendDays: string,
    baselineEnabled: boolean,
    baselineEndDate: Date,
    timezone: string,
    status: string,
    baselineTemplates?: Template[],
    followUpTemplates?: Template[],
    receiverGroups: ReceiverGroup[],
    domains: Domain[],
}
export type CampaignStatus = {
    status: Status,
}
export type CampaignReceiverGroups = [
    {
        receiverGroupId: string
    }
]
export type CampaignDomains = [
    {
        domainId: string,
        type: string,
        domain: string,
    }
]
export type CampaignTemplate = {
        templateId: string
    }
export type CampaignTemplates = CampaignTemplate[];

export enum Status {
    IN_CONFIGURATION = "In configuration",
    READY = "Ready",
    RUNNING = "Running",
    PAUSED = "Paused",
    FINISHED = "Finished",
    CANCELLED = "Cancelled"
}