import { Form, json, redirect, useLocation, useNavigate, useNavigation, useParams, useSubmit } from 'react-router-dom';
import { createTemplate, deactivateTemplate, editTemplate } from '../../api/templateClient';
import EmailEditor from './EmailEditor';
import classes from '../../styling/components/Editor.module.css';
import React, { useEffect, useState } from 'react';
import { Template } from '../../model/Template';
import { Alert, Box, Button, TextField } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { LearningContent } from '../../model/LearningContent';
import { TemplateTag, TemplateTagGroup } from '../../model/TemplateTag';
import HintManager from './HintManager';
import DifficultySelect from './DifficultySelect';
import CustomTextField from '../Input/CustomTextfield';
import { useTranslation } from 'react-i18next';
import TemplateTagGroupSelect from './TemplateTagGroupSelect';

interface Props {
    method: string,
    template?: Template,
    templateTags: TemplateTag[]
}

const TemplateEditor: React.FC<Props> = (props) => {
    const { t } = useTranslation();
    const { method, template, templateTags } = props;
    const navigate = useNavigate();
    const navigation = useNavigation();
    const location = useLocation();
    const { customerId } = useParams();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    //Reset isSubmitting when page is reloaded
    useEffect(() => {
        // Reset isSubmitting when location changes, indicating navigation
        setIsSubmitting(false);
    }, [location]);

    function cancelHandler() {
        navigate('..');
    }

    const [errorStates, setErrorStates] = useState({});
    const [hasError, setHasError] = useState<boolean>(false);

    // Callback function to update the error state for a specific field
    const handleTextFieldError = (name: string, errorState: boolean) => {
        setErrorStates(prevErrorStates => {
            const updatedErrorStates = {
                ...prevErrorStates,
                [name]: errorState,
            };
    
            // Now checking inside the update function, where we have the updated state
            const anyError = Object.values(updatedErrorStates).some(error => error);
            setHasError(anyError);
    
            return updatedErrorStates;
        });
    };

    function previewHandler() {
        window.open(location.pathname + '/preview','_blank')
    }

    async function deleteHandler() {
        const proceed = window.confirm(`${t('templateEditor.confirmDelete')}`);
        if (proceed) {
            const response = await deactivateTemplate(props.template!.templateId!, customerId!);
            if (!response.ok) {
                throw json({ message: `${t('templateEditor.deleteError')}` }, { status: response.status });
            }
            else {
                return navigate('..');
            }
        }
    }
    const [body, setBody] = useState<string>((template && template.body !== null) ? template.body : '');

    const bodyChangeHandler = (value: string) => {
        setBody(value);
    }
    // Difficulty
    const [difficulty, setDifficulty] = useState<string>(template ? template.difficulty : "");

    const handleDifficultyChange = (event: SelectChangeEvent) => {
        setDifficulty(event.target.value as string);
    };

    // Hints starting here
    const [hints, setHints] = useState<LearningContent[]>([]);

    // Callback function to handle changes in hints
    const handleHintsChange = (updatedHints: LearningContent[]) => {
        setHints(updatedHints);
    };
    //Validate body before submit
    const submit = useSubmit();
    const [templateSaveError, setTemplateSaveError] = useState<boolean>(false);
    const [templateSaveErrorText, setTemplateSaveErrorText] = useState<string>("");
    const checkSubstringOnce = (string: string, substring: string) => {
        // Find the first occurrence of the substring
        const firstIndex: number = string.indexOf(substring);

        // If the substring is not found, throw an error
        if (firstIndex === -1) {
            setTemplateSaveErrorText(`${t('templateEditor.placeholderNotFound1')} ${substring} ${t('templateEditor.placeholderNotFound2')}`);
            setTemplateSaveError(true);
            return false;
        }

        // Find the next occurrence of the substring after the first one
        const secondIndex = string.indexOf(substring, firstIndex + 1);

        // If another occurrence is found, throw an error
        if (secondIndex !== -1) {
            setTemplateSaveErrorText(`${t('templateEditor.placeholderMoreThanOnce1')} ${substring} ${t('templateEditor.placeholderMoreThanOnce2')}`);
            setTemplateSaveError(true);
            return false;
        }
        // If no more occurrences are found, the substring is contained exactly once
        return true;
    }
    const bodyValidated = () => {
        let tempBody = body;
        for (let i = 0; i < hints.length; i++) {
            const hint: LearningContent = hints[i];
            if (hint.location.includes("l_body")) {
                //Check if all defined hints are correctly opened and closed
                let hintId: string = hint.location.charAt(7);
                let openingPlaceholder: string = `$L${hintId}OPEN$`;
                let closingPlaceholder: string = `$L${hintId}CLOSE$`;
                if (!checkSubstringOnce(tempBody, openingPlaceholder))
                    return false;
                if (!checkSubstringOnce(tempBody, closingPlaceholder))
                    return false;
                //Remove all occurences of valid placeholders
                tempBody = tempBody.replace(openingPlaceholder, "");
                tempBody = tempBody.replace(closingPlaceholder, "");
            }
        }
        // check if body still contains open or close placeholders after removing all valid, indicating there are hints openend which are not defined
        if (tempBody.includes("OPEN$") || tempBody.includes("CLOSE$")) {
            setTemplateSaveErrorText(`${t('templateEditor.placeholderUndefinedHint')}`);
            setTemplateSaveError(true);
            return false;
        }
        return true;
    }
    const submitHandler = (event: any, method: string) => {
        setIsSubmitting(true);
        event.preventDefault();
        const target = event.currentTarget;
        target.method = method;
        setTemplateSaveErrorText("");
        setTemplateSaveError(false);
        if (bodyValidated() === true)
            submit(target);
        else {
            setIsSubmitting(false);
        }
    }
    // TemplateTags
    // Use reduce to group templateTags by type
    const templateTagGroups: TemplateTagGroup[] = templateTags.reduce((tagGroups, tag) => {
        // Find existing group for the tag type
        const existingGroup = tagGroups.find(group => group.type === tag.type);

        // If a group exists, add the tag to the existing group, otherwise create a new group
        if (existingGroup) {
            existingGroup.tags.push(tag);
        } else {
            tagGroups.push({ type: tag.type, tags: [tag] });
        }

        return tagGroups;
    }, [] as TemplateTagGroup[]);
    const [selectedTemplateTagGroups, setSelectedTemplateTagGroups] = useState<TemplateTagGroup[]>(() => {
        // Initialize selectedTemplateTagGroups only when the template prop is provided
        if (template) {
            return template.templateTags.reduce((tagGroups, tag) => {
                const existingGroup = tagGroups.find((group) => group.type === tag.type);

                if (existingGroup) {
                    existingGroup.tags.push(tag);
                } else {
                    tagGroups.push({ type: tag.type, tags: [tag] });
                }

                return tagGroups;
            }, [] as TemplateTagGroup[]);
        }

        return []; // Default value if template prop is not provided
    });
    
    return (
        <Box component={Form} method={method as any} className={classes.form} onSubmit={(event) => submitHandler(event, method)} autoComplete='off'>
            <CustomTextField name="name" label={t('templateEditor.nameLabel')} defaultValue={template ? template.name : ''} maxLength={50} regEx={/^[A-Za-z0-9\s!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~À-ÖØ-öø-ÿ]+$/} helper={t('templateEditor.nameHelper')} onErrorChange={handleTextFieldError}/>
            <DifficultySelect difficulty={difficulty} handleDifficultyChange={handleDifficultyChange} />
            <CustomTextField name="subject" label={t('templateEditor.subjectLabel')} defaultValue={template ? template.subject : ''} maxLength={100} regEx={/^[A-Za-z0-9\s!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~À-ÖØ-öø-ÿ]+$/} helper={t('templateEditor.subjectHelper')} onErrorChange={handleTextFieldError}/>
            <CustomTextField name="senderAddress" label={t('templateEditor.senderAddressLabel')} defaultValue={template ? template.senderAddress : ''} maxLength={100} regEx={/^(?:[a-zA-Z$0-9À-ÖØ-öø-ÿ\s]+<([a-zA-Z0-9._%+-]+@((\$SUBDOMAIN\$|[a-zA-Z0-9.-]+\.)*(\$SENDER[1-4]\$)|\$DOMAIN\$))>|([a-zA-Z0-9._%+-]+@((\$SUBDOMAIN\$|[a-zA-Z0-9.-]+\.)*(\$SENDER[1-4]\$)|\$DOMAIN\$)))\s*$/} helper={t('templateEditor.senderAddressHelper')} onErrorChange={handleTextFieldError}/>
            <CustomTextField name="replyToAddress" label={t('templateEditor.replyToAddressLabel')} defaultValue={template ? template.replyToAddress : ''} maxLength={100} regEx={/^(?:[a-zA-Z$0-9À-ÖØ-öø-ÿ\s]+<([a-zA-Z0-9._%+-]+@((\$SUBDOMAIN\$|[a-zA-Z0-9.-]+\.)*(\$SENDER[1-4]\$)))>|([a-zA-Z0-9._%+-]+@((\$SUBDOMAIN\$|[a-zA-Z0-9.-]+\.)*(\$SENDER[1-4]\$))))\s*$/} helper={t('templateEditor.replyToAddressHelper')} onErrorChange={handleTextFieldError}/>
            <EmailEditor onChange={bodyChangeHandler} model={body} hints={hints} />
            <TextField id="body" label="Body" name="body" type="text" className={classes.displayNone} required fullWidth aria-readonly value={body} />
            <TemplateTagGroupSelect templateTagGroups={templateTagGroups} selectedTemplateTagGroups={selectedTemplateTagGroups} setSelectedTemplateTagGroups={setSelectedTemplateTagGroups} />
            <HintManager template={template} onHintsChange={handleHintsChange} />
            {templateSaveError && <Alert onClose={() => { setTemplateSaveError(false) }} severity="error">{templateSaveErrorText}</Alert>}
            <div>
                {/* {method === 'PUT' && <Button type="button" className={classes.delete} variant="contained" onClick={deleteHandler} disabled={isSubmitting}>{t('templateEditor.deactivate')}</Button>} */}
                <Button type="button" variant="outlined" onClick={cancelHandler} disabled={isSubmitting}>{t('templateEditor.cancel')}</Button>
                {method === 'PUT' && <Button type="button" variant="outlined" onClick={previewHandler} disabled={isSubmitting}>{t('templateEditor.preview')}</Button>}
                <Button type="submit" variant="contained" disabled={isSubmitting || hasError}>{t('templateEditor.save')}</Button>
            </div>
        </Box>
    );
}

export default TemplateEditor;

export async function action({ request, params }: { request: Request, params: any }) {
    const method = request.method;
    const customerId = params.customerId;
    const data = await request.formData();
    // Transform templateTags into array like [{templateTagId: 1}, {templateTagId: 2}, {templateTagId: 3}, {templateTagId: 4}, {templateTagId: 5}]
    const dataObject = Object.fromEntries(data);
    const templateTagIdsByType = Object.entries(dataObject)
        .filter(([key]) => key.includes("tag"))
        .map(([key, value]) => ({ "templateTagId": value as unknown as string }))
        .filter(tagObject => tagObject.templateTagId.trim() !== ''); // Filter out objects with empty templateTagId
    const templateTagIds = templateTagIdsByType.flatMap(obj => {
        const valuesArray = obj.templateTagId.split(',').map(id => parseInt(id));
        return valuesArray.map(templateTagId => ({ templateTagId }));
    });
    // Extract the hints dynamically
    const learningContents: LearningContent[] = [];

    // Using forEach on the array of keys
    data.forEach((value, key) => {
        // Check if the key starts with 'l_' and ends with '_title'
        if (key.startsWith('l_') && key.endsWith('_title')) {
            const location = key.slice(0, -6); // Extract location from the key
            const title = data.get(`${location}_title`) as string;
            const content = data.get(`${location}_content`) as string;

            // Create a LearningContent object and add it to the array
            learningContents.push({ location, title, content });
        }
    });
    const templateData = {
        name: data.get('name') as string,
        difficulty: data.get('difficulty') as string,
        subject: data.get('subject') as string,
        senderAddress: data.get('senderAddress') as string,
        replyToAddress: data.get('replyToAddress') as string,
        body: data.get('body') as string,
        learningContents: learningContents,
        active: true,
        customer: {
            customerId: customerId,
        },
        templateTags: templateTagIds as unknown as TemplateTag[],
    };

    if (method === 'POST') {
        const response = await createTemplate(templateData, customerId);
        if (!response.ok) {
            throw json({ message: 'Could not add template to customer.' }, { status: 500 });
        }
        else {
            const data = await response.json();
            return redirect('../' + data.templateId);
        }
    }

    if (method === 'PUT') {
        const templateId = params.templateId;
        const response = await editTemplate(templateData, templateId, customerId);

        if (!response.ok) {
            throw json({ message: 'Could not update template in template.' }, { status: 500 });
        } else {
            return redirect ('.');
        }
    }
}