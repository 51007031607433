import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    List,
    ListItem,
    ListItemText,
    Typography
} from "@mui/material";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";

interface Props {
  open: boolean
  onClose: () => void
  learningLinks: string[]
    loading?: boolean
}

export const LearningLinksDialog: React.FC<Props> = (props) => {
  const { t } = useTranslation();
    const {onClose, open, learningLinks, loading} = props;

  const handleClose = () => {
    onClose();
  };

  const [isCopied, setIsCopied] = useState<boolean>(false);

  const handleCopyToClipboard = () => {
    // Copy the learningLinks array to the clipboard
    navigator.clipboard.writeText(learningLinks.join('\n'))
      .then(() =>
        setIsCopied(true))
      .catch((err) => console.error(err));
  };


  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>{t('learningLinksDialog.learningLinksTitle')}</DialogTitle>
      <DialogContent>
          {loading ? <CircularProgress/> :
              <List>
                  {learningLinks.length > 0 && learningLinks.map((learningLink) => (
                      <ListItem key={learningLink} disableGutters>
                          <ListItemText>
                              <Typography>
                                  {learningLink.split(/(https?:\/\/\S+)/).map((part, index) => (
                                      index % 2 === 0 ? (
                                          <span key={index}>{part}</span>
                                      ) : (
                                          <a key={index} href={part} target="_blank" rel="noopener noreferrer">
                                              {part}
                                          </a>
                                      )
                                  ))}
                              </Typography>
                          </ListItemText>
                      </ListItem>
                  ))}
              </List>
          }
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCopyToClipboard} variant="contained" color="primary">
          {isCopied ? t('learningLinksDialog.copied') : t('learningLinksDialog.copyToClipboard')}
        </Button>
      </DialogActions>
    </Dialog>
  );

}

export default LearningLinksDialog;