import {Card, CardContent, Grid} from "@mui/material";
import React from "react";
import classes from '../../../styling/components/analytics/StatCard.module.css';
import {ArcElement, Chart as ChartJS, ChartData, Tooltip} from "chart.js";
import {Doughnut} from "react-chartjs-2";
import {toPct} from "../../../util/Calc";

interface Props {
    headline: string | React.ReactElement,
    icon: JSX.Element,
    divisor: number,
    dividend: number,
    className?: string
    data: ChartData<'doughnut'>
    doughnutSize: number
}

export const StatCard: React.FC<Props> = (props) => {

    ChartJS.register(ArcElement, Tooltip)

    const rate = toPct(props.divisor, props.dividend)

    return (
        <div className={classes.statcard + " statCard " + (props.className ?? "")}>
            <Card>
                <CardContent className={classes.cardContent}>
                    <Grid container direction={'column'}>
                        <Grid item xs={3}>
                            <Grid container justifyContent={'space-between'} marginBottom={'20px'}
                                  alignItems={'center'}>
                                <Grid item xs={10}>
                                    <span className={classes.headline}>{props.headline}</span>
                                </Grid>
                                <Grid item xs={2}>
                                    <div className={classes.icon}>
                                        {props.icon}
                                    </div>
                                </Grid>
                            </Grid>

                        </Grid>
                        <Grid item xs={9}>
                            <Grid container alignItems={'flex-end'}>
                                <Grid item xs={12} lg>
                                    <div className={classes.rate}>{rate} %</div>
                                    <div className={classes.amounts}>{props.divisor} / {props.dividend}</div>
                                </Grid>
                                <Grid item xs={false} lg={props.doughnutSize}>
                                    <Doughnut data={props.data} options={{plugins: {legend: {display: false}}}}/>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>


                </CardContent>
            </Card>
        </div>

    )
}