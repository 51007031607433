import {Alert, Box, Button} from "@mui/material";
import React, {useState} from "react";
import {json, useNavigate, useParams} from "react-router-dom";
import {createReceivers} from "../../api/receiverClient";
import classes from "../../styling/components/ReceiverCsvUpload.module.css";
import {Receiver} from "../../model/Receiver";
import {useTranslation} from "react-i18next";

const ReceiverCsvUpload: React.FC = (props) => {
    const { t } = useTranslation();
    const [addReceiversButtonDisabled, setAddReceiversButtonDisabled] = useState(true);
    const [filetypeError, setFileTypeError] = useState(false);
    const {customerId} = useParams();
    const navigate = useNavigate();
    const [file, setFile] = useState<File>();
    const fileReader = new FileReader();

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files![0].name.match(/.+(\.csv)$/)) {
            setAddReceiversButtonDisabled(false);
            setFileTypeError(false);
            setReceiverDataError(false);
            setReceiverUploadSuccessful(false);
            setReceiverUploadSuccessfulText("");
            setReceiverDataErrorText("");
            setFile(event.target.files![0]);
        } else {
            setAddReceiversButtonDisabled(true);
            setFileTypeError(true);
        }

    };

    const [receiverDataError, setReceiverDataError] = useState<boolean>(false);
    const [receiverDataErrorText, setReceiverDataErrorText] = useState<string>("");
    const [receiverUploadSuccessful, setReceiverUploadSuccessful] = useState<boolean>(false);
    const [receiverUploadSuccessfulText, setReceiverUploadSuccessfulText] = useState<string>("");

    const createReceiversFromCsv = async (receiverData: any) => {
        const response = await createReceivers(receiverData, customerId!);
        if (!response.ok) {
            throw json({ message: `${t('receiverCsvUpload.createReceiversError')}` }, { status: 500 });
        }
        else {
            const newReceivers: Receiver[] = await response.json();
            setReceiverUploadSuccessful(true);
            if (newReceivers.length == 0) {
                setReceiverUploadSuccessfulText(`${t('receiverCsvUpload.uploadEmailAlreadyExist')}`);
            } else {
                setReceiverUploadSuccessfulText(newReceivers.length + `${t('receiverCsvUpload.uploadSuccessful')}`);
            }  
        }
    }

    const handleOnSubmit = (event: React.MouseEvent) => {
        if (file) {
            fileReader.onload = (event) => {
                const csvUpload = event.target!.result;
                const receiverData = csvToJSON(csvUpload as string);
                createReceiversFromCsv(receiverData as any);
            };
            fileReader.readAsText(file);
        }
    };
    const csvToJSON = (csv: string) => {
        csv = csv.replaceAll("\r", "");
        let lines = csv.split("\n");
        let result = [];
        let headers: string[] = lines[0].split(";");
        for (let i = 0; i < headers.length; i++) {
            switch (headers[i]) {
                case "First name":
                case "Vorname":
                    headers[i] = "firstName";
                    break;
                case "Last name":
                case "Nachname":
                    headers[i] = "lastName";
                    break;
                case "Gender":
                case "Geschlecht":
                    headers[i] = "gender";
                    break;
                case "Email":
                case "E-Mail":
                    headers[i] = "emailAddress";
                    break;
                case "Receiver group":
                case "Empfängergruppe":
                case "Abteilung":
                    headers[i] = "receiverGroups";
                    break;
                case "":
                    break;
                default:
                    setReceiverDataError(true);
                    setReceiverDataErrorText(`${t('receiverCsvUpload.unknownColumnError')}`);
                    throw new Error(`${t('receiverCsvUpload.unknownColumnError')}`);
            }
        }
        let errortext: string = "";
        for (let i = 1; i < lines.length; i++) {
            let obj: any = {};
            let currentline = lines[i].split(";");
            for (let j = 0; j < headers.length; j++) {
                //Now validate each entry based on which column(attribute)
                switch (headers[j]) {
                    case "firstName":
                        if (!currentline[j].match(/^[A-Za-zßüöäÜÖÄáéíóúÁÉÍÓÚàèéìòùÀÈÉÌÒÙåäöçñêïûÅÄÖÇÑÊÏÛæœ]+([-'\s]?[A-Za-zßüöäÜÖÄáéíóúÁÉÍÓÚàèéìòùÀÈÉÌÒÙåäöçñêïûÅÄÖÇÑÊÏÛæœ]+)*$/)) {
                            errortext += `${t('receiverCsvUpload.firstNameError')}` + (i + 1) + `${t('receiverCsvUpload.columnError')}` + (j + 1) + `${t('receiverCsvUpload.linebreak')}`;                        }
                        break;
                    case "lastName":
                        if (!currentline[j].match(/^[A-Za-zßüöäÜÖÄáéíóúÁÉÍÓÚàèéìòùÀÈÉÌÒÙåäöçñêïûÅÄÖÇÑÊÏÛæœ]+([-'\s]?[A-Za-zßüöäÜÖÄáéíóúÁÉÍÓÚàèéìòùÀÈÉÌÒÙåäöçñêïûÅÄÖÇÑÊÏÛæœ]+)*$/)) {
                            errortext += `${t('receiverCsvUpload.lastNameError')}` + (i + 1) + `${t('receiverCsvUpload.columnError')}` + (j + 1) + `${t('receiverCsvUpload.linebreak')}`;                        }
                        break;
                    case "gender":
                        if (!currentline[j].match(/^[mfx]?$/)) {
                            errortext += `${t('receiverCsvUpload.genderError')}` + (i + 1) + `${t('receiverCsvUpload.columnError')}` + (j + 1) + `${t('receiverCsvUpload.linebreak')}`;                        }
                        break;
                    case "emailAddress":
                        if (!currentline[j].match(/^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/)) {
                            errortext += `${t('receiverCsvUpload.emailError')}` + (i + 1) + `${t('receiverCsvUpload.columnError')}` + (j + 1) + `${t('receiverCsvUpload.linebreak')}`;                        }
                        break;
                    case "receiverGroups":
                        if (!currentline[j].match(/^[A-Za-zßüöäÜÖÄáéíóúÁÉÍÓÚàèéìòùÀÈÉÌÒÙåäöçñêïûÅÄÖÇÑÊÏÛæœ\d\s\-]*(,[A-Za-zßüöäÜÖÄáéíóúÁÉÍÓÚàèéìòùÀÈÉÌÒÙåäöçñêïûÅÄÖÇÑÊÏÛæœ\d\s\-]+)*$/)) {
                            errortext += `${t('receiverCsvUpload.receiverGroupError')}` + (i + 1) + `${t('receiverCsvUpload.columnError')}` + (j + 1) + `${t('receiverCsvUpload.linebreak')}`;                        }
                        break;
                }
                obj[headers[j]] = currentline[j].trim();
            }

            result.push(obj);
        }
        if (errortext.length != 0) {
            setReceiverDataError(true);
            setReceiverDataErrorText(errortext);
            throw new Error(`${t('receiverCsvUpload.validCsvError')}`);
        }
        // Add correct customer to result
        result = result.map(receiver => ({
            ...receiver,
            customer: {
                customerId: parseInt(customerId!)
            }
        }));
        // Change format of receiverGroups in result: Check if receiver has any receivergroups, if yes, create array with one entry per receivergroup, if not, pass empty array
        result = result.map(receiver => ({
            ...receiver,
            receiverGroups:
                receiver.receiverGroups ? receiver.receiverGroups.split(',').map((receiverGroup: string) => (
                    { name: receiverGroup.trim() }
                )) : []

        }));
        return result;
    }

    return (
        <Box className={classes.container}>
            <input
                type={"file"}
                id={"receiverCSV"}
                accept={".csv"}
                onChange={handleOnChange}
            />
            <Button variant="contained"
                disabled={addReceiversButtonDisabled}
                onClick={(event) => {
                    handleOnSubmit(event);
                }}
            >
                {t('receiverCsvUpload.addReceivers')}
            </Button>
            {receiverDataError && <Alert onClose={() => { setReceiverDataError(false) }} severity="error">{receiverDataErrorText}</Alert>}
            {receiverUploadSuccessful && <Alert closeText="Reload" onClose={() => { 
                navigate(0);
                setReceiverUploadSuccessful(false) }} severity="success">{receiverUploadSuccessfulText}</Alert>}
            {filetypeError && <Alert className={classes.alert} severity="error">{t('receiverCsvUpload.filetypeError')}</Alert>}
        </Box>
    );
}

export default ReceiverCsvUpload;