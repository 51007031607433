import React from "react";

interface Props {
    title: string
}

export const LoginPageHeader: React.FC<Props> = (props) => {

    return (
        <>
            <h2 style={{textAlign: "center", marginBottom: "10px"}}>{props.title}</h2>
        </>
    )
}