export type Domain = {
    domainId?: string,
    type: string,
    placeholder?: string,
    domain: string,
}
export type DomainGroup ={
    type: string;
    domains: Domain[];
}

export enum Placeholder {
    NONE = "",
    MSLOGIN = "MSLOGIN",
    INTRANET = "INTRANET",
    SENDER1 = "SENDER1",
    SENDER2 = "SENDER2",
    SENDER3 = "SENDER3",
    SENDER4 = "SENDER4",
    LINK1 = "LINK1",
    LINK2 = "LINK2",
    LINK3 = "LINK3",
    LINK4 = "LINK4",
    MAILFROM = "MAILFROM",
}

export enum MailFromPlaceholder {
    MAILFROM = "MAILFROM",
}

export enum SenderPlaceholder {
    SENDER1 = "SENDER1",
    SENDER2 = "SENDER2",
    SENDER3 = "SENDER3",
    SENDER4 = "SENDER4",
}

export enum LinkPlaceholder {
    LINK1 = "LINK1",
    LINK2 = "LINK2",
    LINK3 = "LINK3",
    LINK4 = "LINK4",
}

export enum LpPlaceholder {
    MSLOGIN = "MSLOGIN",
    INTRANET = "INTRANET",
}

export enum NonePlaceholder {
    NONE = "",
}