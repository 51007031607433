import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: false,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          analyticsOverview: {
            welcomeBackHeader: 'Welcome back!',
            welcomeBackText: 'Below you will find an overview of your campaign. Select a campaign to view a detailed breakdown.',
            campaignDuration: 'Duration: {{start}} to {{end}}',
            mailsSent: 'Mails sent: ',
            sent: 'Sent',
            pending: 'Pending'
          },
          statCard: {
            clicked: 'Clicked',
            notClicked: 'Not clicked',
              reported: 'Reported',
              notReported: 'Not reported',
            criticalInteraction: 'Critical \ninteractions',
            nonCriticalInteraction: 'Non-crictical \ninteractions',
            noInteraction: 'No interaction'
          },
            trendStatCard: {
              clickRateRunning: 'Click rate {{startDate}} - today',
              reportingRateRunning: 'Reporting rate {{startDate}} - today',
              clickRateFinished: 'Click rate {{startDate}} - {{endDate}}',
              reportingRateFinished: 'Reporting rate {{startDate}} - {{endDate}}'
            },
            templateCard: {
                showAll: "Show all",
                showLess: "Show less"
            },
          campaignAnalyticsView: {
            status: 'Status: ',
            sent: 'Sent: ',
            opened: 'Opened: ',
            clicked: 'Clicked: ',
            receiverGroupStatistics: 'Receiver groups statistics',
            templateStatistics: 'Template statistics',
            monthlyStatistics: 'Monthly statistics',
            month: 'Month',
            currentStatus: {
              inconfiguration: "In configuration",
              ready: "Ready",
              running: "Running",
              paused: "Paused",
              finished: "Finished",
              cancelled: "Cancelled"
            },
            runningUntil: 'This campaign runs until {{endDate}}.',
            campaignFinished: 'This campaign finished on the {{endDate}}.',
              campaignInfo: 'The campaign {{name}} is running since {{startDate}}. {{sent}} of {{total}} mails have already been sent.',
              campaignInfoBaseline: 'the campaign {{name}} is currently in the baseline phase until {{baselineEndDate}}. {{sent}} of {{total}} mails have already been sent.',
              campaignInfoFollowUp: 'the campaign {{name}} is currently in the main phase since {{baselineEndDate}}. {{sent}} of {{total}} mails have already been sent.',
            campaignInfoFinished: 'this campaign ended on the {{endDate}}. A total of {{sent}} mails have been sent. {{clickrate}}% of all recipients have clicked on a link in an e-mail.',
              reportedCardTitle: 'Reporting rate (total)',
            sentState: 'Mails sent',
            clickedCardTitle: 'Click rate (total)',
            clickRate: 'Click rate',
            interactedCardTitle: 'Interaction rate (total)',
              interactionRateCritical: 'Interaction rate (critical)',
              interactionRateNonCritical: 'Interaction rate (non-critical)',
            interactionRate: 'Interaction Rate',
              reportingRate: 'Reporting Rate',
              graphedAnalyticsCardTitle: 'Monthly analysis',
              receiverGroup: 'Department',
              baselineComparisonCardTitle: 'Training progress',
              greeting: "Hello {{name}},"
          },
            explanations: {
              interactionrate: "The Interaction Rate shows the percentage of users who interacted with a fake login page in the phishing simulation. Critical interactions are dangerous actions, such as entering passwords. Non-critical interactions are less risky actions, like clicking on \"Forgot Password\".",
              graphCard: "Here you can evaluate the development of click and reporting rate over time. Hover over data points to see the data for that month. On the right, you can see a comparison between the current and the past month.",
              templateCard: "A breakdown of how your users perform in regards to each specific E-Mail in the campaign. Use the button on the top-right to switch between viewing the click and the interaction rate.",
              baselineCard: "At the start of a campaign, we conduct a baseline phase to gauge the performance of your users. Here you can evaluate how your metrics developed compared to that initial baseline phase.",
              clickrate: "The Click Rate shows the percentage of users who clicked on a link in a simulated phishing mail.",
              reportingrate: "The Reporting Rate shows the percentage of users who reported a simulated phishing mail via the look2x-report-button.",
          },
          confirmDomainsDialog: {
            confirmDomainSelection: 'Confirm Domain Selection',
            areYouSure: 'Are you sure you want to update domains? Please double-check your selection.',
            pauseWarning: 'Warning: This will pause running campaigns! Please Adjust whitelisting information before resuming.',
            selectedSender: 'Selected Sender Domains: ',
            selectedStandard: 'Selected Standard Domains: ',
            selectedMailFrom: 'Selected MailFrom Domains: ',
            cancel: 'Cancel',
            continue: 'Continue',
          },
          confirmReceiverGroupDialog: {
            confirmReceiverGroupSelection: 'Confirm receiver group selection',
            areYouSure: 'Are you sure you want to update receiver groups? This will add newly added receivers to the campaign. Please confirm the receivergroup selection down below.',
            selectedReceiverGroups: 'Selected receiver groups: ',
            cancel: 'Cancel',
            continue: 'Continue',
          },
          confirmTemplatesDialog: {
            confirmTemplateSelection: 'Confirm template selection',
            areYouSure: 'Are you sure you want to update templates? Please double-check your selection. ',
            cancel: 'Cancel',
            continue: 'Continue',
            baselineTemplates: 'Baseline templates',
            followUpTemplates: 'Follow-up templates',
          },
          domainUpdate: {
            senderDomains: 'Sender domains',
            standardDomains: 'Standard domains',
            mailFromDomains: 'MailFrom domains',
            updateDomains: 'Update Domains',
            updatedSuccessfully: 'Domains updated successfully!',
            updatedError: 'Domains could not be updated! Please select at least one sender, one standard and one mailFrom domain.',
          },
          receiverGroupUpdate: {
            updateReceivers: 'Update Receivers',
            updatedSuccessfully: 'Receivers updated successfully!',
            updatedError: 'Receivers could not be updated! Please select at least on receiver group.'
          },
          templateUpdate: {
            updateTemplates: 'Update Templates',
            updatedSuccessfully: 'Templates updated successfully!',
            updatedError: 'Templates could not be updated! Please select at least one template.',
            baselineTemplates: 'Baseline templates',
            followUpTemplates: 'Follow-up templates',
          },
          campaignDateInput: {
            startDate: 'Start date',
            endDate: 'End date',
          },
          campaignEditor: {
            confirmDelete: 'Are you sure?',
            deleteError: 'Could not delete campaign.',
            templateError: 'Please select at least one template. ',
            sendTimeError: 'End send time must be later than start time! ',
            sendDayError: 'Please select at least one sendDay! ',
            senderDomainError: 'Please select at least one sender domain! ',
            standardDomainError: 'Please select at least one standard domain! ',
            mailFromDomainError: 'Please select exactly one mailFrom domain! ',
            receiverGroupError: 'Please select at least one receiver group! ',
            nameLabel: 'Name',
            nameHelper: 'Please provide a valid campaign name!',
            senderDomainLabel: 'Sender domains',
            standardDomainLabel: 'Standard domains',
            mailFromDomainLabel: 'MailFrom domains',
            templateLabel: 'Templates',
            enableBaseline: 'Enable baseline',
            baselineTemplates: 'Baseline templates',
            followUpTemplates: 'Follow-up templates',
            baselineDuration: 'Baseline duration (weeks)',
            delete: 'Delete',
            cancel: 'Cancel',
            save: 'Save',
            saveAndReady: 'Save & Mark as Ready',
            saveAndContinue: 'Save and continue campaign',
          },
          campaignReceiverGroupSelect: {
            receiverGroups: 'Receiver groups',
          },
          campaignSendDayInput: {
            monday: 'Monday',
            tuesday: 'Tuesday',
            wednesday: 'Wednesday',
            thursday: 'Thursday',
            friday: 'Friday',
            saturday: 'Saturday',
            sunday: 'Sunday',
            senddays: 'Senddays',
          },
          campaignTable: {
            updateCampaignError: 'Could not update campaign status.',
            markAsReady: 'Mark as Ready',
            markAsInConfig: 'Mark as In config',
            pauseCampaign: 'Pause campaign',
            continueCampaign: 'Continue campaign',
            cannotChangeFinished: 'Cannot change status of finished campaign',
            cannotChangeCancelled: 'Cannot change status of cancelled campaign',
            receiverGroups: 'Receiver Groups',
            templates: 'Templates',
            changeStatus: 'Change Status',
            currentStatus: {
              inconfiguration: "In configuration",
              ready: "Ready",
              running: "Running",
              paused: "Paused",
              finished: "Finished",
              cancelled: "Cancelled"
            },
            testmailSentSuccessfully: 'Testmails sent successfully',
            testmailSentError: 'Testmails could not be sent',
            status: 'Status',
            edit: 'Edit',
            dispatches: 'Dispatches',
            testmail: 'Testmail',
            learningLinks: 'Generate links',
            send: 'Send',
            viewDispatches: 'View Dispatches',
            whitelisting: 'Whitelisting',
            viewWhitelisting: 'View Whitelisting',
            changeStatusConfirmationTitle: 'Change status',
            changeStatusConfirmationContent: 'Changing "In config" to "Ready" starts the campaign if the start date is in the past. Are you sure you want to change the status of this campaign?',
            testmailConfirmationTitle: 'Send testmails',
            testmailConfirmationContent: 'Sending testmails will send ALL campaign templates to the recipient specified in "TestmailConfig". Are you sure you want to send testmails for this campaign?',
          },
          campaignTimeInput: {
            startTimeLabel: 'Start send time',
            endTimeLabel: 'End send time',
            timeZoneLabel: 'Timezone'
          },
          displayCampaign: {
            confirmCancel: 'Are you sure?',
            cancelError: 'Could not cancel campaign.',
            monday: 'Monday',
            tuesday: 'Tuesday',
            wednesday: 'Wednesday',
            thursday: 'Thursday',
            friday: 'Friday',
            saturday: 'Saturday',
            sunday: 'Sunday',
            nameLabel: 'Name',
            sendDaysLabel: 'Send days',
            enableBaseline: 'Baseline is active',
            baselineEndDate: 'Baseline enddate',
            backToOverview: 'Back to campaign overview',
            cancelCampaign: 'Cancel campaign',
            deleteCampaign: 'Delete campaign',
            deleteConfirmationTitle: 'Delete campaign',
            deleteConfirmationContent: 'Deleting this campaign will delete ALL associated data (dispatches, analytics). Are you sure you want to delete this campaign?',
            cancelConfirmationTitle: 'Cancel campaign',
            cancelConfirmationContent: 'Cancelling this campaign will unschedule all dispatches. Resuming will not be possible. If you want to resume later, pause the campaign instead. Are you sure you want to cancel this campaign?',
          },
          customerEditor: {
            confirmCancel: 'Are you sure?',
            cancelError: 'Could not delete customer',
            shortNameLabel: 'Company Name (short)',
            shortNameHelper: 'Please provide the short form of your company name!',
            fullNameLabel: 'Company Name',
            fullNameHelper: 'Please provide a valid company name!',
            domainLabel: 'Domain',
            domainHelper: 'Please provide a valid domain!',
            zipcodeLabel: 'Zipcode',
            zipcodeHelper: 'Please provide a valid zipcode!',
            zipcodeCity: 'City',
            cityHelper: 'Please provide a valid city!',
            streetLabel: 'Street',
            streetHelper: 'Please provide a valid street and number!',
            primaryColorLabel: 'Primary color',
            primaryColorHelper: 'Please provide a valid color (hex or rgb)',
            secondaryColorLabel: 'Secondary color',
            secondaryColorHelper: 'Please provide a valid color (hex or rgb)',
            enableBranding: 'Enable branding',
            enableReportButton: 'Enable report button',
            helpdeskEmailLabel: 'Helpdesk E-Mail',
            helpdeskEmailHelper: 'Please provide a valid email',
            msTenantIdLabel: 'MS365 Tenant ID',
            msTenantIdHelper: 'Bitte geben Sie eine gültige MS365 Tenant ID an!',
            enableBase64: 'Attach images as base64',
            demoCustomer: 'Demo',
            phoneLabel: 'Phone',
            phoneHelper: 'Please provide a valid phone number!',
            delete: 'Delete',
            cancel: 'Cancel',
            save: 'Save',
            deleteConfirmationTitle: 'Delete customer',
            deleteConfirmationContent: 'Deleting this customer will delete ALL associated data (templates, receivers, campaigns, statistics). Are you sure you want to delete this customer?',
          },
          customerLogoUpload: {
            uploadLogo: 'Upload logo',
            uploadLogoError: 'Could not upload logo',
            uploadLogoSuccessful: 'Logo updated successfully',
            noLogoYet: 'No logo uploaded yet',
            svgNotSupported: 'Your browser does not support svg.',
            deleteLogo: 'Delete logo'
          },
          customersList: {
            allCustomers: 'All customers',
          },
          domainEditor: {
            domainLabel: 'Domain',
            domainHelper: 'Please provide a valid domain!',
            typeLabel: 'Type',
            placeholderLabel: 'Placeholder',
            cancel: 'Cancel',
            save: 'Save',
          },
          errorPage: {
            defaultTitle: 'An error occured!',
            defaultMessage: 'Something went wrong',
            notFoundTitle: 'Not found',
            notFoundMessage: 'Could not find page!',
          },
          genderSelect: {
            genderLabel: 'Gender',
            male: 'Male',
            female: 'Female',
            divers: 'Not specified'
          },
          receiverCsvUpload: {
            createReceiversError: 'Could not add receivers',
            uploadEmailAlreadyExist: 'No receivers created because all emails already exist',
            uploadSuccessful: ' Receivers created successfully',
            unknownColumnError: 'Csv containing columns which could not be recognized.',
            degreeError: 'Wrong degree in line ',
            firstNameError: 'Wrong first name in line ',
            lastNameError: 'Wrong last name in line ',
            genderError: 'Wrong gender in line ',
            emailError: 'Wrong email address in line ',
            receiverGroupError: 'Wrong receiver groups in line ',
            columnError: ' and column ',
            linebreak: '\n',
            validCsvError: 'Please provide a valid csv',
            addReceivers: 'Add receivers via csv',
            filetypeError: 'Please select a csv file',
          },
          receiverEditor: {
            confirmDelete: 'Are you sure?',
            deleteError: 'Could not delete recipient in customer',
            firstNameLabel: 'First name',
            firstNameHelper: 'Please provide a valid first name!',
            lastNameLabel: 'Last name',
            lastNameHelper: 'Please provide a valid lastname',
            emailLabel: 'Email',
            emailHelper: 'Please provide a valid email!',
            delete: 'Delete',
            cancel: 'Cancel',
            save: 'Save',
            deleteConfirmationTitle: 'Delete recipient',
            deleteConfirmationContent: 'Deleting the recipient will delete all associated data. The recipient will no longer receive emails in the future. Are you sure you want to delete this recipient?',
          },
          receiverTable: {
            degree: 'Degree',
            firstName: 'First name',
            lastName: 'Last name',
            gender: 'Gender',
            emailAddress: 'Email address',
              receiverGroups: 'Departments',
            edit: 'Edit',
          },
          receiverGroupEditor: {
            confirmDelete: 'Are you sure?',
            deleteError: 'Could not delete receivergroup.',
            nameLabel: 'Name',
            nameHelper: 'Please provide a valid receiver group name!',
            delete: 'Delete',
            cancel: 'Cancel',
            save: 'Save',
          },
          receiverGroupList: {
            confirmDelete: 'Are you sure?',
            deleteError: 'Could not delete receivergroup.',
            allReceiverGroups: 'All receivergroups',
            noReceiverGroupsYet: 'No receivergroups yet.',
            addReceivergroup: 'Add receiverGroup',
            deleteConfirmationTitle: 'Delete receivergroup',
            deleteConfirmationContent: 'Deleting the receivergroup will remove it from all recipients. Are you sure you want to delete this receivergroup?',
          },
          dateDialog: {
            title: 'Date placeholder',
            text: 'Please specify number of days to be added(+) or subtracted(-) from today\'s date, e.g. 5 for five days from now or -8 for eight days before today.',
            label: 'Days to be added/subtracted',
            helper: 'Please provide a number',
            cancel: 'Cancel',
            addPlaceholder: 'Add placeholder',
          },
          monthDialog: {
            title: 'Month placeholder',
            text: 'Please specify number of months to be added(+) or subtracted(-) from today\'s date, e.g. 5 for five months from now or -8 for eight months before today.',
            label: 'Months to be added/subtracted',
            helper: 'Please provide a number',
            cancel: 'Cancel',
            addPlaceholder: 'Add placeholder',
          },
          hintDialog: {
            title: 'Add learning hint',
            label: 'Hint Id',
            cancel: 'Cancel',
            insert: 'Add placeholder',
          },
          emailPreview: {
            disclaimer: 'No worries, the email below is part of an authorized phishing simulation, with the aim to build awareness within your organization.<br/\> We invite you to hover over the highlighted parts below to learn, how this simulated phishing attack could have been spotted and how it could be prevented in the future.',
            from: 'From: ',
            to: 'To: [Your E-Mail address]',
            subject: 'Subject: ',
          },
          learningLinksDialog: {
            learningLinksTitle: 'Links to learning page',
            copyToClipboard: 'Copy to clipboard',
            copied: 'Copied', 
          },
          copyTemplateDialog: {
            selectTargetCustomer: 'Select Target Customer',
          },
          difficultySelect: {
            label: 'Difficulty',
            easy: 'Easy',
            medium: 'Medium',
            hard: 'Hard',
          },
          emailEditor: {
            salutationFormal: 'Salutation(formal)',
            salutationInformal: 'Salutation(informal)',
            firstname: 'First name',
            lastname: 'Last name',
            email: 'Email',
            subdomain: 'Subdomain',
            domain: 'Domain',
            company: 'Company',
            companyShort: 'Company short form',
            zipcode: 'Zipcode',
            city: 'City',
            street: 'Street + No.',
            phone: 'Phone',
            addAttachment: 'Add attachment',
            addLearningHint: 'Add learning hint',
            today: 'Today',
            todayx: 'Today +/- x',
            currentMonth: 'Current month',
            monthx: 'Current month +/- x',
            currentYear: 'Current year',
            bodyPlaceholder: 'Edit your content here!',
            togglePreview: 'Toggle preview',
          },
          hintManager: {
            labelStart: 'Hint ',
            labelTitleEnd: ' title',
            labelContentEnd: ' content',
            deleteHint: 'Delete hint',
            addHint: 'Add hint'
          },
          templateEditor: {
            confirmDelete: 'Are you sure?',
            deleteError: 'Could not deactivate template in customer.',
            placeholderNotFound1: 'Placeholder "',
            placeholderNotFound2: '" not found in the body.',
            placeholderMoreThanOnce1: 'Placeholder "',
            placeholderMoreThanOnce2: '" is contained more than once.',
            placeholderUndefinedHint: 'Body containing hint placeholders for undefined hints',
            nameLabel: 'Name',
            nameHelper: 'Please provide a valid template name!',
            subjectLabel: 'Subject',
            subjectHelper: 'Please provide a valid subject',
            senderAddressLabel : 'Sender address',
            senderAddressHelper: 'Please provide a valid sender address',
            replyToAddressLabel: 'ReplyTo address',
            replyToAddressHelper: 'Please provide a valid replyTo address',
            deactivate: 'Deactivate',
            cancel: 'Cancel',
            preview: 'Preview',
            save: 'Save',
          },
          templateTable: {
            name: 'Name',
            subject: 'Subject',
            senderAddress: 'Sender address',
            replyToAddress: 'ReplyTo Address',
            testmail: 'Testmail',
            send: 'Send',
            edit: 'Edit',
            preview: 'Preview',
            generateLearningLinks: 'Generate learninglinks',
            copyTemplatesError: 'Could not copy templates.',
            copyTemplates: 'Copy templates',
            testmailSentSuccessfully: 'Testmail sent successfully!',
            testmailSentError: 'Testmail could not be sent!',
              testmailConfirm: 'Do you want to send a testmail for this template to {{mailAddress}}?'
          },
          templateTagGroupSelect: {
            labelStart: 'Select ',
            labelEnd: ' Tags',
          },
          templateTagEditor: {
            nameLabel: 'Name',
            nameHelper: 'Please provide a valid template tag name!',
            typeLabel: 'Type',
            cancel: 'Cancel',
            save: 'Save',
          },
          templateTagList: {
            confirmDelete: 'Are you sure?',
            deleteError: 'Could not delete templatetag',
          },
          testmailConfigEditor: {
            firstNameLabel: 'First name',
            firstNameHelper: 'Please provide a valid first name!',
            lastNameLabel: 'Last name',
            lastNameHelper: 'Please provide a valid lastname',
            genderLabel: 'Gender',
            male: 'Male',
            female: 'Female',
            divers: 'Not specified',
            emailLabel: 'Email',
            emailHelper: 'Please provide a valid email!',
            cancel: 'Cancel',
            save: 'Save',
          },
          campaignManager: {
            newCampaign: 'New campaign',
          },
          customerManager: {
            newCustomer: 'New customer',
          },
          domainManager: {
            newDomain: 'New domain',
          },
          receiverManager: {
            newReceiver: 'New receiver',
          },
          templateManager: {
            newTemplate: 'New template',
          },
          templateTagManager: {
            newTemplateTag: 'New templatetag',
          },
          confirmationDialog: {
            cancel: 'Cancel',
            proceed: 'Proceed',
          },
            navigationSidebar: {
                campaigns: 'Campaigns',
                settings: 'Settings'
            },
            userEditor: {
                userUpdated: 'User information was successfully updated',
                accountInfo: 'Account information',
                personalInfo: 'Personal information',
                language: 'Language',
                emailAdress: 'E-Mail Adress',
                firstName: 'First Name',
                lastName: 'Last Name'
          }
        }
      },
      de: {
        translation: {
          analyticsOverview: {
            welcomeBackHeader: 'Willkommen zurück!',
            welcomeBackText: 'Nachfolgend finden Sie eine Übersicht über Ihre Kampagnen. Wählen Sie eine Kampagne, um eine Auswertung zu erhalten.',
            campaignDuration: 'Laufzeit: {{start}} bis {{end}}',
            mailsSent: 'Versandstatus: ',
            sent: 'Gesendet',
            pending: 'Ausstehend'
          },
          statCard: {
            clicked: 'Geklickt',
            notClicked: 'Nicht geklickt',
              reported: 'Gemeldet',
              notReported: 'Nicht gemeldet',
            criticalInteraction: 'Kritische \nInteraktion',
            nonCriticalInteraction: 'Nicht-kritische \nInteraktion',
            noInteraction: 'Keine Interaktion'
          },
            trendStatCard: {
              clickRateRunning: 'Klickrate {{startDate}} - heute',
              reportingRateRunning: 'Melderate {{startDate}} - heute',
              clickRateFinished: 'Klickrate {{startDate}} - {{endDate}}',
              reportingRateFinished: 'Melderate {{startDate}} - {{endDate}}'
            },
            templateCard: {
                showAll: "Alle anzeigen",
                showLess: "Weniger anzeigen"
            },
          campaignAnalyticsView: {
            status: 'Status: ',
            sent: 'Gesendet: ',
            opened: 'Geöffnet: ',
            clicked: 'Geklickt: ',
            receiverGroupStatistics: 'Empfängergruppen Statistik',
            templateStatistics: 'Template Statistik',
            monthlyStatistics: 'Monatliche Statistik',
            month: 'Monat',
            currentStatus: {
              inconfiguration: "In Bearbeitung",
              ready: "Bereit",
              running: "Läuft",
              paused: "Pausiert",
              finished: "Abgeschlossen",
              cancelled: "Abgebrochen"
            },
            runningUntil: 'Diese Kampagne läuft noch bis zum {{endDate}}.',
            campaignFinished: 'Diese Kampagne wurde am {{endDate}} abgeschlossen.',
              campaignInfo: 'Die Kampagne {{name}} läuft seit dem {{startDate}}. Es wurden bereits {{sent}} von {{total}} E-Mails versendet.',
              campaignInfoBaseline: 'die Kampagne {{name}} befindet sich in der Baseline-Phase bis {{baselineEndDate}}. Es wurden bereits {{sent}} von {{total}} E-Mails versendet.',
              campaignInfoFollowUp: 'die Kampagne {{name}} befindet sich seit dem {{baselineEndDate}} in der Hauptphase. Es wurden bereits {{sent}} von {{total}} E-Mails versendet.',
            campaignInfoFinished: 'diese Kampagne wurde am {{endDate}} beendet. Es wurden {{sent}} E-Mails versendet. {{clickrate}}% aller Empfänger haben auf einen Link in der E-Mail geklickt.',
              reportedCardTitle: 'Melderate (Gesamt)',
            sentState: 'Sendestatus',
            clickedCardTitle: 'Klickrate (Gesamt)',
            clickRate: 'Klickrate',
            interactedCardTitle: 'Interaktionsrate (Gesamt)',
            interactionRate: 'Interaktionsrate',
            interactionRateCritical: 'Interaktionsrate (Kritisch)',
            interactionRateNonCritical: 'Interaktionsrate (Nicht-kritisch)',
              reportingRate: 'Melderate',
              graphedAnalyticsCardTitle: 'Monatliche Auswertung',
              receiverGroup: 'Abteilung',
              baselineComparisonCardTitle: 'Trainingsfortschritt',
              greeting: "Hallo {{name}},"
          },
          explanations: {
            interactionrate: "Die Interaktionsrate zeigt den Prozentsatz der Benutzer, die mit einer gefälschten Anmeldeseite in der Phishing-Simulation interagiert haben. Kritische Interaktionen sind gefährliche Aktionen, wie das Eingeben von Passwörtern. Nicht-kritische Interaktionen sind weniger riskante Aktionen, wie das Klicken auf 'Passwort vergessen'.",
            graphCard: "Hier können Sie die Entwicklung der Klick- und Melderate im Laufe der Zeit auswerten. Fahren Sie mit der Maus über die Datenpunkte, um die Daten für den jeweiligen Monat anzuzeigen. Rechts sehen Sie einen Vergleich zwischen dem aktuellen und dem vorherigen Monat.",
            templateCard: "Eine Aufschlüsselung, wie Ihre Benutzer in Bezug auf jede spezifische E-Mail in der Kampagne abschneiden. Verwenden Sie die Schaltfläche oben rechts, um zwischen der Anzeige der Klick- und der Interaktionsrate zu wechseln.",
            baselineCard: "Zu Beginn einer Kampagne führen wir eine Baseline-Phase durch, um die Leistung Ihrer Benutzer zu bewerten. Hier können Sie sehen, wie sich Ihre Kennzahlen im Vergleich zu dieser initialen Baseline-Phase entwickelt haben.",
            clickrate: "Die Klickrate zeigt den Prozentsatz der Benutzer, die in einer simulierten Phishing-E-Mail auf einen Link geklickt haben.",
            reportingrate: "Die Melderate zeigt den Prozentsatz der Benutzer, die eine simulierte Phishing-E-Mail über den look2x Phishing-Melde-Button gemeldet haben.",
          },
          confirmDomainsDialog: {
            confirmDomainSelection: 'Domainauswahl bestätigen',
            areYouSure: 'Sind Sie sicher, dass Sie Domains aktualisieren möchten? Bitte überprüfen Sie Ihre Auswahl noch einmal.',
            pauseWarning: 'Warnung: Dies wird laufende Kampagnen pausieren! Bitte passen Sie die Whitelisting-Informationen an, bevor Sie die Kampagne wieder laufen lassen.',
            selectedSender: 'Ausgewählte Absenderdomains: ',
            selectedStandard: 'Ausgewählte Standarddomains: ',
            selectedMailFrom: 'Ausgewählte MailFromdomains: ',
            cancel: 'Abbrechen',
            continue: 'Weiter',
          },
          confirmReceiverGroupDialog: {
            confirmReceiverGroupSelection: 'Empfängergruppenauswahl bestätigen',
            areYouSure: 'Sind Sie sicher, dass sie die Empfängergruppen aktualisieren wollen? Diese Aktion wird neu hinzugefügte Empfänger zur Kampagne hinzufügen. Bitte bestätigen Sie die untenstehende Auswahl.',
            selectedReceiverGroups: 'Ausgewählte Empfängergruppen: ',
            cancel: 'Abbrechen',
            continue: 'Weiter',
          },
          confirmTemplatesDialog: {
            confirmTemplateSelection: 'Templateauswahl bestätigen',
            areYouSure: 'Sind Sie sicher, dass Sie die Templates aktualisieren möchten? Bitte überprüfen Sie Ihre Auswahl noch einmal.',
            cancel: 'Abbrechen',
            continue: 'Weiter',
            baselineTemplates: 'Baseline Templates',
            followUpTemplates: 'Follow-up Templates',
          },
          domainUpdate: {
            senderDomains: 'Absenderdomains',
            standardDomains: 'Standarddomains',
            mailFromDomains: 'MailFromdomains',
            updateDomains: 'Domains aktualisieren',
            updatedSuccessfully: 'Domains erfolreich aktualisiert!',
            updatedError: 'Domains konnten nicht aktualisiert werden! Bitte wählen Sie mindestens eine Absender-, Standard- und MailFromdomain aus.',
          },
          receiverGroupUpdate: {
            updateReceivers: 'Empfänger aktualisieren',
            updatedSuccessfully: 'Empfänger(-gruppen) erfolgreich aktualisiert.',
            updatedError: 'Empfänger(-gruppen) konnten nicht aktualisiert werden! Bitte wählen Sie mindestens eine Empfängergruppe.'
          },
          templateUpdate: {
            updateTemplates: 'Templates aktualisieren',
            updatedSuccessfully: 'Templates erfolgreich aktualisiert',
            updatedError: 'Templates konnten nicht aktualisiert werden! Bitte wählen Sie mindestens ein Template.',
            baselineTemplates: 'Baseline Templates',
            followUpTemplates: 'Follow-up Templates',
          },
          campaignDateInput: {
            startDate: 'Startdatum',
            endDate: 'Enddatum',
          },
          campaignEditor: {
            confirmDelete: 'Sind Sie sicher?',
            deleteError: 'Kampagne konnte nicht gelöscht werden.',
            templateError: 'Bitte wählen Sie mindestens ein Template aus. ',
            sendTimeError: 'Die Endzeit muss nach der Startzeit liegen! ',
            sendDayError: 'Bitte wählen Sie mindestens einen Versandtag! ',
            senderDomainError: 'Bitte wählen Sie mindestens eine Absenderdomain! ',
            standardDomainError: 'Bitte wählen Sie mindestens eine Standarddomain! ',
            mailFromDomainError: 'Bitte wählen Sie genau eine MailFromdomain! ',
            receiverGroupError: 'Bitte wählen Sie mindestens eine Empfängergruppe! ',
            nameLabel: 'Name',
            nameHelper: 'Bitte geben Sie einen gültigen Kampagnennamen an!',
            senderDomainLabel: 'Absenderdomains',
            standardDomainLabel: 'Standarddomains',
            mailFromDomainLabel: 'MailFromdomains',
            templateLabel: 'Templates',
            enableBaseline: 'Baseline aktivieren',
            baselineTemplates: 'Baseline Templates',
            followUpTemplates: 'Follow-up Templates',
            baselineDuration: 'Dauer Baseline (in Wochen)',
            delete: 'Löschen',
            cancel: 'Abbrechen',
            save: 'Speichern',
            saveAndReady: 'Speichern & als "Bereit" markieren',
            saveAndContinue: 'Speichern und Kampagne fortsetzen',
          },
          campaignReceiverGroupSelect: {
            receiverGroups: 'Empfängergruppen',
          },
          campaignSendDayInput: {
            monday: 'Montag',
            tuesday: 'Dienstag',
            wednesday: 'Mittwoch',
            thursday: 'Donnerstag',
            friday: 'Freitag',
            saturday: 'Samstag',
            sunday: 'Sonntag',
            senddays: 'Versandtage',
          },
          campaignTable: {
            updateCampaignError: 'Kampagnenstatus konnte nicht aktualisiert werden.',
            markAsReady: 'Als "Bereit" markieren',
            markAsInConfig: 'Als "In Bearbeitung" markieren',
            pauseCampaign: 'Kampagne pausieren',
            continueCampaign: 'Kampagne fortsetzen',
            cannotChangeFinished: 'Status einer beendeten Kampagne kann nicht geändert werden',
            cannotChangeCancelled: 'Status einer abgebrochenen Kampagne kann nicht geändert werden',
            receiverGroups: 'Empfängergruppen',
            templates: 'Templates',
            changeStatus: 'Status ändern',
            currentStatus: {
              inconfiguration: "In Bearbeitung",
              ready: "Bereit",
              running: "Läuft",
              paused: "Pausiert",
              finished: "Beendet",
              cancelled: "Abgebrochen"
            },
            testmailSentSuccessfully: 'Testmails wurden erfolgreich verschickt',
            testmailSentError: 'Testmails konnten nicht verschickt werden',
            learningLinks: 'Links generieren',
            status: 'Status',
            edit: 'Bearbeiten',
            dispatches: 'E-Mails',
            testmail: 'Testmail',
            send: 'Senden',
            viewDispatches: 'E-Mails anzeigen',
            whitelisting: 'Whitelisting',
            viewWhitelisting: 'Whitelisting anzeigen',
            changeStatusConfirmationTitle: 'Status ändern',
            changeStatusConfirmationContent: 'Ändern von "In Bearbeitung" auf "Bereit" startet die Kampagne, falls das Startdatum in der Vergangenheit liegt. Sind Sie sicher, dass Sie den Status dieser Kampagne ändern möchten?',
            testmailConfirmationTitle: 'Testmails senden',
            testmailConfirmationContent: 'Senden von Testmails wird ALLE der Kampagne zugeordneten Templates an den in "TestmailConfig" hinterlegten Empfänger schicken. Sind Sie sicher, dass Sie Testmails für diese Kampagne senden möchten?',
          },
          campaignTimeInput: {
            startTimeLabel: 'Start Versandzeit',
            endTimeLabel: 'Ende Versandzeit',
            timeZoneLabel: 'Zeitzone'
          },
          displayCampaign: {
            confirmCancel: 'Sind Sie sicher?',
            cancelError: 'Kampagne konnte nicht abgebrochen werden.',
            monday: 'Montag',
            tuesday: 'Dienstag',
            wednesday: 'Mittwoch',
            thursday: 'Donnerstag',
            friday: 'Freitag',
            saturday: 'Samstag',
            sunday: 'Sonntag',
            nameLabel: 'Name',
            sendDaysLabel: 'Versandtage',
            enableBaseline: 'Baseline ist aktiv',
            baselineEndDate: 'Baseline Enddatum',
            backToOverview: 'Zurück zum Kampagnenüberblick',
            cancelCampaign: 'Kampagne abbrechen',
            deleteCampaign: 'Kampagne löschen',
            deleteConfirmationTitle: 'Kampagne löschen',
            deleteConfirmationContent: 'Wenn Sie diese Kampagne löschen, werden ALLE zugehörigen Daten (versendete E-Mails, Statistiken) gelöscht. Sind Sie sicher, dass Sie diese Kampagne löschen möchten?',
            cancelConfirmationTitle: 'Kampagne abbrechen',
            cancelConfirmationContent: 'Wenn Sie diese Kampagne abbrechen, werden alle geplanten Versendungen gelöscht. Eine Fortsetzung der Kampagne ist dann nicht mehr möglich. Wenn Sie die Kampagne zu einem späteren Zeitpunkt fortsetzen möchten, pausieren Sie sie stattdessen. Sind Sie sicher, dass Sie diese Kampagne abbrechen möchten?',
          },
          customerEditor: {
            confirmCancel: 'Sind Sie sicher?',
            cancelError: 'Kunde konnte nicht gelöscht werden',
            shortNameLabel: 'Firmenname (Kurzform)',
            shortNameHelper: 'Bitte geben Sie die Kurzform Ihres Firmennamens an!',
            fullNameLabel: 'Firmenname',
            fullNameHelper: 'Bitte geben Sie einen gültigen Firmennamen an!',
            domainLabel: 'Domain',
            domainHelper: 'Bitte geben Sie eine gültige Domain an!',
            zipcodeLabel: 'Postleitzahl',
            zipcodeHelper: 'Bitte geben Sie eine gültige Postleitzahl an!',
            cityLabel: 'Stadt',
            cityHelper: 'Bitte geben Sie eine gültige Stadt an!',
            streetLabel: 'Straße',
            streetHelper: 'Bitte geben Sie eine gültige Straße und Hausnummer an!',
            phoneLabel: 'Telefon',
            phoneHelper: 'Bitte geben Sie eine gültige Telefonnummer an!',
            primaryColorLabel: 'Primäre Farbe',
            primaryColorHelper: 'Bitte geben Sie ein gültige Farbe an (hex oder rgb)',
            secondaryColorLabel: 'Sekundäre Farbe',
            secondaryColorHelper: 'Bitte geben Sie ein gültige Farbe an (hex oder rgb)',
            enableBranding: 'Branding aktivieren',
            enableReportButton: 'Report Button aktivieren',
            helpdeskEmailLabel: 'Helpdesk E-Mail',
            helpdeskEmailHelper: 'Bitte geben Sie eine gültige E-Mail an!',
            msTenantIdLabel: 'MS365 Tenant ID',
            msTenantIdHelper: 'Bitte geben Sie eine gültige MS365 Tenant ID an!',
            enableBase64: 'Bilder als Base64 verschicken',
            demoCustomer: 'Demo',
            delete: 'Löschen',
            cancel: 'Abbrechen',
            save: 'Speichern',
            deleteConfirmationTitle: 'Kunden löschen',
            deleteConfirmationContent: 'Wenn Sie diesen Kunden löschen, werden ALLE zugehörigen Daten (Templates, Empfänger, Kampagnen, Statistiken) gelöscht. Sind Sie sicher, dass Sie diesen Kunden löschen möchten?',
          },
          customerLogoUpload: {
            uploadLogo: 'Logo hochladen',
            uploadLogoError: 'Logo konnte nicht hochgeladen werden',
            uploadLogoSuccessful: 'Logo erfolgreich hochgeladen',
            noLogoYet: 'Noch kein Logo hochgeladen',
            svgNotSupported: 'Dein Browser unterstützt kein svg.',
            deleteLogo: 'Logo löschen',
          },
          customersList: {
            allCustomers: 'Alle Kunden',
          },
          domainEditor: {
            domainLabel: 'Domain',
            domainHelper: 'Bitte geben Sie eine gültige Domain an!',
            typeLabel: 'Typ',
            landingPageLabel: 'Placeholder',
            cancel: 'Abbrechen',
            save: 'Speichern',
          },
          errorPage: {
            defaultTitle: 'Es ist ein Fehler aufgetreten!',
            defaultMessage: 'Etwas ist schief gelaufen',
            notFoundTitle: 'Nicht gefunden',
            notFoundMessage: 'Die Seite konnte nicht gefunden werden!',
          },
          genderSelect: {
            genderLabel: 'Geschlecht',
            male: 'männlich',
            female: 'weiblich',
            divers: 'Keine Angabe'
          },
          receiverCsvUpload: {
            createReceiversError: 'Empfänger konnten nicht hinzugefügt werden.',
            uploadEmailAlreadyExist: 'Es wurden keine Empfänger erstellt, da alle E-Mails bereits vorhanden sind',
            uploadSuccessful: ' Empfänger erfolgreich erstellt',
            unknownColumnError: 'Csv enthält Spalten, die nicht erkannt werden konnten.',
            degreeError: 'Falscher Titel in Zeile ',
            firstNameError: 'Falscher Vorname in Zeile ',
            lastNameError: 'Falscher Nachname in Zeile ',
            genderError: 'Falsches Geschlecht in der Zeile ',
            emailError: 'Falsche E-Mail Adresse in Zeile ',
            receiverGroupError: 'Falsche Empfängergruppen in Zeile ',
            columnError: ' und Spalte ',
            linebreak: '\n',
            validCsvError: 'Bitte geben Sie eine gültige csv-Datei an',
            addReceivers: 'Empfänger über csv hinzufügen',
            filetypeError: 'Bitte wählen Sie eine csv-Datei',
          },
          receiverEditor: {
            confirmDelete: 'Sind Sie sicher?',
            deleteError: 'Konnte Empfänger nicht löschen',
            firstNameLabel: 'Vorname',
            firstNameHelper: 'Bitte geben Sie einen gültigen Vornamen an!',
            lastNameLabel: 'Nachname',
            lastNameHelper: 'Bitte geben Sie einen gültigen Nachnamen an',
            emailLabel: 'E-Mail',
            emailHelper: 'Bitte geben Sie eine gültige E-Mail an!',
            delete: 'Löschen',
            cancel: 'Abbrechen',
            save: 'Speichern',
            deleteConfirmationTitle: 'Empfänger löschen',
            deleteConfirmationContent: 'Löschen des Empfängers wird alle zugehörigen Daten löschen. Der Empfänger wird zukünftig keine E-Mails mehr erhalten. Sind Sie sicher, dass Sie diesen Empfänger löschen möchten?',
          },
          receiverTable: {
            degree: 'Titel',
            firstName: 'Vorname',
            lastName: 'Nachname',
            gender: 'Geschlecht',
            emailAddress: 'E-mail Adresse',
              receiverGroups: 'Abteilungen',
            edit: 'Bearbeiten',
          },
          receiverGroupEditor: {
            confirmDelete: 'Sind Sie sicher?',
            deleteError: 'Konnte Empfängergruppe nicht löschen.',
            nameLabel: 'Name',
            nameHelper: 'Bitte geben Sie einen gültigen Empfängergruppennamen an!',
            delete: 'Löschen',
            cancel: 'Abbrechen',
            save: 'Speichern',
          },
          receiverGroupList: {
            confirmDelete: 'Sind Sie sicher?',
            deleteError: 'Konnte Empfängergruppe nicht löschen.',
            allReceiverGroups: 'Alle Empfängergruppen',
            noReceiverGroupsYet: 'Noch keine Empfängergruppen.',
            addReceiverGroup: 'Empfängergruppe hinzufügen',
            deleteConfirmationTitle: 'Empfängergruppe löschen',
            deleteConfirmationContent: 'Löschen der Empfängergruppe wird sie von allen Empfängern entfernen. Sind Sie sicher, dass Sie diese Empfängergruppe löschen möchten?',
          },
          dateDialog: {
            title: 'Datumsplatzhalter',
            text: 'Bitte geben Sie die Anzahl der Monate an, die zum heutigen Datum addiert (+) oder davon subtrahiert (-) werden sollen, z. B. 5 für fünf Monate ab heute oder -8 für acht Monate vor heute.',
            label: 'Zu addierende/subtrahierende Monate',
            helper: 'Bitte geben Sie eine Zahl ein',
            cancel: 'Abbrechen',
            addPlaceholder: 'Platzhalter hinzufügen',
          },
          monthDialog: {
            title: 'Monatsplatzhalter',
            text: 'Please specify number of months to be added(+) or subtracted(-) from today\'s date, e.g. 5 for five months from now or -8 for eight months before today.',
            label: 'Months to be added/subtracted',
            helper: 'Please provide a number',
            cancel: 'Cancel',
            addPlaceholder: 'Add placeholder',
          },
          hintDialog: {
            title: 'Lernhinweis hinzufügen',
            label: 'Hinweis Id',
            cancel: 'Abbrechen',
            insert: 'Platzhalter hinzufügen',
          },
          emailPreview: {
            disclaimer: 'Keine Sorge, die unten stehende E-Mail ist Teil einer autorisierten Phishing-Simulation, die darauf abzielt, das Bewusstsein in Ihrem Unternehmen zu schärfen.<br/\> Wir laden Sie ein, mit dem Mauszeiger über die hervorgehobenen Teile unten zu fahren, um zu erfahren, wie dieser simulierte Phishing-Angriff hätte entdeckt werden können und wie er in Zukunft verhindert werden kann.',
            from: 'From: ',
            to: 'To: [Ihre E-Mail Adresse]',
            subject: 'Betreff: ',
          },
          learningLinksDialog: {
            learningLinksTitle: 'Links zur Lernseite',
            copyToClipboard: 'In Zwischenablage kopieren',
            copied: 'Kopiert', 
          },
          copyTemplateDialog: {
            selectTargetCustomer: 'Zielkunde auswählen',
          },
          difficultySelect: {
            label: 'Schwierigkeit',
            easy: 'Einfach',
            medium: 'Mittel',
            hard: 'Schwer',
          },
          emailEditor: {
            salutationFormal: 'Anrede(formell)',
            salutationInformal: 'Anrede(informell)',
            firstname: 'Vorname',
            lastname: 'Nachname',
            email: 'E-Mail',
            subdomain: 'Subdomain',
            domain: 'Domain',
            company: 'Firmenname',
            companyShort: 'Kurzform Firmenname',
            zipcode: 'Postleitzahl',
            city: 'Stadt',
            street: 'Straße + Nr.',
            phone: 'Telefon',
            addAttachment: 'Anhang hinzufügen',
            addLearningHint: 'Lernhinweis hinzufügen',
            today: 'Heute',
            todayx: 'Heute +/- x',
            currentMonth: 'Aktueller Monat',
            monthx: 'Aktueller Monat +/- x',
            currentYear: 'Aktuelles Jahr',
            bodyPlaceholder: 'Bearbeiten Sie Ihren Inhalt hier!',
            togglePreview: 'Vorschau umschalten',
          },
          hintManager: {
            labelStart: 'Hinweis ',
            labelTitleEnd: ' Titel',
            labelContentEnd: ' Inhalt',
            deleteHint: 'Hinweis löschen',
            addHint: 'Hinweis hinzufügen'
          },
          templateEditor: {
            confirmDelete: 'Sind Sie sicher?',
            deleteError: 'Das template konnte nicht deaktiviert werden.',
            placeholderNotFound1: 'Platzhalter "',
            placeholderNotFound2: '" nicht im E-mail-Body gefunden.',
            placeholderMoreThanOnce1: 'Platzhalter "',
            placeholderMoreThanOnce2: '" ist mehr als einmal im E-Mail-Body enthalten.',
            placeholderUndefinedHint: 'E-MailBody enthält Hinweisplatzhalter für undefinierte Hinweise.',
            nameLabel: 'Name',
            nameHelper: 'Bitte geben Sie einen gültigen Templatenamen an!',
            subjectLabel: 'Betreff',
            subjectHelper: 'Bitte geben Sie einen gültigen Betreff an',
            senderAddressLabel : 'Absenderadresse',
            senderAddressHelper: 'Bitte geben Sie eine gültige Absenderadresse an',
            replyToAddressLabel: 'ReplyTo-Adresse',
            replyToAddressHelper: 'Bitte geben Sie eine gültige ReplyTo-Adresse an',
            deactivate: 'Deaktivieren',
            cancel: 'Abbrechen',
            preview: 'Vorschau',
            save: 'Speichern',
          },
          templateTable: {
            name: 'Name',
            subject: 'Betreff',
            senderAddress: 'Absenderadresse',
            replyToAddress: 'ReplyTo-Adresse',
            testmail: 'Testmail',
            send: 'Senden',
            edit: 'Bearbeiten',
            preview: 'Vorschau',
            generateLearningLinks: 'Lernlinks generieren',
            copyTemplatesError: 'Templates konnten nicht kopiert werden.',
            copyTemplates: 'Templates kopieren',
            testmailSentSuccessfully: 'Testmail erfolgreich gesendet!',
            testmailSentError: 'Testmail konnte nicht gesendet werden!',
              testmailConfirm: 'Möchtest du eine Testmail für dieses Template an {{mailAddress}} senden?'
          },
          templateTagGroupSelect: {
            labelStart: '',
            labelEnd: ' Tags auswählen',
          },
          templateTagEditor: {
            nameLabel: 'Name',
            nameHelper: 'Bitte geben Sie einen gültigen Namen an!',
            typeLabel: 'Typ',
            cancel: 'Abbrechen',
            save: 'Speichern',
          },
          templateTagList: {
            confirmDelete: 'Sind Sie sicher?',
            deleteError: 'Templatetag konte nicht gelöscht werden.',
          },
          testmailConfigEditor: {
            firstNameLabel: 'Vorname',
            firstNameHelper: 'Bitte geben Sie einen gültigen Vornamen an!',
            lastNameLabel: 'Nachname',
            lastNameHelper: 'Bitte geben Sie einen gültigen Nachnamen an',
            genderLabel: 'Geschlecht',
            male: 'Männlich',
            weiblich: 'Weiblich',
            divers: 'Keine Angabe',
            emailLabel: 'E-Mail',
            emailHelper: 'Bitte geben Sie eine gültige E-Mail an!',
            cancel: 'Abbrechen',
            save: 'Speichern',
          },
          campaignManager: {
            newCampaign: 'Neue Kampagne',
          },
          customerManager: {
            newCustomer: 'Neuer Kunde',
          },
          domainManager: {
            newDomain: 'Neue Domain',
          },
          receiverManager: {
            newReceiver: 'Neuer Empfänger',
          },
          templateManager: {
            newTemplate: 'Neues Template',
          },
          templateTagManager: {
            newTemplateTag: 'Neues Templatetag',
          },
          confirmationDialog: {
            cancel: 'Abbrechen',
            proceed: 'Fortfahren',
          },
            navigationSidebar: {
                campaigns: 'Kampagnen',
                settings: 'Einstellungen'
            },
            userEditor: {
                userUpdated: 'Die Benutzerinformationen wurden erfolgreich aktualisiert',
                accountInfo: 'Accountinformationen',
                personalInfo: 'Persönliche Informationen',
                language: 'Sprache',
                emailAdress: 'E-Mail Addresse',
                firstName: 'Vorname',
                lastName: 'Nachname'

          }
        }
      }
    }
  });

export default i18n;