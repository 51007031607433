import {ReceiverGroup} from "../model/ReceiverGroup";
import {fetchWithToken} from "./fetchWithToken";

const getBaseUrl = (customerId: string) => {
    let url;
    switch (process.env.NODE_ENV) {
        case 'production':
            url = `/api/customers/${customerId}/receiver_groups`;
            break;
        case 'development':
            url = `http://localhost:5000/api/customers/${customerId}/receiver_groups`;
            break;
        default:
            url = `/api/customers/${customerId}/receiver_groups`;
    }

    return url;
}

export async function getReceiverGroup(receiver_group_id: string, customerId: string) {
    try {
        const response = await fetchWithToken(getBaseUrl(customerId) + `/${receiver_group_id}`, {
            method: "GET",
        })
        return response
    } catch (error) {
        throw new Error("Error")
    }
}

export async function getCustomerReceiverGroups(customerId: string) {
    try {
        const response = await fetchWithToken(getBaseUrl(customerId), {
            method: "GET"
        })
        return response
    } catch (error) {
        throw new Error("Error while loading receiver groups")
    }
}

export async function deleteReceiverGroup(receiver_group_id: string, customerId: string) {
    try {
        const response = await fetchWithToken(getBaseUrl(customerId) + `/${receiver_group_id}`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
            },
        })
        return response
    } catch (error) {
        throw new Error("Error")
    }
}

export async function createReceiverGroup(receiver_group: ReceiverGroup, customerId: string) {
    try {
        const response = await fetchWithToken(getBaseUrl(customerId), {
            method: "POST",
            body: JSON.stringify(receiver_group),
            headers: {
                "Content-Type": "application/json",
            },
        })
        return response
    } catch (error) {
        throw new Error("Error")
    }
}

export async function editReceiverGroup(receiver_group: ReceiverGroup, receiver_group_id: string, customerId: string) {
    try {
        const response = await fetchWithToken(getBaseUrl(customerId) + `/${receiver_group_id}`, {
            method: "PUT",
            body: JSON.stringify(receiver_group),
            headers: {
                "Content-Type": "application/json",
            },
        })
        return response
    } catch (error) {
        throw new Error("Error")
    }
}