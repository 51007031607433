import React, {Suspense} from "react";
import {Await, defer, json, useLoaderData} from "react-router-dom";
import {getCampaignDispatches} from "../../api/dispatchClient";
import DispatchTable from "../../components/Dispatch/DispatchTable";
import {Dispatch} from "../../model/Dispatch";
import {CircularProgress} from "@mui/material";

const DispatchManagerPage: React.FC = (props) => {
    const {dispatches} = useLoaderData() as { dispatches: Dispatch[] };
    return (
        <Suspense fallback={<CircularProgress/>}>
            <Await resolve={dispatches}>
                {loadedDispatches => <DispatchTable dispatches={loadedDispatches}/>}
            </Await>
            </Suspense>
    );
}

export default DispatchManagerPage;

async function loadCampaignDispatches(id: string) {
    const response = await getCampaignDispatches(id);

    if (!response.ok) {
        throw json({ message: 'Could not fetch campaign dispatches.' }, { status: 500 });
    }
    else {
        const resData = await response.json();
        return resData
    }
}

export async function loader({request, params}: { request: Request, params: { campaignId: string } }) {
    const id = params.campaignId;

    return defer({
        dispatches: loadCampaignDispatches(id)
    })
}