import {Box, Button, CircularProgress} from "@mui/material";
import React, {Suspense} from "react";
import {Await, defer, json, useLoaderData} from "react-router";
import {Link} from "react-router-dom";
import {TemplateTag, TemplateTagGroup} from "../../model/TemplateTag";
import classes from "../../styling/pages/Manager.module.css"
import {listTemplateTags} from "../../api/templateTagClient";
import TemplateTagList from "../../components/TemplateTag/TemplateTagList";
import {useTranslation} from "react-i18next";


const TemplateTagManagerPage: React.FC = (props) => {
    const { t } = useTranslation();
    const {templateTagGroups} = useLoaderData() as { templateTagGroups: TemplateTagGroup[] };

    return (
        <><Box className={classes.buttonContainer}>
            <Link to={"new"}>
                <Button variant="contained">
                    {t('templateTagManager.newTemplateTag')}
                </Button>
            </Link>
        </Box>
            <Suspense fallback={<CircularProgress/>}>
                <Await resolve={templateTagGroups}>
                    {loadedTemplateTagGroups => loadedTemplateTagGroups.map((group: TemplateTagGroup) =>
                        <TemplateTagList key={group.type} templateTagGroup={group} />
                    )}
                </Await>
            </Suspense>
        </>
    );

}

export default TemplateTagManagerPage;

export async function loadTemplateTagGroups() {
    const response = await listTemplateTags();
    if (!response.ok) {
        throw json({ message: 'Could not fetch templateTags.' }, { status: response.status });
    } else {
        const resData: TemplateTag[] = await response.json();
        const templateTagGroups: TemplateTagGroup[] = resData.reduce((tagGroups, tag) => {
            // Find existing group for the tag type
            const existingGroup = tagGroups.find(group => group.type === tag.type);

            // If a group exists, add the tag to the existing group, otherwise create a new group
            if (existingGroup) {
                existingGroup.tags.push(tag);
            } else {
                tagGroups.push({ type: tag.type, tags: [tag] });
            }

            return tagGroups;
        }, [] as TemplateTagGroup[]);
        return templateTagGroups;
    }
}

export async function loader({ request, params }: { request: Request, params: { templateId: string } }) {

    return defer({
        templateTagGroups: loadTemplateTagGroups(),
    })
}