import React from "react";
import {CampaignAnalyticsSummary} from "../../model/CampaignAnalyticsSummary";
import {HeaderCard} from "../Base/HeaderCard";
import {CampaignOverviewCard} from "./Base/CampaignOverviewCard";
import {NavigationHeader} from "../Navigation/NavigationHeader";
import {useTranslation} from "react-i18next";

interface Props {
    campaigns: CampaignAnalyticsSummary[]
}

export const AnalyticsOverview: React.FC<Props> = (props) => {
    const {campaigns} = props;

    const {t} = useTranslation()

    const navigation = [
        {url: "", label: "Kampagnen"}
    ]

    //Sort order: Running > Paused > Finished. Campaigns with same status are sorted based on start date (descending)
    const campaignsSorted = campaigns.sort((a, b) => {
        if (a.status !== b.status) {
            if (a.status === "Running")
                return -1
            if (b.status === "Running")
                return 1
            if (a.status === "Paused")
                return -1
            if (b.status === "Paused")
                return 1
            else return 0 //Should not be possible to enter this case
        } else {
            if (a.startDate < b.startDate)
                return 1
            else if (a.startDate > b.startDate)
                return -1
            else return 0
        }
    })

    return (
        <>
            <NavigationHeader path={navigation}/>
            <HeaderCard headline={<>{t('analyticsOverview.welcomeBackHeader')}</>}
                        body={<>{t('analyticsOverview.welcomeBackText')}</>}/>
            {campaignsSorted.map(campaign => <CampaignOverviewCard campaign={campaign}/>)}
        </>
    )
}

