import {Auth} from "aws-amplify";

export async function fetchWithToken(input: Request | string | URL, init?: RequestInit | undefined): Promise<Response> {

    const authorizationHeader = await getAuthHeader();
    let initParams = init ?? {headers: undefined}
    initParams.headers = Object.assign({}, initParams.headers, {authorization: authorizationHeader})

    return fetch(input, initParams)
}

export async function getAuthHeader() {
    const token = (await Auth.currentSession()).getAccessToken().getJwtToken()
    if (!token)
        throw new Error('No authentication token found while trying to fetch resource.')
    return 'Bearer ' + token;
}

