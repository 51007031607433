import {fetchWithToken} from "./fetchWithToken";

const getBaseUrl = (customerId: string) => {
    let url;
    switch (process.env.NODE_ENV) {
        case 'production':
            url = `/api/customers/${customerId}/branding`;
            break;
        case 'development':
            url = `http://localhost:5000/api/customers/${customerId}/branding`;
            break;
        default:
            url = `/api/customers/${customerId}/campaigns`;
    }

    return url;
}

export async function createCustomerLogo(customerId: string, formData: FormData) {
    try {
        const response = await fetchWithToken(getBaseUrl(customerId), {
            method: "POST",
            body: formData,
        })
        return response
    } catch (error) {
        throw new Error("Error")
    }
}

export async function getCustomerLogo(customerId: string) {
    try {
        const response = await fetchWithToken(getBaseUrl(customerId), {
            method: "GET",
        })
        return response
    } catch (error) {
        throw new Error("Error")
    }
}

export async function deleteCustomerLogo(customerId: string) {
    try {
        const response = await fetchWithToken(getBaseUrl(customerId), {
            method: "DELETE",
        })
        return response
    } catch (error) {
        throw new Error("Error")
    }
}