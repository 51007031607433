import {Box, Button, CircularProgress} from "@mui/material";
import React, {Suspense} from "react";
import {Await, defer, json, useLoaderData} from "react-router";
import {Link} from "react-router-dom";
import {Domain, DomainGroup} from "../../model/Domain";
import classes from "../../styling/pages/Manager.module.css"
import DomainList from "../../components/Domain/DomainList";
import {listDomains} from "../../api/domainClient";
import {useTranslation} from "react-i18next";


const DomainManagerPage: React.FC = (props) => {
    const { t } = useTranslation();
    const {domainGroups} = useLoaderData() as { domainGroups: DomainGroup[] };

    return (
        <><Box className={classes.buttonContainer}>
            <Link to={"new"}>
                <Button variant="contained">
                    {t('domainManager.newDomain')}
                </Button>
            </Link>
        </Box>
            <Suspense fallback={<CircularProgress/>}>
                <Await resolve={domainGroups}>
                    {loadedDomainGroups => loadedDomainGroups.map((group: DomainGroup) =>
                        <DomainList key={group.type} domainGroup={group} />
                    )}
                </Await>
            </Suspense>
        </>
    );

}

export default DomainManagerPage;

export async function loadDomainGroups(customerId: string) {
    const response = await listDomains(customerId);
    if (!response.ok) {
        throw json({message: 'Could not fetch Domains.'}, {status: response.status});
    } else {
        const resData: Domain[] = await response.json();
        const DomainGroups: DomainGroup[] = resData.reduce((domainGroups, domain) => {
            // Find existing group for the tag type
            const existingGroup = domainGroups.find(group => group.type === domain.type);
            // If a group exists, add the tag to the existing group, otherwise create a new group
            if (existingGroup) {
                existingGroup.domains.push(domain);
            } else {
                domainGroups.push({ type: domain.type, domains: [domain] });
            }

            return domainGroups;
        }, [] as DomainGroup[]);
        return DomainGroups;
    }
}

export async function loader({request, params}: { request: Request, params: { customerId: string } }) {
    const customerId = params.customerId;
    return defer({
        domainGroups: loadDomainGroups(customerId),
    })
}