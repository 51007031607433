import * as React from "react";
import {NavLink} from "react-router-dom";
import classes from '../../styling/components/NavigationHeader.module.css'
import {Breadcrumbs} from "@mui/material";


type Props = {
    path: { label: string, url: string }[]
}
export const NavigationHeader: React.FC<Props> = (props) => {
    const {path} = props

    return (
        <div className={classes.navigationHeader}>
            <Breadcrumbs>
                <NavLink to={"/"}>Start</NavLink>
                {path.map(({label, url}, index) => {
                    return (
                        (index === path.length - 1) ? <span>{label}</span> :
                            <NavLink to={url}>{label}</NavLink>
                    )
                })}
            </Breadcrumbs>
            <h1>{path[path.length - 1].label}</h1>
        </div>
    )
}