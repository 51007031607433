import React, {Suspense} from "react";
import {Await, defer, json, useLoaderData} from "react-router-dom";
import {getTemplate} from "../../api/templateClient";
import TemplateEditor from "../../components/Template/TemplateEditor";
import {Template} from "../../model/Template";
import {getCustomerTemplateTags} from "../../api/templateTagClient";
import {TemplateTag} from "../../model/TemplateTag";
import {CircularProgress} from "@mui/material";

const EditTemplatePage: React.FC = (props) => {
    const {template, templateTags} = useLoaderData() as { template: Template, templateTags: TemplateTag[] };

    return (
        <Suspense fallback={<CircularProgress/>}>
            <Await resolve={Promise.all([template, templateTags])}>
                {data => <TemplateEditor method="PUT" template={data[0]} templateTags={data[1]}/>}
            </Await>
        </Suspense>
    )
}

export default EditTemplatePage;

export async function loadTemplate(templateId: string, customerId: string) {
    const response = await getTemplate(templateId, customerId);

    if (!response.ok) {
        throw json({message: 'Could not fetch template.'}, {status: 500});
    } else {
        const resData = await response.json();
        return resData
    }
}

export async function loadTemplateTags(customerId: string) {
    const response = await getCustomerTemplateTags(customerId);
    if (!response.ok) {
        throw json({message: 'Could not fetch templateTags.'}, {status: response.status});
    } else {
        const resData = await response.json();
        return resData
    }
}

export async function loader({
                                 request,
                                 params
                             }: { request: Request, params: { templateId: string, customerId: string } }) {
    const templateId = params.templateId;
    const customerId = params.customerId;

    return defer({
        template: loadTemplate(templateId, customerId),
        templateTags: loadTemplateTags(customerId),
    })
}