import {Outlet, useParams} from "react-router-dom";
import CustomerNavigation from "./CustomerNavigation";
import React, {useEffect, useState} from "react";
import classes from '../../styling/components/CustomerLayout.module.css'
import {checkCustomerDemoFlag} from "../../api/customerClient";
import {CircularProgress} from "@mui/material";

const CustomerLayout: React.FC = (props) => {

    const {customerId} = useParams();

    const [demo, setDemo] = useState(false)
    const [loading, setLoading] = useState(true)

    console.log("State: ", demo)

    useEffect(() => {
        if (customerId)
            checkCustomerDemoFlag(customerId)
                .then(res => {
                    if (res.ok)
                        return res.text()
                })
                .then(val => {
                    setDemo(val === 'true')
                    setLoading(false)
                })

    }, [customerId, setDemo, setLoading]);

    return (
        loading ? <CircularProgress/> :
            <>
                <div className={classes.appbar}>
                    <CustomerNavigation demo={demo}/>
                </div>
                <div className={classes.main}>
                    <div className={classes.content}>
                        <Outlet/>
                    </div>

                </div>

            </>
    )

}

export default CustomerLayout;
