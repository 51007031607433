import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import { Domain } from '../../../model/Domain';
import { DialogActions, DialogTitle } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface ConfirmDomainsDialogProps {
    open: boolean;
    onClose: () => void;
    onContinue: () => void;
    selectedDomains: {
        senderDomains: Domain[];
        standardDomains: Domain[];
        mailFromDomains: Domain[];
    };
}

const ConfirmDomainsDialog: React.FC<ConfirmDomainsDialogProps> = ({
    open,
    onClose,
    onContinue,
    selectedDomains,
}) => {
    const { t } = useTranslation();
    const { senderDomains, standardDomains, mailFromDomains } = selectedDomains;

    // Extract the 'domain' property from each Domain object
    const senderDomainNames = senderDomains.map((domain) => domain.domain);
    const standardDomainNames = standardDomains.map((domain) => domain.domain);
    const mailFromDomainNames = mailFromDomains.map((domain) => domain.domain);

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{t('confirmDomainsDialog.confirmDomainSelection')}</DialogTitle>
            <DialogContent>
                <p>{t('confirmDomainsDialog.areYouSure')}</p>
                <p><b>{t('confirmDomainsDialog.pauseWarning')}</b></p>
                <p>{t('confirmDomainsDialog.selectedSender')} {senderDomainNames.join(', ')}</p>
                <p>{t('confirmDomainsDialog.selectedStandard')} {standardDomainNames.join(', ')}</p>
                <p>{t('confirmDomainsDialog.selectedMailFrom')} {mailFromDomainNames.join(', ')}</p>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    {t('confirmDomainsDialog.cancel')}
                </Button>
                <Button onClick={onContinue} color="primary">
                    {t('confirmDomainsDialog.continue')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmDomainsDialog;