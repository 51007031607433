import React from 'react';
import {TimeField, LocalizationProvider, TimePicker} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { Campaign } from '../../model/Campaign';
import { MenuItem, Select } from '@mui/material';
import {aryIanaTimeZones} from "../../model/Timezones";
import { useTranslation } from 'react-i18next';


interface CampaignTimeInputProps {
  campaign?: Campaign;
  dayStartTime: Dayjs;
  dayEndTime: Dayjs;
  onTimeChange: (field: string, newValue: Dayjs) => void;
}

const CampaignTimeInput: React.FC<CampaignTimeInputProps> = (props) => {
  const { t } = useTranslation();
  const { dayStartTime, dayEndTime, onTimeChange } = props;
  // const [startSendTime, setStartSendTime] = React.useState<Dayjs | null>(dayjs(props.campaign ? `2000-01-01T${props.campaign.dayStartTime}` : '2000-01-01T09:00'));

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div>
        <TimeField
          format="HH:mm"
          label={t('campaignTimeInput.startTimeLabel')}
          id="dayStartTime"
          name="dayStartTime"
          margin="normal"
          required
          value={dayStartTime}
          onChange={(newValue) => onTimeChange('dayStartTime', newValue!)}
        />
        <TimeField
          format="HH:mm"
          label={t('campaignTimeInput.endTimeLabel')}
          id="dayEndTime"
          name="dayEndTime"
          margin="normal"
          required
          value={dayEndTime}
          onChange={(newValue) => onTimeChange('dayEndTime', newValue!)}
          minTime={dayjs(dayStartTime).add(1, 'minute')}
        />
        <Select id="timezone" label={t('campaignTimeInput.timeZoneLabel')} name="timezone" type="text"
                            defaultValue={props.campaign?.timezone ?? Intl.DateTimeFormat().resolvedOptions().timeZone}>
                        {aryIanaTimeZones.map(zone => <MenuItem key={zone} value={zone}>{zone}</MenuItem>)}
                    </Select>
      </div>
    </LocalizationProvider>
  );
};

export default CampaignTimeInput;
