import React from "react";
import HelpIcon from '@mui/icons-material/Help';
import {Tooltip} from "@mui/material";

interface Props {
    content: string | React.ReactElement
}

export const ExplanationTooltip: React.FC<Props> = (props) => {


    return (
        <>
            <Tooltip placement={"right"}
                     title={props.content}
            >
                <HelpIcon fontSize={"inherit"} style={{marginLeft: "5px", verticalAlign: "middle"}} color={"primary"}/>
            </Tooltip>

        </>
    )
}