import React from "react";
import {TimespanAnalytics} from "../../../model/Analytics";
import {Card, CardContent, CardHeader, Grid} from "@mui/material";
import {useTranslation} from "react-i18next";
import classes from '../../../styling/components/analytics/GraphedAnalyticsCard.module.css';
import {ClickRateGraph} from "./ClickRateGraph";
import {TrendStatCardPanel} from "./TrendStatCardPanel";
import {ExplanationTooltip} from "./ExplanationTooltip";


interface Props {
    timespanStats: TimespanAnalytics[]
    reportingEnabled: boolean
}

export const GraphedAnalyticsCard: React.FC<Props> = (props) => {
    const {timespanStats, reportingEnabled} = props

    const {t} = useTranslation()

    const cardHeader = <>
        {t('campaignAnalyticsView.graphedAnalyticsCardTitle')}
        <ExplanationTooltip content={t('explanations.graphCard')}/>
    </>

    const currentTimespanIndex = timespanStats.findIndex((timespan, index) => (new Date(timespan.startDate) < new Date() && new Date() < new Date(timespan.endDate)) || index === timespanStats.length - 1)

    return (
        timespanStats.length <= 1 ? null :
        <Card className={classes.graphedAnalyticsCard}>
            <CardHeader
                title={cardHeader}
            />
            <CardContent>
                <Grid container columnSpacing={5} alignItems={"center"}>
                    <Grid item xs={12} md={9}>
                        <div>
                            <ClickRateGraph timespanStats={timespanStats} reportingEnabled={reportingEnabled}/>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TrendStatCardPanel currentTimespan={timespanStats[currentTimespanIndex]}
                                            previousTimespan={timespanStats[currentTimespanIndex - 1]}
                                            reportingEnabled={reportingEnabled}
                        />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>

    )
}