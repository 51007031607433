import * as React from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import {ReceiverGroup} from '../../model/ReceiverGroup';
import {Button, IconButton, Typography} from '@mui/material';
import classes from '../../styling/components/List.module.css'
import {json, Link, useNavigate, useParams} from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import {deleteReceiverGroup} from '../../api/receiverGroupClient';
import {useTranslation} from 'react-i18next';
import {useConfirmationDialog} from "../../hooks/useConfirmationDialog";

interface Props {
  receiverGroups: ReceiverGroup[]
}

const ReceiverGroupList: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { customerId } = useParams();

  const [confirmationDialog, openConfirmationDialog] = useConfirmationDialog()

  const confirmDelete = (receiverGroupId: string) => {
    openConfirmationDialog(t('receiverGroupList.deleteConfirmationTitle'), t('receiverGroupList.deleteConfirmationContent'), () => handleDelete(receiverGroupId))
  }
  const handleDelete = async (receiverGroupId: string) => {
    const response = await deleteReceiverGroup(receiverGroupId, customerId!);
    if (!response.ok) {
      throw json({ message: `${t('receiverGroupList.deleteError')}` }, { status: response.status });
    } else {
      navigate(0);
      return;
    }
  }
  return (
      <>
        {confirmationDialog}
        <Box sx={{width: '10%', margin: '0 auto'}} className={classes.mainContainer}>
          {props.receiverGroups.length ?
              <Box><Typography variant="h4">{t('receiverGroupList.allReceiverGroups')}</Typography></Box> :
              <Box>{t('receiverGroupList.noReceiverGroupsYet')}</Box>}
          <Link to={"new-receivergroup"}>
            <Button variant="contained">
              {t('receiverGroupList.addReceiverGroup')}
            </Button>
          </Link>
          <List>
            {props.receiverGroups.map((receiverGroup) => (
                <ListItem key={receiverGroup.receiverGroupId} disablePadding>
                  <Link to={`edit-receivergroup/${receiverGroup.receiverGroupId}`}>
                    <ListItemText primary={receiverGroup.name}/>
                  </Link>
                  <IconButton
                      edge="end"
                      aria-label="delete"
                      disabled={receiverGroup.name === "Default"}
                      onClick={() => confirmDelete(receiverGroup.receiverGroupId!)}
                  >
                    <DeleteIcon/>
                  </IconButton>
                </ListItem>))}
          </List>
        </Box>
      </>
  );
}

export default ReceiverGroupList;