import * as React from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import classes from '../../styling/components/List.module.css'
import { IconButton, Typography } from '@mui/material';
import { Link, json, useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import { deleteDomain } from '../../api/domainClient';
import { DomainGroup } from '../../model/Domain';

interface Props {
  domainGroup: DomainGroup
}

const DomainList: React.FC<Props> = (props) => {
  const navigate = useNavigate();
  const handleDelete = async (domainId: string) => {
    const proceed = window.confirm('Are you sure?');

    if (proceed) {
      const response = await deleteDomain(domainId);
      if (!response.ok) {
        throw json({ message: 'Could not delete domain.' }, { status: response.status });
      }
      else {
        navigate('');
        return;
      }
    }
  }
  return (
    <Box sx={{ width: '300px', margin: '0 auto' }} className={classes.mainContainer}>
      <Typography variant="h4">{props.domainGroup.type} domains</Typography>
      <List>
        {props.domainGroup.domains.map((domain) => (
          <ListItem key={domain.domainId} disablePadding>
            <ListItemText primary={domain.domainId} />
            <Link to={`${domain.domainId}`}>
              <ListItemText primary={domain.domain} />
            </Link>
            <ListItemText primary={domain.placeholder} />
            <IconButton
              edge="end"
              aria-label="delete"
              onClick={() => handleDelete(domain.domainId!)}
            >
              <DeleteIcon />
            </IconButton>
          </ListItem>))}
      </List>
    </Box>
  );
}

export default DomainList;