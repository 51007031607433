import React from "react";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import classes from "../../../styling/components/analytics/ReceiverGroupAnalyticsTable.module.css";
import {useTranslation} from "react-i18next";
import {toPct} from "../../../util/Calc";
import {ProgressBar} from "./ProgressBar";

type Props = {
    stats: {
        name: string,
        sent: number,
        clicked: number,
        reported: number,
    }[],
    reportingEnabled: boolean
}

export const ReceiverGroupAnalyticsTable: React.FC<Props> = (props) => {

    const {stats, reportingEnabled} = props
    const {t} = useTranslation()

    const rows = stats.map(stat => {
        return {
            name: stat.name,
            data: {
                clicked: stat.clicked,
                reported: stat.reported,
                sent: stat.sent,
                clickRate: toPct(stat.clicked, stat.sent),
                reportingRate: toPct(stat.reported, stat.sent),
            }
        }
    }).sort((a, b) => {
        if (a.data.clickRate < b.data.clickRate)
            return 1
        if (a.data.clickRate > b.data.clickRate)
            return -1
        return 0
    })


    return (
        <Table className={classes.dataTable}>
            <TableHead>
                <TableRow>
                    <TableCell>{t("campaignAnalyticsView.receiverGroup")}</TableCell>
                    <TableCell>{t("campaignAnalyticsView.clickRate")}</TableCell>
                    {reportingEnabled ?
                        <TableCell>{t("campaignAnalyticsView.reportingRate")}</TableCell>
                        : null}
                </TableRow>
            </TableHead>
            <TableBody>
                {rows.map((row, index) => {
                    return (
                        <TableRow key={row.name}>
                            <TableCell>{row.name}</TableCell>
                            <TableCell>
                                <ProgressBar color={'click'} value={row.data.clickRate} textColor={"black"}
                                             tooltipContent={`${t("campaignAnalyticsView.clickRate")}: ${row.data.clicked}/${row.data.sent} (${row.data.clickRate}%)`}/>
                            </TableCell>
                            {reportingEnabled ?
                                <TableCell>
                                    <ProgressBar color={'reporting'} value={row.data.reportingRate} textColor={"black"}
                                                 tooltipContent={`${t("campaignAnalyticsView.reportingRate")}: ${row.data.reported}/${row.data.sent} (${row.data.reportingRate}%)`}/>
                                </TableCell>
                                : null}
                        </TableRow>
                    )
                })}
            </TableBody>
        </Table>
    )
}