// DomainUpdate.tsx
import React, {useState} from 'react';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import {Domain} from '../../../model/Domain';
import {CampaignDomains} from '../../../model/Campaign';
import {updateCampaignDomains} from '../../../api/campaignClient';
import DomainMultiSelect from '../DomainMultiSelect';
import ConfirmDomainsDialog from './ConfirmDomainsDialog';
import { useTranslation } from 'react-i18next';
import {useParams} from "react-router-dom";

interface DomainUpdateProps {
  campaignId: string;
  campaignSenderDomains: Domain[];
  campaignStandardDomains: Domain[];
  campaignMailFromDomains: Domain[];
  senderDomains: Domain[];
  standardDomains: Domain[];
  mailFromDomains: Domain[];
  enableEdit: boolean;
}

const DomainUpdate: React.FC<DomainUpdateProps> = ({
  campaignId,
  campaignSenderDomains,
  campaignStandardDomains,
  campaignMailFromDomains,
  senderDomains,
  standardDomains,
  mailFromDomains,
  enableEdit
}) => {
  const { t } = useTranslation();
  const {customerId} = useParams();
  const [confirmDomainsDialogOpen, setConfirmDomainsDialogOpen] = useState<boolean>(false);
  const [selectedSenderDomains, setSelectedSenderDomains] = useState<Domain[]>(campaignSenderDomains);
  const [selectedStandardDomains, setSelectedStandardDomains] = useState<Domain[]>(campaignStandardDomains);
  const [selectedMailFromDomains, setSelectedMailFromDomains] = useState<Domain[]>(campaignMailFromDomains);
  const [updateDomainsError, setUpdateDomainsError] = useState<boolean>(false);
  const [updateDomainsSuccessfully, setUpdateDomainsSuccessfully] = useState<boolean>(false);

  const updateDomainsHandler = () => {
    setConfirmDomainsDialogOpen(true);
  };

  const handleConfirmDomainsDialogClose = () => {
    setConfirmDomainsDialogOpen(false);
  };

  const updateDomains = async () => {
    setUpdateDomainsError(false);
    setUpdateDomainsSuccessfully(false);
    const updateDomains: CampaignDomains = selectedSenderDomains.concat(selectedStandardDomains).concat(selectedMailFromDomains) as unknown as CampaignDomains;
    if (updateDomains.length >= 2) {
      const response = await updateCampaignDomains(updateDomains, campaignId, customerId!);
      if (!response.ok) {
        setUpdateDomainsError(true);
      } else {
        setUpdateDomainsSuccessfully(true);
      }
      setConfirmDomainsDialogOpen(false);
    } else {
      setUpdateDomainsError(true);
      setConfirmDomainsDialogOpen(false);
    }
  }

  return (
    <div>
      <DomainMultiSelect
        id="senderdomains"
        name="senderdomains"
        label={t('domainUpdate.senderDomains')}
        defaultValue={campaignSenderDomains.map((domain) => parseInt(domain.domainId!))}
        options={senderDomains}
        onSelectChange={(selectedValues) => setSelectedSenderDomains(selectedValues)}
        enableEdit={enableEdit}
      />
      <DomainMultiSelect
        id="standarddomains"
        name="standarddomains"
        label={t('domainUpdate.standardDomains')}
        defaultValue={campaignStandardDomains.map((domain) => parseInt(domain.domainId!))}
        options={standardDomains}
        onSelectChange={(selectedValues) => setSelectedStandardDomains(selectedValues)}
        enableEdit={enableEdit}
      />
      <DomainMultiSelect
        id="mailfromdomains"
        name="mailfromdomains"
        label={t('domainUpdate.mailFromDomains')}
        defaultValue={campaignMailFromDomains.map((domain) => parseInt(domain.domainId!))}
        options={mailFromDomains}
        onSelectChange={(selectedValues) => setSelectedMailFromDomains(selectedValues)}
        enableEdit={enableEdit}
      />
      {enableEdit && <Button type="button" variant="outlined" onClick={updateDomainsHandler}>
        {t('domainUpdate.updateDomains')}
      </Button>}
      <ConfirmDomainsDialog
        open={confirmDomainsDialogOpen}
        onClose={handleConfirmDomainsDialogClose}
        onContinue={updateDomains}
        selectedDomains={{ senderDomains: selectedSenderDomains, standardDomains: selectedStandardDomains, mailFromDomains: selectedMailFromDomains }}
      />
      {updateDomainsSuccessfully && (
        <Alert onClose={() => setUpdateDomainsSuccessfully(false)} severity="success">
          {t('domainUpdate.updatedSuccessfully')}
        </Alert>
      )}
      {updateDomainsError && (
        <Alert onClose={() => setUpdateDomainsError(false)} severity="error">
          {t('domainUpdate.updatedError')}
        </Alert>
      )}
    </div>
  );
};

export default DomainUpdate;
