import React, { useEffect, useState } from 'react';
import { DateField, DatePicker, DatePickerProps, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { Campaign } from '../../model/Campaign';
import { TextField } from '@mui/material';
import classes from '../../styling/components/Editor.module.css';
import { useTranslation } from 'react-i18next';


interface CampaignDateInputProps {
  campaign?: Campaign;
  onErrorChange?: (name: string, errorState: boolean) => void;
}

dayjs.extend(utc);

const CampaignDateInput: React.FC<CampaignDateInputProps> = (props) => {
  const { t } = useTranslation();
  const today = dayjs();
  const todayPlus1Year = dayjs().add(1, 'year');

  const [startDate, setStartDate] = useState<any>(dayjs.utc(props.campaign ? props.campaign.startDate : today));
  const handleStartDateChange = (newDate: Dayjs | null) => {
    if (newDate && newDate.isValid()) {
      setStartDate(newDate);
    } else {
      if (props.onErrorChange)
        props.onErrorChange("date", true);
    }
  };
  const [endDate, setEndDate] = useState<any>(dayjs.utc(props.campaign ? props.campaign.endDate : todayPlus1Year));
  const handleEndDateChange = (newDate: Dayjs | null) => {
    if (newDate && newDate.isValid()) {
      setEndDate(newDate);
    } else {
      if (props.onErrorChange)
        props.onErrorChange("date", true);
    }
  };

  // Use useEffect to check the validity of startDate and endDate whenever they change
  useEffect(() => {
    if (startDate && endDate && startDate.isValid() && endDate.isValid()) {
      if (!startDate.isBefore(endDate)) {
        if (props.onErrorChange)
          props.onErrorChange("date", true);
      } else {
        if (props.onErrorChange)
          props.onErrorChange("date", false);
      }
    } else {
      // Optionally handle the case where either date is invalid or null
      if (props.onErrorChange)
        props.onErrorChange("date", true);
    }
  }, [startDate, endDate]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        defaultValue={dayjs.utc(props.campaign ? props.campaign.startDate : today)}
        minDate={today}
        label={t('campaignDateInput.startDate')}
        format="DD.MM.YYYY"
        value={startDate}
        onChange={handleStartDateChange}
      />
      <TextField id="startDate" label={t('campaignDateInput.startDate')} name="startDate" className={classes.displayNone} required fullWidth value={new Date(startDate).toISOString().split('T')[0]} />
      <DatePicker
        defaultValue={dayjs.utc(props.campaign ? props.campaign.endDate : todayPlus1Year)}
        minDate={dayjs.utc(startDate).add(1, 'day')}
        label={t('campaignDateInput.endDate')}
        format="DD.MM.YYYY"
        value={endDate}
        onChange={handleEndDateChange}
      />
      <TextField id="endDate" label={t('campaignDateInput.endDate')} name="endDate"  className={classes.displayNone} required fullWidth value={new Date(endDate).toISOString().split('T')[0]} />

    </LocalizationProvider>
  );
};

export default CampaignDateInput;