import React, {useContext} from "react";
import {Analytics, TotalCampaignAnalytics} from "../../model/Analytics";
import {Campaign} from "../../model/Campaign";
import {useTranslation} from "react-i18next";
import {HeaderCard} from "../Base/HeaderCard";
import {StatCardPanel} from "./Base/StatCardPanel";
import {GraphedAnalyticsCard} from "./Base/GraphedAnalyticsCard";
import {ReceiverGroupCard} from "./Base/ReceiverGroupCard";
import {NavigationHeader} from "../Navigation/NavigationHeader";
import {useParams} from "react-router-dom";
import {toPct} from "../../util/Calc";
import {TemplateCard} from "./Base/TemplateCard";
import {Grid} from "@mui/material";
import {BaselineComparisonCard} from "./Base/BaselineComparisonCard";
import {UserContext} from "../../App";

interface Props {
    analytics: Analytics
    campaign: Campaign
    demo?: boolean
}

export const CampaignAnalyticsView: React.FC<Props> = (props) => {
    const {t, i18n} = useTranslation();
    const {customerId} = useParams();
    const {analytics, campaign, demo} = props
    const {
        campaignAnalytics,
        receiverGroupAnalytics,
        templateAnalytics,
        timespanAnalytics
    } = analytics

    const {user} = useContext(UserContext)

    const inBaseline = campaign.baselineEnabled && new Date(campaign.startDate) < new Date() && new Date() <= new Date(campaign.baselineEndDate)


    const getTotalCampaignAnalytics = (totalAnalytics: TotalCampaignAnalytics) => {
        const { baselineAnalytics, followUpAnalytics } = totalAnalytics;
    
        return {
            total: baselineAnalytics.total + followUpAnalytics.total,
            sent: baselineAnalytics.sent + followUpAnalytics.sent,
            reported: baselineAnalytics.reported + followUpAnalytics.reported,
            clicked: baselineAnalytics.clicked + followUpAnalytics.clicked,
            interactableTotal: baselineAnalytics.interactableTotal + followUpAnalytics.interactableTotal,
            interactableClicked: baselineAnalytics.interactableClicked + followUpAnalytics.interactableClicked,
            interacted: baselineAnalytics.interacted + followUpAnalytics.interacted,
            interactedCritical: baselineAnalytics.interactedCritical + followUpAnalytics.interactedCritical,
        };
    };

    const totalCampaignAnalytics = getTotalCampaignAnalytics(campaignAnalytics);

    const clickrate = Math.round(totalCampaignAnalytics.clicked / totalCampaignAnalytics.sent * 100) || 0

    const navigationPath = [
        {
            url: demo ? `/customers/${customerId}/demo/analytics` : `/customers/${customerId}/analytics`,
            label: "Kampagnen"
        },
        {url: "", label: campaign.name}
    ]

    const readableBaselineEndDate = new Date(campaign.baselineEndDate).toLocaleString(i18n.language, {
        year: "numeric",
        month: "long",
        day: "numeric"
    })

    const readableEndDate = new Date(campaign.endDate).toLocaleString(i18n.language, {
        year: "numeric",
        month: "long",
        day: "numeric"
    })

    const headerCardBody = () => {
        if (inBaseline) {
            return t('campaignAnalyticsView.campaignInfoBaseline', {
                sent: totalCampaignAnalytics.sent,
                total: totalCampaignAnalytics.total,
                name: campaign.name,
                baselineEndDate: readableBaselineEndDate
            })
        } else if (campaign.status === "Running" && campaign.baselineEnabled) {
            return t('campaignAnalyticsView.campaignInfoFollowUp', {
                name: campaign.name,
                sent: totalCampaignAnalytics.sent,
                total: totalCampaignAnalytics.total,
                baselineEndDate: readableBaselineEndDate
            })
        } else if (campaign.status === "Running") {
            return t('campaignAnalyticsView.campaignInfo', {
                name: campaign.name,
                sent: totalCampaignAnalytics.sent,
                total: totalCampaignAnalytics.total,
            })
        } else if (campaign.status === "Finished") {
            return t('campaignAnalyticsView.campaignInfoFinished', {
                sent: totalCampaignAnalytics.sent,
                clickrate: clickrate,
                endDate: readableEndDate
            })
        }
    }

    const templateInteractionStats = analytics.templateAnalytics.filter(template => template.interactable).map(template => {
        return {
            name: template.name,
            templateId: template.templateId,
            interacted: template.interacted,
            interactedCritical: template.interactedCritical,
            interactableClicked: template.clicked
        }
    })
    const interactionEnabled = templateInteractionStats.length > 0
    const reportingEnabled = analytics.reportingEnabled

    const filteredRgAnalytics = receiverGroupAnalytics.filter(rg => rg.name !== "Default")

    // Only show the graphed card after 2 months, and only if a campaign is at least 6 months long
    const showGraphedCard = timespanAnalytics.length >= 6 && new Date(timespanAnalytics[2].startDate) < new Date() && !inBaseline


    return (
        <div>
            <NavigationHeader path={navigationPath}/>
            <HeaderCard
                headline={(<>{t('campaignAnalyticsView.greeting', {name: user.firstName ?? ""})}</>)}
                body={<>{headerCardBody()}</>}
                progress={toPct(totalCampaignAnalytics.sent, totalCampaignAnalytics.total)}
            />
            <StatCardPanel stats={totalCampaignAnalytics} reportingEnabled={reportingEnabled}
                           interactionEnabled={interactionEnabled}/>
            {showGraphedCard ?
                <GraphedAnalyticsCard timespanStats={timespanAnalytics} reportingEnabled={reportingEnabled}/> : null}

            <Grid container>
                <Grid item xs={12} md={5.7}>
                    <ReceiverGroupCard title={t('receiverTable.receiverGroups')}
                                       stats={filteredRgAnalytics}
                                       reportingEnabled={reportingEnabled}
                    />
                </Grid>
                <Grid item xs={.6}/>
                <Grid item xs={12} md={5.7}>
                    <TemplateCard clickStats={templateAnalytics}
                                  interactionStats={templateInteractionStats} showInteractionRate={interactionEnabled}/>
                </Grid>
            </Grid>
            {campaign.baselineEnabled && !inBaseline ?
                <BaselineComparisonCard stats={campaignAnalytics} reportingEnabled={reportingEnabled}
                                        interactionEnabled={interactionEnabled} inBaseline={inBaseline}/>
                : null}

        </div>
    )
}
