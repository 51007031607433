import * as React from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import {Customer} from '../../model/Customer';
import {Link} from 'react-router-dom';
import classes from '../../styling/components/List.module.css'
import {Typography} from '@mui/material';
import { useTranslation } from 'react-i18next';

interface Props {
    customers: Customer[]
}

const CustomersList: React.FC<Props> = (props) => {
  const { t } = useTranslation();
    return (
    <Box sx={{ width: '300px', margin: '0 auto' }} className={classes.mainContainer}>
      {props.customers.length ? <Box><Typography variant="h4">{t('customersList.allCustomers')}</Typography></Box> : <Box>No customers yet.</Box>}
      <List>
        {props.customers.map((customer) => (
          <ListItem key={customer.customerId} component={Link} to={`/customers/${customer.customerId}/templates`} disablePadding>
            <ListItemButton>
              <ListItemText primary={customer.fullName} />
            </ListItemButton>
          </ListItem>))}
      </List>
    </Box>
  );
}

export default CustomersList;