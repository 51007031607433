import React from "react";
import {toPct} from "../../../util/Calc";
import classes from "../../../styling/components/analytics/BaselineStatComparison.module.css";
import {Doughnut} from "react-chartjs-2";
import {ArcElement, Chart as ChartJS, ChartData, Tooltip} from "chart.js";
import {Grid} from "@mui/material";

interface Props {
    statName: string,
    baselineValue: number,
    baselineTotal: number,
    baselineData: ChartData<'doughnut'>,
    followUpValue: number,
    followUpTotal: number,
    followUpData: ChartData<'doughnut'>,
    icon: React.ReactElement,
    color: string,
}

export const BaselineStatComparison: React.FC<Props> = (props) => {
    ChartJS.register(ArcElement, Tooltip)
    const {
        statName,
        baselineValue,
        baselineTotal,
        baselineData,
        followUpValue,
        followUpTotal,
        followUpData,
        color,
        icon
    } = props

    const baselineRate = toPct(baselineValue, baselineTotal)
    const followUpRate = toPct(followUpValue, followUpTotal)
    const delta = Intl.NumberFormat("de-DE", {
        signDisplay: "always",
    }).format(followUpRate - baselineRate)

    return (
        <div className={classes.baselineStatComparison}>
            <Grid container justifyContent={'space-between'} alignItems={'center'}>
                <Grid item xs={2.5}>
                    <div className={classes.baselineNumbers}>
                        <div className={classes.titleArea}>{statName}</div>
                        <div className={classes.rate} style={{color: color}}>{baselineRate} %</div>
                        <div className={classes.amounts} style={{color: color}}>{baselineValue} / {baselineTotal}</div>
                    </div>
                </Grid>
                <Grid item xs={1.5}>
                    <div>
                        <Doughnut data={baselineData} options={{plugins: {legend: {display: false}}}}/>
                    </div>
                </Grid>
                <Grid item xs={4}>
                    <div className={classes.trendArea}>
                        <div className={classes.delta}>
                            <span>{delta}%</span>
                        </div>

                        <div className={classes.arrow}>
                            <img src={"/assets/img/arrow.svg"}/>
                        </div>
                        <div className={classes.icon}>
                            {icon}
                        </div>
                    </div>

                </Grid>
                <Grid item xs={1.5}>
                    <div>
                        <Doughnut data={followUpData} options={{plugins: {legend: {display: false}}}}/>
                    </div>
                </Grid>
                <Grid item xs={2.5}>
                    <div className={classes.followUpNumbers}>
                        <div className={classes.titleArea}>{statName}</div>
                        <div className={classes.rate} style={{color: color}}>{followUpRate} %</div>
                        <div className={classes.amounts} style={{color: color}}>{followUpValue} / {followUpTotal}</div>
                    </div>
                </Grid>
            </Grid>


        </div>
    )
}