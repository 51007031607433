import {Form, json, redirect, useNavigate, useNavigation, useParams} from 'react-router-dom';
import {createReceiver, deactivateReceiver, editReceiver} from '../../api/receiverClient';

import classes from '../../styling/components/Editor.module.css';
import {Receiver} from '../../model/Receiver';
import React, {useState} from 'react';
import {Box, Button, SelectChangeEvent,} from '@mui/material';
import {ReceiverGroup} from '../../model/ReceiverGroup';
import GenderSelect from './GenderSelect';
import ReceiverGroupSelect from './ReceiverGroupSelect';
import CustomTextField from '../Input/CustomTextfield';
import {useTranslation} from 'react-i18next';
import {useConfirmationDialog} from "../../hooks/useConfirmationDialog";

interface Props {
    method: string,
    receiver?: Receiver,
    receiverGroups: ReceiverGroup[]
}

const ReceiverEditor: React.FC<Props> = (props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const navigation = useNavigation();
    const { customerId } = useParams();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    function cancelHandler() {
        navigate('..');
    }

    const [errorStates, setErrorStates] = useState({});
    const [hasError, setHasError] = useState<boolean>(false);

    // Callback function to update the error state for a specific field
    const handleTextFieldError = (name: string, errorState: boolean) => {
        setErrorStates(prevErrorStates => {
            const updatedErrorStates = {
                ...prevErrorStates,
                [name]: errorState,
            };

            // Now checking inside the update function, where we have the updated state
            const anyError = Object.values(updatedErrorStates).some(error => error);
            setHasError(anyError);

            return updatedErrorStates;
        });
    };
    const [confirmationDialog, openConfirmationDialog] = useConfirmationDialog()
    const confirmDelete = () => {
        openConfirmationDialog(t('receiverEditor.deleteConfirmationTitle'), t('receiverEditor.deleteConfirmationContent'), deleteHandler)
    }
    async function deleteHandler() {
        const response = await deactivateReceiver(props.receiver!.receiverId!, customerId!);
        if (!response.ok) {
            throw json({ message: `${t('receiverEditor.deleteError')}` }, { status: response.status });
        }
        else {
            return navigate('..');
        }
    }
    // ReceiverGroups
    const [checkedReceiverGroups, setCheckedReceiverGroups] = useState<boolean[]>(
        props.receiverGroups.map((receiverGroup) => (
            props.receiver ? props.receiver.receiverGroups!.map(receiverGroup => receiverGroup.receiverGroupId).includes(receiverGroup.receiverGroupId) : receiverGroup.name === "Default" ? true : false))
    );

    const handleReceiverGroupChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const nextChecked = checkedReceiverGroups.map((c, i) => {
            if (i === index) {
                return event.target.checked;
            } else {
                return c;
            }
        });
        setCheckedReceiverGroups(nextChecked);
    }
    // Gender
    const [gender, setGender] = useState<string>(props.receiver ? props.receiver.gender : "");

    const handleGenderChange = (event: SelectChangeEvent) => {
        setGender(event.target.value as string);
    };

    return (
        <>
            {confirmationDialog}
            <Box component={Form} method={props.method as any} className={classes.form}
                 onSubmit={() => setIsSubmitting(true)} autoComplete='off'>
                <CustomTextField name="firstName" label={t('receiverEditor.firstNameLabel')}
                                 defaultValue={props.receiver ? props.receiver.firstName : ''} maxLength={50}
                                 regEx={/^[a-zA-ZÀ-ÖØ-öø-ÿß\s-]+$/} helper={t('receiverEditor.firstNameHelper')}
                                 onErrorChange={handleTextFieldError}/>
                <CustomTextField name="lastName" label={t('receiverEditor.lastNameLabel')}
                                 defaultValue={props.receiver ? props.receiver.lastName : ''} maxLength={50}
                                 regEx={/^[a-zA-ZÀ-ÖØ-öø-ÿß\s-]+$/} helper={t('receiverEditor.lastNameHelper')}
                                 onErrorChange={handleTextFieldError}/>
                <GenderSelect gender={gender} handleGenderChange={handleGenderChange}/>
                <CustomTextField name="emailAddress" label={t('receiverEditor.emailLabel')}
                                 defaultValue={props.receiver ? props.receiver.emailAddress : ''} maxLength={50}
                                 regEx={/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/}
                                 helper={t('receiverEditor.emailHelper')} onErrorChange={handleTextFieldError}/>
                <ReceiverGroupSelect receiverGroups={props.receiverGroups} checked={checkedReceiverGroups}
                                     handleChange={handleReceiverGroupChange}/>
                {props.method === 'PUT' &&
                    <>
                        <Button type="button" className={classes.delete} variant="contained" onClick={confirmDelete}
                                disabled={isSubmitting}>{t('receiverEditor.delete')}</Button>
                    </>}
                <Button type="button" variant="outlined" onClick={cancelHandler}
                        disabled={isSubmitting}>{t('receiverEditor.cancel')}</Button>
                <Button type="submit" variant="contained"
                        disabled={isSubmitting || hasError}>{t('receiverEditor.save')}</Button>
            </Box>
        </>
    );
}

export default ReceiverEditor;

export async function action({ request, params }: { request: Request, params: any }) {
    const method = request.method;
    const customerId = params.customerId;
    const data = await request.formData();
    const { firstName, lastName, gender, emailAddress, ...rest } = Object.fromEntries(data);
    let selectedReceiverGroups: any = Object.values(rest).map((name) => (
        { name: name }
    ));
    const defaultReceiverGroup = { name: "Default" };
    selectedReceiverGroups.push(defaultReceiverGroup);
    const receiverData = {
        firstName: data.get('firstName') as string,
        lastName: data.get('lastName') as string,
        gender: data.get('gender') as string,
        emailAddress: data.get('emailAddress') as string,
        customer: {
            customerId: customerId
        },
        active: true,
        receiverGroups: selectedReceiverGroups
    };

    if (method === 'POST') {
        const response = await createReceiver(receiverData, customerId);

        if (!response.ok) {
            throw json({ message: 'Could not add Receiver' }, { status: 500 });
        }
        else {
            return redirect('..');
        }
    }

    if (method === 'PUT') {
        const receiverId = params.receiverId;
        const response = await editReceiver(receiverData, receiverId, customerId);

        if (!response.ok) {
            throw json({ message: 'Could not update Receiver' }, { status: 500 });
        } else {
            return redirect('..');
        }
    }
}