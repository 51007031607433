import {TimespanAnalytics} from "../../../model/Analytics";

import classes from '../../../styling/components/analytics/GraphedAnalyticsCard.module.css';
import {
    CategoryScale,
    Chart as ChartJS,
    ChartData,
    ChartOptions,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip,
    TooltipItem
} from "chart.js";
import {useTranslation} from "react-i18next";
import {Line} from "react-chartjs-2";
import React from "react";
import {useTheme} from "@mui/material";

type Props = {
    timespanStats: TimespanAnalytics[]
    reportingEnabled: boolean
}
export const ClickRateGraph: React.FC<Props> = (props) => {

    const {timespanStats, reportingEnabled} = props
    const {t, i18n} = useTranslation()

    const theme = useTheme()

    const xAxis = timespanStats.map(stat => "M" + stat.timespanIndex)
    const clickData = timespanStats.map(stat => {
        return stat.sent === 0 ? 0 : (stat.clicked / stat.sent) ?? 0
    })
    const reportingData = timespanStats.map(stat => {
        return stat.sent === 0 ? 0 : (stat.reported / stat.sent) ?? 0
    })

    const chartData: ChartData<"line"> = {
        labels: xAxis,
        datasets: [{
            data: clickData, //@ts-ignore
            borderColor: theme.palette.click.main, //@ts-ignore
            backgroundColor: theme.palette.click.main,
            label: t('campaignAnalyticsView.clickRate'),
            fill: false,
            tension: 0,
            borderWidth: 2
        },
            ...(reportingEnabled ? [{
                data: reportingData, //@ts-ignore
                borderColor: theme.palette.reporting.main, //@ts-ignore
                backgroundColor: theme.palette.reporting.main,
                label: t('campaignAnalyticsView.reportingRate'),
                fill: false,
                tension: 0,
                borderWidth: 2
            }] : [])
        ],
    }
    const tooltipTitleCallback = (items: TooltipItem<'line'>[]) => {
        const item = items[0]
        const timespan = timespanStats[item.dataIndex];
        const readableStartDate = new Date(timespan.startDate).toLocaleString(i18n.language, {
            year: "numeric",
            month: "short",
            day: "numeric"
        })
        const readableEndDate = new Date(timespan.endDate).toLocaleString(i18n.language, {
            year: "numeric",
            month: "short",
            day: "numeric"
        })
        return `${item.label} (${readableStartDate} - ${readableEndDate})`
    }

    const tooltipLabelCallback = (item: TooltipItem<'line'>) => {
        console.log(item)
        const timespan = timespanStats[item.dataIndex]

        if (item.datasetIndex === 1) {
            return ` ${t('campaignAnalyticsView.reportingRate')}: ${Math.round(reportingData[item.dataIndex] * 100)}% (${timespan.reported} / ${timespan.sent})`
        } else {
            return ` ${t('campaignAnalyticsView.clickRate')}: ${Math.round(clickData[item.dataIndex] * 100)}% (${timespan.clicked} / ${timespan.sent})`
        }
    }

    const chartOptions: ChartOptions<'line'> = {
        scales: {
            y: {beginAtZero: true, ticks: {format: {style: 'percent', maximumSignificantDigits: 2}}}
        },
        plugins: {
            tooltip: {
                callbacks: {
                    title: tooltipTitleCallback,
                    label: tooltipLabelCallback
                }
            }
        }
    }

    ChartJS.register(LineElement, CategoryScale, PointElement, LinearScale, Title, Tooltip, Legend);

    return (
        <div className={classes.graph}>
            <Line data={chartData} options={chartOptions}/>
        </div>
    )
}