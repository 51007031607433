import {Await, defer, useLoaderData} from "react-router-dom";
import React, {Suspense} from "react";
import {UserEditor} from "../../components/User/UserEditor";
import {OrganizationLevel} from "../../model/enum/OrganizationLevel";
import {loadInstanceRoles} from "./EditInstanceUser";
import {CircularProgress} from "@mui/material";

const NewInstanceUserPage: React.FC = (props) => {
    const {availableRoles} = useLoaderData() as { availableRoles: string[] };


    return (
        <Suspense fallback={<CircularProgress/>}>
            <Await resolve={availableRoles}>
                {availableRoles => <UserEditor method="POST" availableRoles={availableRoles}
                                               level={OrganizationLevel.INSTANCE}/>}
            </Await>
        </Suspense>
    )
}

export default NewInstanceUserPage;


export async function newInstanceUserPageLoader({
                                                    request,
                                                    params
                                                }: { request: Request, params: { instanceId: string } }) {
    const instanceId = params.instanceId;
    return defer({
        availableRoles: loadInstanceRoles(instanceId)
    })
}