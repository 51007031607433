import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';

interface LearningContent {
    location: string;
    title: string;
    content: string;
}

interface HintManagerProps {
    template?: { learningContents: LearningContent[] };
    onHintsChange: (hints: LearningContent[]) => void;
}

const HintManager: React.FC<HintManagerProps> = ({ template, onHintsChange }) => {
    const { t } = useTranslation();
    const [hints, setHints] = useState<LearningContent[]>(template ? template.learningContents : []);

    useEffect(() => {
        // Notify the parent about the changes in hints
        onHintsChange(hints);
    }, [hints, onHintsChange]);

    const addHint = () => {
        let newHint: LearningContent;
        const senderExists = hints.some((hint) => hint.location === 'l_sender');
        const subjectExists = hints.some((hint) => hint.location === 'l_subject');

        if (!senderExists) {
            newHint = {
                location: 'l_sender',
                title: '',
                content: '',
            };
        } else if (!subjectExists) {
            newHint = {
                location: 'l_subject',
                title: '',
                content: '',
            };
        } else {
            const bodyHints = hints.filter((hint) => hint.location.startsWith('l_body_'));
            const nextBodyNumber = bodyHints.length + 1;

            newHint = {
                location: `l_body_${nextBodyNumber}`,
                title: ``,
                content: ``,
            };
        }
        //Sort hints
        // Calculate the updated order
        const order = ['l_sender', 'l_subject', ...(hints.filter(hint => hint.location.startsWith('l_body_')).map(hint => hint.location)), newHint.location];

        // Sort hints using the calculated order
        const updatedHints = [...hints, newHint].sort((a, b) => order.indexOf(a.location) - order.indexOf(b.location));

        setHints(updatedHints);
    };

    const handleLearningContentChange = (location: string, field: string, value: string): void => {
        const updatedHints = hints.map((hint) =>
            hint.location === location ? { ...hint, [field]: value } : hint
        );
        setHints(updatedHints);
    };

    const deleteHint = (locationToDelete: string) => {
        const updatedHints = hints
            .filter((hint) => hint.location !== locationToDelete)
            .map((hint, index) => {
                if (hint.location.startsWith('l_body_') && parseInt(hint.location.split('_')[2], 10) > parseInt(locationToDelete.split('_')[2], 10)) {
                    return {
                        ...hint,
                        location: `l_body_${parseInt(hint.location.split('_')[2], 10) - 1}`,
                    };
                }
                return hint;
            });

        setHints(updatedHints);
    };

    return (
        <>
            {hints.map((hint) => (
                <div key={hint.location}>
                    <TextField
                        id={`${hint.location}_title`}
                        label={`${t('hintManager.labelStart')} ${hint.location.slice(2)} ${t('hintManager.labelTitleEnd')}`}
                        name={`${hint.location}_title`}
                        margin="normal"
                        fullWidth
                        required
                        inputProps={{maxLength: 50}}
                        value={hint.title}
                        onChange={(e) => handleLearningContentChange(hint.location, 'title', e.target.value)}
                    />
                    <TextField
                        id={`${hint.location}_content`}
                        label={`${t('hintManager.labelStart')} ${hint.location.slice(2)} ${t('hintManager.labelContentEnd')}`}
                        name={`${hint.location}_content`}
                        margin="normal"
                        fullWidth
                        multiline
                        required
                        inputProps={{maxLength: 255}}
                        value={hint.content}
                        onChange={(e) => handleLearningContentChange(hint.location, 'content', e.target.value)}
                    />
                    <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => deleteHint(hint.location)}
                    >
                        {t('hintManager.deleteHint')}
                    </Button>
                </div>
            ))}
            <Button variant="outlined" color="primary" onClick={addHint}>
                {t('hintManager.addHint')}
            </Button>
        </>
    );
};

export default HintManager;