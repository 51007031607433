import {Suspense} from "react";
import {Await, defer, json, useLoaderData} from "react-router-dom";
import {loadCustomerLogo} from "../Customer/EditCustomer";
import EmailPreview from "../../components/Template/TemplatePreview/EmailPreview";
import {CircularProgress} from "@mui/material";
import {getTemplateLearningPagePreview} from "../../api/templateClient";
import {LearningPageInfo} from "../../model/LearningPageInfo";

const TemplatePreviewPage: React.FC = (props) => {
    const {
        learningPageInfo,
        customerLogo
    } = useLoaderData() as { learningPageInfo: LearningPageInfo, customerLogo: any };

    return (
        <Suspense fallback={<CircularProgress/>}>
            <Await resolve={Promise.all([learningPageInfo, customerLogo])}>
                {data => <EmailPreview learningPageInfo={data[0]} customerLogo={data[1]}/>}
            </Await>
        </Suspense>
    )
}

export default TemplatePreviewPage;

export async function loadTemplatePreview(templateId: string, customerId: string) {
    const response = await getTemplateLearningPagePreview(templateId, customerId);

    if (!response.ok) {
        throw json({message: 'Could not fetch template.'}, {status: 500});
    } else {
        const resData = await response.json();
        return resData
    }
}

export async function loader({
                                 request,
                                 params
                             }: { request: Request, params: { customerId: string, templateId: string } }) {
    const customerId = params.customerId;
    const templateId = params.templateId;

    return defer({
        learningPageInfo: loadTemplatePreview(templateId, customerId),
        customerLogo: loadCustomerLogo(customerId),
    })
}