import {Form, json, redirect, useNavigate, useNavigation} from 'react-router-dom';
import {createCustomer, deleteCustomer, editCustomer} from '../../api/customerClient';
import classes from '../../styling/components/Editor.module.css';
import {Customer} from '../../model/Customer';
import {Box, Button, FormControlLabel, Switch} from '@mui/material';
import React, {useState} from "react";
import CustomTextField from '../Input/CustomTextfield';
import {useTranslation} from 'react-i18next';
import CustomerLogoUpload from './CustomerLogoUpload';
import {useConfirmationDialog} from "../../hooks/useConfirmationDialog";

interface Props {
    method: string,
    customer?: Customer,
    customerLogo?: any
}

const CustomerEditor: React.FC<Props> = (props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const navigation = useNavigation();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const [errorStates, setErrorStates] = useState({});
    const [hasError, setHasError] = useState<boolean>(false);
    const [customerLogo, setCustomerLogo] = useState(props.customerLogo);
    // Callback function to update the error state for a specific field
    const handleTextFieldError = (name: string, errorState: boolean) => {
        setErrorStates(prevErrorStates => {
            const updatedErrorStates = {
                ...prevErrorStates,
                [name]: errorState,
            };

            // Now checking inside the update function, where we have the updated state
            const anyError = Object.values(updatedErrorStates).some(error => error);
            setHasError(anyError);

            return updatedErrorStates;
        });
    };

    function cancelHandler() {
        if (props.method === 'POST') {
            navigate('..');
        } else {
            navigate('../templates')
        }
    }

    const [confirmationDialog, openConfirmationDialog] = useConfirmationDialog()

    const confirmDelete = () => {
        openConfirmationDialog(t('customerEditor.deleteConfirmationTitle'), t('customerEditor.deleteConfirmationContent'), deleteHandler)
    }
    async function deleteHandler() {
        const response = await deleteCustomer(props.customer!.customerId!);
        if (!response.ok) {
            throw json({ message: 'Could not delete customer.' }, { status: 500 });
        }
        else {
            return navigate('/');
        }
    }
    const [brandingEnabled, setBrandingEnabled] = useState(props.customer ? props.customer.brandingEnabled : false);
    const handleBrandingEnabledChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setBrandingEnabled(event.target.checked);
    };
    const [reportButtonEnabled, setReportButtonEnabled] = useState(props.customer ? props.customer.reportButtonEnabled : false);
    const handleReportButtonEnabledChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setReportButtonEnabled(event.target.checked);
    }
    return (
        <>
            {confirmationDialog}
            <Box component={Form} method={props.method as any} className={classes.form}
                 onSubmit={() => setIsSubmitting(true)} autoComplete='off'>
                <CustomTextField name="shortName" label={t('customerEditor.shortNameLabel')}
                                 defaultValue={props.customer ? props.customer.shortName : ''} maxLength={50}
                                 regEx={/^[A-Za-z0-9À-ÖØ-öø-ÿ-\s/()&.[\]]+$/}
                                 helper={t('customerEditor.shortNameHelper')} onErrorChange={handleTextFieldError}/>
                <CustomTextField name="fullName" label={t('customerEditor.fullNameLabel')}
                                 defaultValue={props.customer ? props.customer.fullName : ''} maxLength={50}
                                 regEx={/^[A-Za-z0-9À-ÖØ-öø-ÿ-\s/()&.[\]]+$/}
                                 helper={t('customerEditor.fullNameHelper')} onErrorChange={handleTextFieldError}/>
                <CustomTextField name="domain" label={t('customerEditor.domainLabel')}
                                 defaultValue={props.customer ? props.customer.domain : ''} maxLength={50}
                                 regEx={/^(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,}$/}
                                 helper={t('customerEditor.domainHelper')} onErrorChange={handleTextFieldError}/>
                <CustomTextField name="zipcode" label={t('customerEditor.zipcodeLabel')}
                                 defaultValue={props.customer ? props.customer.zipcode : ''} maxLength={5}
                                 regEx={/^\d{4,5}$/} helper={t('customerEditor.zipcodeHelper')}
                                 onErrorChange={handleTextFieldError}/>
                <CustomTextField name="city" label={t('customerEditor.cityLabel')}
                                 defaultValue={props.customer ? props.customer.city : ''} maxLength={50}
                                 regEx={/^([a-zA-Z\u0080-\u024F]+(?:. |-| |'))*[a-zA-Z\u0080-\u024F]*$/}
                                 helper={t('customerEditor.cityHelper')} onErrorChange={handleTextFieldError}/>
                <CustomTextField name="street" label={t('customerEditor.streetLabel')}
                                 defaultValue={props.customer ? props.customer.street : ''} maxLength={50}
                                 regEx={/^[\w\s\d\u00C0-\u017F.,\/'()-]{1,100}$/}
                                 helper={t('customerEditor.streetHelper')} onErrorChange={handleTextFieldError}/>
                <CustomTextField name="phoneNumber" label={t('customerEditor.phoneLabel')}
                                 defaultValue={props.customer ? props.customer.phoneNumber : ''} maxLength={30}
                                 regEx={/^[\d+()\-\s]+$/} helper={t('customerEditor.phoneHelper')}
                                 onErrorChange={handleTextFieldError}/>
                <FormControlLabel control={<Switch name="brandingEnabled" checked={brandingEnabled}
                                                   onChange={handleBrandingEnabledChange}/>}
                                  label={t('customerEditor.enableBranding')}/>
                <CustomTextField disabled={!brandingEnabled} name="primaryColor"
                                 label={t('customerEditor.primaryColorLabel')} required={false}
                                 defaultValue={props.customer ? props.customer.primaryColor : ''} maxLength={20}
                                 regEx={/^(?:#[0-9A-Fa-f]{6}|rgb\((?:\d{1,3}),\s*(?:\d{1,3}),\s*(?:\d{1,3})\))?$/}
                                 helper={t('customerEditor.primaryColorHelper')} onErrorChange={handleTextFieldError}/>
                <CustomTextField disabled={!brandingEnabled} name="secondaryColor"
                                 label={t('customerEditor.secondaryColorLabel')} required={false}
                                 defaultValue={props.customer ? props.customer.secondaryColor : ''} maxLength={20}
                                 regEx={/^(?:#[0-9A-Fa-f]{6}|rgb\((?:\d{1,3}),\s*(?:\d{1,3}),\s*(?:\d{1,3})\))?$/}
                                 helper={t('customerEditor.secondaryColorHelper')}
                                 onErrorChange={handleTextFieldError}/>
                {props.method === 'PUT' && <CustomerLogoUpload logo={customerLogo}/>}
                <FormControlLabel control={<Switch name="reportButtonEnabled" checked={reportButtonEnabled}
                                                   onChange={handleReportButtonEnabledChange}/>}
                                  label={t('customerEditor.enableReportButton')}/>
                <CustomTextField disabled={!reportButtonEnabled} name="helpdeskEmail"
                                 label={t('customerEditor.helpdeskEmailLabel')} required={false}
                                 defaultValue={props.customer ? props.customer.helpdeskEmail : ''} maxLength={50}
                                 regEx={/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/}
                                 helper={t('customerEditor.helpdeskEmailHelper')} onErrorChange={handleTextFieldError}/>
                <CustomTextField disabled={!reportButtonEnabled} name="msTenantIdentifier"
                                 label={t('customerEditor.msTenantIdLabel')} required={false}
                                 defaultValue={props.customer ? props.customer.msTenantIdentifier : ''} maxLength={36}
                                 regEx={/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/}
                                 helper={t('customerEditor.msTenantIdHelper')} onErrorChange={handleTextFieldError}/>
                <FormControlLabel control={<Switch name="base64Enabled"
                                                   defaultChecked={props.customer ? props.customer.base64Enabled : false}/>}
                                  label={t('customerEditor.enableBase64')}/>
                <br/>
                <FormControlLabel control={<Switch name="demoCustomer"
                                                   defaultChecked={props.customer ? props.customer.demoCustomer : false}/>}
                                  label={t('customerEditor.demoCustomer')}/>
                <Box>
                    {props.method === 'PUT' &&
                        <>
                            <Button type="button" className={classes.delete} variant="contained" onClick={confirmDelete}
                                    disabled={isSubmitting}>{t('customerEditor.delete')}</Button>
                        </>}
                    <Button type="button" variant="outlined" onClick={cancelHandler}
                            disabled={isSubmitting}>{t('customerEditor.cancel')}</Button>
                    <Button type="submit" variant="contained"
                            disabled={isSubmitting || hasError}>{t('customerEditor.save')}</Button>
                </Box>
            </Box>
        </>
    );
}

export default CustomerEditor;

export async function action({ request, params }: { request: Request, params: any }) {
    const method = request.method;
    const data = await request.formData();
    const brandingEnabled = data.get('brandingEnabled') === 'on';
    const reportButtonEnabled = data.get('reportButtonEnabled') === 'on';
    const base64Enabled = data.get('base64Enabled') === 'on';
    const demoCustomer = data.get('demoCustomer') === 'on';
    const customerData = {
        shortName: data.get('shortName') as string,
        fullName: data.get('fullName') as string,
        domain: data.get('domain') as string,
        zipcode: data.get('zipcode') as string,
        city: data.get('city') as string,
        street: data.get('street') as string,
        phoneNumber: data.get('phoneNumber') as string,
        primaryColor: data.get('primaryColor') as string,
        secondaryColor: data.get('secondaryColor') as string,
        brandingEnabled: brandingEnabled,
        helpdeskEmail: data.get('helpdeskEmail') as string,
        msTenantIdentifier: data.get('msTenantIdentifier') as string,
        reportButtonEnabled: reportButtonEnabled,
        base64Enabled: base64Enabled,
        demoCustomer: demoCustomer
    };

    if (method === 'POST') {
        const response = await createCustomer(customerData, "1");

        if (!response.ok) {
            throw json({ message: 'Could not add customer' }, { status: 500 });
        }
        else {
            return redirect('..');
        }
    }

    if (method === 'PUT') {
        const customerId = params.customerId;
        const response = await editCustomer(customerData, customerId);

        if (!response.ok) {
            throw json({ message: 'Could not update customer' }, { status: 500 });
        } else {
            return redirect('/');
        }
    }
}