// TemplateUpdate.tsx
import React, {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import {Template} from '../../../model/Template';
import {Campaign, CampaignTemplates} from '../../../model/Campaign';
import {updateCampaignTemplates} from '../../../api/campaignClient';
import ConfirmTemplatesDialog from './ConfirmTemplatesDialog';
import {useParams} from "react-router-dom";
import {useTranslation} from 'react-i18next';
import {Box, Typography} from '@mui/material';
import dayjs from 'dayjs';
import TemplateTable from "../../Template/TemplateTable";

interface TemplateUpdateProps {
  templates: Template[];
  campaign: Campaign;
  enableEdit: boolean;
  baselineEnabled: boolean;
}

const TemplateUpdate: React.FC<TemplateUpdateProps> = ({ templates, campaign, enableEdit, baselineEnabled }) => {
  const { customerId } = useParams();
  const { t } = useTranslation();
  const [selectedFollowUpTemplateIds, setSelectedFollowUpTemplateIds] = useState<string[]>([]);
  const [selectedBaselineTemplateIds, setSelectedBaselineTemplateIds] = useState<string[]>([]);
  useEffect(() => {
    setSelectedFollowUpTemplateIds(campaign.followUpTemplates?.map(template => template.templateId!) as string[]);
    setSelectedBaselineTemplateIds(campaign.baselineTemplates?.map(template => template.templateId!) as string[]);
  }, [])
  const [confirmTemplatesDialogOpen, setConfirmTemplatesDialogOpen] = useState(false);
  const [updateTemplatesError, setUpdateTemplatesError] = useState<boolean>(false);
  const [updateTemplatesSuccessfully, setUpdateTemplatesSuccessfully] = useState<boolean>(false);

  const updateTemplatesHandler = () => {
    // Open the confirm dialog
    setConfirmTemplatesDialogOpen(true);
  };
  const handleConfirmTemplatesDialogClose = () => {
    // Close the confirm dialog
    setConfirmTemplatesDialogOpen(false);
  };

  const updateTemplates = async () => {
    setUpdateTemplatesSuccessfully(false);
    setUpdateTemplatesError(false);
    let updateBaselineTemplates: CampaignTemplates;
    if (baselineEnabled) {
      updateBaselineTemplates = selectedBaselineTemplateIds.map((id) => ({ templateId: id })) as unknown as CampaignTemplates;
    } else {
      updateBaselineTemplates = [];
    }
    updateBaselineTemplates = selectedBaselineTemplateIds.map((id) => ({ templateId: id })) as unknown as CampaignTemplates;
    const updateFollowUpTemplates: CampaignTemplates = selectedFollowUpTemplateIds.map((id) => ({ templateId: id })) as unknown as CampaignTemplates;
    if (updateFollowUpTemplates.length >= 1 && (updateBaselineTemplates.length >= 1 || !baselineEnabled)) {
      const response = await updateCampaignTemplates(updateBaselineTemplates, updateFollowUpTemplates, campaign.campaignId!, customerId!);
      if (!response.ok) {
        setUpdateTemplatesError(true);
      } else {
        setUpdateTemplatesSuccessfully(true);
      }
      // Close the confirm dialog after updating
      setConfirmTemplatesDialogOpen(false);
    } else {
      setUpdateTemplatesError(true);
      setConfirmTemplatesDialogOpen(false);
    }
  }

  const followUpTemplateSelectionChangeHandler = (selection: string[]) => {
    setSelectedFollowUpTemplateIds(selection);
  };

  const baselineTemplateSelectionChangeHandler = (selection: string[]) => {
    setSelectedBaselineTemplateIds(selection);
  };

  // Define states to define the possible selection for baseline and followUp templates
  const [baselineSelection, setBaselineSelection] = useState<Template[]>(templates);
  const [followUpSelection, setFollowUpSelection] = useState<Template[]>(templates);

  // Adjust followUpSelection based on baseline selection changes
  useEffect(() => {
    console.log('Updating followUpSelection based on baseline changes', selectedBaselineTemplateIds);
    setFollowUpSelection(templates.filter(template =>
      !selectedBaselineTemplateIds.includes(template.templateId!)
    ));
  }, [selectedBaselineTemplateIds, templates]);
  // Adjust baselineSelection based on followUp selection changes
  useEffect(() => {
    console.log('Updating baselineSelection based on followUp changes', selectedFollowUpTemplateIds);
    setBaselineSelection(templates.filter(template =>
      !selectedFollowUpTemplateIds.includes(template.templateId!)
    ));
  }, [selectedFollowUpTemplateIds, templates]);

  // Only allow changes to baseline templates if the baselineEndDate is in the future
  const today = dayjs();
  const allowBaselineChange = today.isBefore(dayjs(campaign.baselineEndDate!));

  return (
    <div>
      {baselineEnabled &&
        <Box>
          <Typography>{t('templateUpdate.baselineTemplates')}</Typography>
          <TemplateTable templates={baselineSelection} onSelectionChange={baselineTemplateSelectionChangeHandler}
                         selectedTemplateIds={selectedBaselineTemplateIds} readonly={!allowBaselineChange}/>
        </Box>
      }
      <Typography>{t('templateUpdate.followUpTemplates')}</Typography>
      <TemplateTable templates={followUpSelection} onSelectionChange={followUpTemplateSelectionChangeHandler}
                     selectedTemplateIds={selectedFollowUpTemplateIds}/>
      {enableEdit && <Button type="button" variant="outlined" onClick={updateTemplatesHandler}>
        {t('templateUpdate.updateTemplates')}
      </Button>}
      <ConfirmTemplatesDialog
        open={confirmTemplatesDialogOpen}
        onClose={handleConfirmTemplatesDialogClose}
        onContinue={updateTemplates}
        baselineEnabled={baselineEnabled}
        selectedBaselineTemplateIds={selectedBaselineTemplateIds}
        selectedFollowUpTemplateIds={selectedFollowUpTemplateIds}
        templates={templates}
      />
      {updateTemplatesSuccessfully && (
        <Alert onClose={() => setUpdateTemplatesSuccessfully(false)} severity="success">
          {t('templateUpdate.updatedSuccessfully')}
        </Alert>
      )}
      {updateTemplatesError && (
        <Alert onClose={() => setUpdateTemplatesError(false)} severity="error">
          {t('templateUpdate.updatedError')}
        </Alert>
      )}
    </div>
  );
};

export default TemplateUpdate;
