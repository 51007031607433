import React, {Suspense} from "react";
import {Await, defer, json, useLoaderData} from "react-router-dom";
import {CircularProgress} from "@mui/material";
import {CampaignAnalyticsView} from "../../components/Analytics/CampaignAnalyticsView";
import {demoAnalyticsWithTemplates, demoCampaign} from "../../util/DemoData";
import {listDemoTemplates} from "../../api/templateClient";

const DemoCampaignAnalyticsPage: React.FC = (props) => {

    const {demoTemplates} = useLoaderData() as { demoTemplates: { name: string, templateId: string }[] };

    return (
        <Suspense fallback={<CircularProgress/>}>
            <Await resolve={demoTemplates}>
                {loadedDemoTemplates => <CampaignAnalyticsView
                    analytics={demoAnalyticsWithTemplates(loadedDemoTemplates)} campaign={demoCampaign} demo/>}
            </Await>
        </Suspense>
    )
}

export default DemoCampaignAnalyticsPage;

async function loadDemoTemplates(customerId: string) {
    const response = await listDemoTemplates(customerId);

    if (!response.ok) {
        throw json({message: 'Could not fetch Campaign.'}, {status: response.status});
    } else {
        const resData = await response.json();
        return resData
    }
}

export async function loader({
                                 request,
                                 params
                             }: { request: Request, params: { customerId: string } }) {
    const customerId = params.customerId;

    return defer({
        demoTemplates: loadDemoTemplates(customerId)
    })
}

