import {Dispatch} from "../model/Dispatch";
import {fetchWithToken} from "./fetchWithToken";

const getBaseUrl = () => {
  let url;
  switch(process.env.NODE_ENV) {
    case 'production':
      url = '/api/dispatches';
      break;
    case 'development':
     url = 'http://localhost:5000/api/dispatches';
     break;
    default:
      url = '/api/dispatches';
  }

  return url;
}

export async function getDispatch(dispatch_id: string){
    try {
        const response = await fetchWithToken(getBaseUrl() + `/${dispatch_id}`, {
            method: "GET",
        })
        return response
    } catch (error){
        throw new Error("Error")
    }
}

export async function getCampaignDispatches(campaign_id: string){
    try {
        const response = await fetchWithToken(getBaseUrl() + `/campaign=${campaign_id}`, {
            method: "GET",
        })
        return response
    } catch (error){
        throw new Error("Error")
    }
}

export async function listDispatches(){
    try {
        const response = await fetchWithToken(getBaseUrl(), {
            method: "GET"
        })
        return response
    } catch (error){
        throw new Error("Error")
    }
}

export async function deleteDispatch(dispatch_id: string){
    try {
        const response = await fetchWithToken(getBaseUrl() + `/${dispatch_id}`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
            },
        })
        return response
    } catch (error){
        throw new Error("Error")
    }
}

export async function createDispatch(dispatch: Dispatch){
    try {
        const response = await fetchWithToken(getBaseUrl(), {
            method: "POST",
            body: JSON.stringify(dispatch),
            headers: {
                "Content-Type": "application/json",
            },
        })
        return response
    } catch (error){
        throw new Error("Error")
    }
}

export async function editDispatch(dispatch: Dispatch, dispatch_id: string){
    try {
        const response = await fetchWithToken(getBaseUrl() + `/${dispatch_id}`, {
            method: "PUT",
            body: JSON.stringify(dispatch),
            headers: {
                "Content-Type": "application/json",
            },
        })
        return response
    } catch (error){
        throw new Error("Error")
    }
}

