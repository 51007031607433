import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {StyledEngineProvider} from '@mui/material/styles'
import {Amplify} from 'aws-amplify'
import '@aws-amplify/ui-react/styles.css';
import './i18n';
import {muiTheme} from "./MuiTheme";
import {ThemeProvider as MuiThemeProvider} from "@mui/material";

Amplify.configure({
    Auth: {
        region: process.env.REACT_APP_AWS_REGION,
        userPoolId: process.env.REACT_APP_USER_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_USER_POOL_APP_CLIENT_ID
    }
})

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
      <StyledEngineProvider injectFirst>
          <MuiThemeProvider theme={muiTheme}>
              <App/>
          </MuiThemeProvider>
      </StyledEngineProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
