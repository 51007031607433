import { Form, json, redirect, useNavigate, useNavigation } from 'react-router-dom';

import classes from '../../styling/components/Editor.module.css';
import React, { SyntheticEvent, useState } from "react";
import { Autocomplete, Box, Button, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import { Domain, DomainGroup, LinkPlaceholder, LpPlaceholder, MailFromPlaceholder, NonePlaceholder, Placeholder, SenderPlaceholder } from '../../model/Domain';
import { createDomain, editDomain } from '../../api/domainClient';
import CustomTextField from '../Input/CustomTextfield';
import { useTranslation } from 'react-i18next';

interface Props {
    method: string,
    domain?: Domain,
    domainGroups: DomainGroup[]
}

const DomainEditor: React.FC<Props> = (props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const navigation = useNavigation();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    function cancelHandler() {
        navigate('..');
    }

    const [errorStates, setErrorStates] = useState({});
    const [hasError, setHasError] = useState<boolean>(false);

    // Callback function to update the error state for a specific field
    const handleTextFieldError = (name: string, errorState: boolean) => {
        setErrorStates(prevErrorStates => {
            const updatedErrorStates = {
                ...prevErrorStates,
                [name]: errorState,
            };

            // Now checking inside the update function, where we have the updated state
            const anyError = Object.values(updatedErrorStates).some(error => error);
            setHasError(anyError);

            return updatedErrorStates;
        });
    };

    // Placeholder
    const [placeholder, setPlaceholder] = useState(props.domain ? props.domain.placeholder : "");

    const handlePlaceholderChange = (event: SelectChangeEvent) => {
        setPlaceholder(event.target.value as string);
    };
    // DomainType
    const [domainType, setDomainType] = useState(props.domain ? props.domain.type : '');

    const handleDomainTypeChange = (event: SyntheticEvent, newValue: string | null) => {
        setDomainType(newValue!);
    };

    const getPlaceholderOptions = (type: string) => {
        switch (type) {
            case 'mailFrom':
                return Object.values(MailFromPlaceholder);
            case 'Sender':
                return [...Object.values(NonePlaceholder), ...Object.values(SenderPlaceholder)];
            case 'Standard':
                return [...Object.values(NonePlaceholder), ...Object.values(LinkPlaceholder)];
            case 'LP':
                return [...Object.values(NonePlaceholder), ...Object.values(LpPlaceholder)];
            default:
                return Object.values(NonePlaceholder);
        }
    }
    const placeholderOptions = getPlaceholderOptions(domainType);



    return (
        <Box component={Form} method={props.method as any} className={classes.form} autoComplete='off' onSubmit={() => setIsSubmitting(true)}>
            <CustomTextField name="domain" label={t('domainEditor.domainLabel')} defaultValue={props.domain ? props.domain.domain : ''} maxLength={50} regEx={/^(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,}$/} helper={t('domainEditor.domainHelper')} onErrorChange={handleTextFieldError} />
            <div>
                <Autocomplete
                    id="type"
                    freeSolo
                    value={domainType}
                    onChange={handleDomainTypeChange}
                    options={props.domainGroups.map((option) => option.type)}
                    renderInput={(params) => <TextField {...params} name="type" label={t('domainEditor.typeLabel')} required fullWidth />}
                    inputValue={domainType}
                    onInputChange={(event, newInputValue) => {
                        setDomainType(newInputValue);
                    }}
                />
            </div>
            <div>
                <FormControl fullWidth>
                    <InputLabel id="placeholder-label">{t('domainEditor.placeholderLabel')}</InputLabel>
                    <Select
                        labelId="placeholder-label"
                        id="placeholder"
                        name="placeholder"
                        value={placeholder}
                        label={t('domainEditor.placeholderLabel')}
                        onChange={handlePlaceholderChange}
                    >
                        {placeholderOptions.map((value) => (
                            <MenuItem key={value} value={value}>
                                {value || 'None'}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
            <div>
                <Button type="button" variant="outlined" onClick={cancelHandler} disabled={isSubmitting}>{t('domainEditor.cancel')}</Button>
                <Button type="submit" variant="contained" disabled={isSubmitting || hasError}>{t('domainEditor.save')}</Button>
            </div>
        </Box>
    );
}

export default DomainEditor;

export async function action({ request, params }: { request: Request, params: any }) {
    const method = request.method;
    const data = await request.formData();

    const domainData = {
        domain: data.get('domain') as string,
        type: data.get('type') as string,
        placeholder: data.get('placeholder') as string,
    };

    if (method === 'POST') {
        const response = await createDomain(domainData);

        if (!response.ok) {
            throw json({ message: 'Could not add Domain' }, { status: response.status });
        }
        else {
            return redirect('..');
        }
    }

    if (method === 'PUT') {
        const domainId = params.domainId;
        const response = await editDomain(domainData, domainId);

        if (!response.ok) {
            throw json({ message: 'Could not update Domain' }, { status: response.status });
        } else {
            return redirect('..');
        }
    }
}