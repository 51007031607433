import React from "react";
import {useTranslation} from "react-i18next";
import {toPct} from "../../../util/Calc";
import {Table, TableBody, TableCell, TableRow} from "@mui/material";
import classes from "../../../styling/components/analytics/InteractionRateTable.module.css";
import {ProgressBar} from "./ProgressBar";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {Link, useParams} from "react-router-dom";

type Props = {
    stats: {
        name: string,
        templateId: string,
        interacted: number,
        interactedCritical: number,
        interactableClicked: number
    }[],
    cutOff?: number
}

export const InteractionRateTable: React.FC<Props> = (props) => {

    const {stats} = props
    const {t} = useTranslation()
    const {customerId} = useParams()
    const cutOff = props.cutOff ? props.cutOff : stats.length

    const rows = stats.map(stat => {
        return {
            name: stat.name,
            templateId: stat.templateId,
            data: {
                clicked: stat.interactableClicked ?? 0,
                interacted: stat.interacted ?? 0,
                interactedCritical: stat.interactedCritical ?? 0,
                interactedCriticalRate: toPct(stat.interactedCritical, stat.interactableClicked),
                interactedRate: toPct(stat.interacted, stat.interactableClicked),
                rate: toPct(stat.interacted + stat.interactedCritical, stat.interactableClicked)
            }
        }
    }).sort((a, b) => {
        if (a.data.rate < b.data.rate)
            return 1
        if (a.data.rate > b.data.rate)
            return -1
        if (a.data.interactedCriticalRate < b.data.interactedCriticalRate)
            return 1
        if (a.data.interactedCriticalRate > b.data.interactedCriticalRate)
            return -1
        return 0
    }).slice(0, cutOff)

    const tooltip = (interacted: number, interactedCritical: number, clicked: number) => {
        if (interacted === 0 && interactedCritical === 0)
            return ""
        return (
            <ul className={classes.legend}>
                {interactedCritical > 0 ?
                <li><span
                    className={classes.legendCriticalInteraction}></span>{`${t('campaignAnalyticsView.interactionRateCritical')}: ${interactedCritical}/${clicked} (${toPct(interactedCritical, clicked)}%)`}
                </li>
                    : null}
                {interacted > 0 ?
                    <li><span
                    className={classes.legendInteraction}></span>{`${t('campaignAnalyticsView.interactionRateNonCritical')}: ${toPct(interacted, clicked)}% (${interacted}/${clicked})`}
                </li>
                    : null}
            </ul>)
    }


    return (
        <Table className={classes.dataTable + (props.cutOff ? ` ${classes.collapsed}` : ` ${classes.extended}`)}>
            <TableBody>
                {rows.map((row, index) => {
                    return (
                        <TableRow key={row.name}>
                            <TableCell>{index + 1}.</TableCell>
                            <TableCell>{row.name}
                                {row.templateId != "demoTemplate" ?
                                <Link to={`/customers/${customerId}/templates/${row.templateId}/preview`}
                                      target="_blank" rel="noopener noreferrer">
                                    <OpenInNewIcon fontSize={"inherit"}
                                                   style={{verticalAlign: "center", marginLeft: "7px"}}/>
                                </Link> : null
                                }
                            </TableCell>
                            <TableCell>
                                    <ProgressBar value={row.data.interactedCriticalRate} color={"interaction"}
                                                 textColor={"black"} secondaryValue={row.data.interactedRate}
                                                 tooltipContent={tooltip(row.data.interacted, row.data.interactedCritical, row.data.clicked)}/>
                            </TableCell>
                        </TableRow>
                    )
                })}
            </TableBody>
        </Table>
    )
}