import {Await, defer, json, useLoaderData} from "react-router-dom";
import React, {Suspense} from "react";
import {Domain, DomainGroup} from "../../model/Domain";
import {getDomain} from "../../api/domainClient";
import DomainEditor from "../../components/Domain/DomainEditor";
import {loadDomainGroups} from "./DomainManager";
import {CircularProgress} from "@mui/material";

const EditDomainPage: React.FC = (props) => {
    const {domain, domainGroups} = useLoaderData() as { domain: Domain, domainGroups: DomainGroup[] };


    return (
        <Suspense fallback={<CircularProgress/>}>
            <Await resolve={Promise.all([domain, domainGroups])}>
                    {data => <DomainEditor method="PUT" domain={data[0]} domainGroups={data[1]} />}
                </Await>
            </Suspense>
    )
}

export default EditDomainPage;

async function loadDomain(domainId: string, customerId: string) {
    const response = await getDomain(domainId, customerId);

    if (!response.ok) {
        throw json({message: 'Could not fetch Domain.'}, {status: response.status});
    } else {
        const resData = await response.json();
        return resData
    }
}

export async function loader({
                                 request,
                                 params
                             }: { request: Request, params: { domainId: string, customerId: string } }) {
    const domainId = params.domainId;
    const customerId = params.customerId;
    return defer({
        domain: loadDomain(domainId, customerId),
        domainGroups: loadDomainGroups(customerId)
    })
}