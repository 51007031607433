import React, {useState} from "react";
import {DataGrid, GridColDef, GridRenderCellParams, GridValueGetterParams, useGridApiRef} from '@mui/x-data-grid';
import {Campaign, Status} from '../../model/Campaign';
import classes from '../../styling/components/Table.module.css';
import {json, Link, useNavigate, useParams} from 'react-router-dom';
import {ReceiverGroup} from '../../model/ReceiverGroup';
import {Alert, Button, IconButton} from '@mui/material';
import {updateCampaignStatus} from "../../api/campaignClient";
import EditIcon from '@mui/icons-material/Edit';
import SendIcon from '@mui/icons-material/Send';
import LinkIcon from '@mui/icons-material/Link';
import {useTranslation} from "react-i18next";
import {generateCampaignLearningLinks, sendCampaignTestmails} from "../../api/sendTestmailClient";
import LearningLinksDialog from "../Template/TemplateTableDialog/LearningLinksDialog";
import {useConfirmationDialog} from "../../hooks/useConfirmationDialog";

interface Props {
    campaigns: Campaign[]
}

const CampaignTable: React.FC<Props> = (props) => {
    const { t } = useTranslation();
    // const [renderStatusButtonLabel, setRenderStatusButtonLabel] = useState<string>();
    // const [disableRenderStatusButton, setDisableRenderStatusButton] = useState<boolean>(false);
    const apiRef = useGridApiRef();
    const navigate = useNavigate();
    const { customerId } = useParams();

    const [confirmationDialog, openConfirmationDialog] = useConfirmationDialog()

    // Change status confirmation dialog
    const confirmChangeStatus = (status: Status, params: GridRenderCellParams) => {
        const callback = () => {
            if (status && params) {
                changeStatus(status, params);
            }
        }
        openConfirmationDialog(t('campaignTable.changeStatusConfirmationTitle'), t('campaignTable.changeStatusConfirmationContent'), callback)
    }

    const changeStatus = async (currentStatus: string, params: GridRenderCellParams) => {
        const campaignId = params.row.campaignId;
        let newStatus: Status
        switch (currentStatus) {
            case Status.IN_CONFIGURATION:
                newStatus = Status.READY;
                break;
            case Status.READY:
                newStatus = Status.IN_CONFIGURATION;
                break;
            case Status.RUNNING:
                newStatus = Status.PAUSED;
                break;
            case Status.PAUSED:
                newStatus = Status.RUNNING;
                break;
            // No finished/cancelled case needed here because button disabled  anyways
        }
        const response = await updateCampaignStatus({ status: newStatus! }, campaignId, customerId!);
        if (!response.ok) {
            throw json({ message: `${t('campaignTable.updateCampaignError')}` }, { status: response.status });
        } else {
            renderStatusButton(params);
            apiRef.current.updateRows([{ campaignId: campaignId, status: newStatus! }])
        }
    }

    const renderStatusButton = (params: GridRenderCellParams) => {
        const status = params.row.status;
        let label: string = "";
        let isDisabled: boolean = false;
        switch (status) {
            case Status.IN_CONFIGURATION:
                label = `${t('campaignTable.markAsReady')}`;
                break;
            case Status.READY:
                label = `${t('campaignTable.markAsInConfig')}`;
                break;
            case Status.RUNNING:
                label = `${t('campaignTable.pauseCampaign')}`;
                break;
            case Status.PAUSED:
                label = `${t('campaignTable.continueCampaign')}`;
                break;
            case Status.FINISHED:
                label = `${t('campaignTable.cannotChangeFinished')}`;
                isDisabled = true;
                break;
            case Status.CANCELLED:
                label = `${t('campaignTable.cannotChangeCancelled')}`;
                isDisabled = true;
                break;
        }
        return (
            <strong>
                <Button
                    variant="contained"
                    disabled={isDisabled}
                    color="primary"
                    size="small"
                    children={label}
                    onClick={() => {
                        confirmChangeStatus(status, params)
                    }}
                />
            </strong>
        )
    }
    // Send Testmail
    // Send Testmail Confirmation Dialog

    const confirmSendTestmail = (params: GridRenderCellParams) => {
        const callback = () => {
            if (params) {
                sendCampaignTestmailsFromTable(params.value);
            }
        }
        openConfirmationDialog(t('campaignTable.testmailConfirmationTitle'), t('campaignTable.testmailConfirmationContent'), callback)
    }

    const [testMailSentSuccessfully, setTestmailSentSuccessfully] = useState<boolean>(false);
    const [testMailSentError, setTestmailSentError] = useState<boolean>(false);
    const sendCampaignTestmailsFromTable = async (campaignId: string) => {
        setIsSubmitting(true);
        const response = await sendCampaignTestmails(campaignId);
        if (!response.ok) {
            setTestmailSentError(true);
            setIsSubmitting(false)
        } else {
            setTestmailSentSuccessfully(true);
            setIsSubmitting(false);
        }
    }
    const columns: GridColDef[] = [
        { field: 'name', headerName: 'Name', width: 130 },
        {
            field: 'receiverGroups', headerName: `${t('campaignTable.receiverGroups')}`, width: 130,
            renderCell: (params: GridRenderCellParams) => (
                params.value.map((receiverGroup: ReceiverGroup) => (
                    `${receiverGroup.name} `
                ))
            )
        },
        //DO not show templates because not much value
        /*{
            field: 'templates', headerName: `${t('campaignTable.templates')}`, width: 130,
            renderCell: (params: GridRenderCellParams) => (
                params.value.map((template: Template) => (
                    `${template.name} `
                ))
            )
        },*/
        {
            field: 'changeStatus',
            headerName: `${t('campaignTable.changeStatus')}`,
            width: 170,
            renderCell: renderStatusButton
        },
        {
            field: 'status', headerName: `${t('campaignTable.status')}`, width: 130,
            renderCell: (params) => (
                <span>{t(`campaignTable.currentStatus.${params.value.toLowerCase().split(' ').join('')}`)}</span>
            ),
        },
        {
            field: 'edit', headerName: `${t('campaignTable.edit')}`, width: 70,
            valueGetter: (params: GridValueGetterParams) => params.row.campaignId,
            renderCell: (params: GridRenderCellParams) => (
                <IconButton aria-label="edit" onClick={() => {
                    navigate(`${params.value}`)
                }}>
                    <EditIcon />
                </IconButton>
            )
        },
        {
            field: 'testmail', headerName: `${t('campaignTable.testmail')}`, width: 70,
            valueGetter: (params: GridValueGetterParams) => params.row.campaignId,
            renderCell: (params) => (
                <IconButton aria-label="testmail" disabled={isSubmitting} onClick={(e) => confirmSendTestmail(params)}>
                    <SendIcon />
                </IconButton>
            )
        },
        {
            field: 'learningLinks', headerName: `${t('campaignTable.learningLinks')}`, width: 70,
            valueGetter: (params: GridValueGetterParams) => params.row.campaignId,
            renderCell: (params) => (
                <IconButton disabled={isSubmitting} onClick={(e) => generateLinks(params.value as string)}>
                    <LinkIcon />
                </IconButton>
            )
        },
        {
            field: 'whitelisting', headerName: `${t('campaignTable.whitelisting')}`, width: 130,
            valueGetter: (params: GridValueGetterParams) => params.row.campaignId,
            renderCell: (params: GridRenderCellParams) => (
                <Link to={`${params.value}/whitelisting`}>{t('campaignTable.viewWhitelisting')}</Link>
            )
        },
        {
            field: 'dispatches', headerName: `${t('campaignTable.dispatches')}`, width: 130,
            valueGetter: (params: GridValueGetterParams) => params.row.campaignId,
            renderCell: (params: GridRenderCellParams) => (
                <Link to={`${params.value}/dispatches`}>{t('campaignTable.viewDispatches')}</Link>
            )
        },

    ];
    // Generate links
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [learningLinks, setLearningLinks] = useState<string[]>([]);
    const [linkDialogOpen, setLinkDialogOpen] = useState(false);

    const openLinkDialog = () => {
        setLinkDialogOpen(true);
    }
    const handleLinkDialogClose = () => {
        setLinkDialogOpen(false);
    }

    const generateLinks = (campaignId: string) => {
        setIsSubmitting(true);
        generateCampaignLearningLinks(campaignId)
            .then((res) => {
                if (!res.ok) {
                    throw json({ message: `${t('campaignTable.generateLinksError')}` }, { status: 500 });
                }
                setIsSubmitting(false);
                return res.json()
            })
            .then((data) => {
                setIsSubmitting(false);
                setLearningLinks(data);
                openLinkDialog();
            })
            .catch((exception) => {
                console.log(exception.message);
            });
    }

    const rows = props.campaigns;
    return (
        <>
            {confirmationDialog}
            <div className={classes.campaigntable}>
                {testMailSentSuccessfully && <Alert onClose={() => {
                    setTestmailSentSuccessfully(false)
                }} severity="success">{t('campaignTable.testmailSentSuccessfully')}</Alert>}
                {testMailSentError && <Alert onClose={() => {
                    setTestmailSentError(false)
                }} severity="error">{t('campaignTable.testmailSentError')}</Alert>}
                <LearningLinksDialog
                    open={linkDialogOpen}
                    onClose={handleLinkDialogClose}
                    learningLinks={learningLinks}
                />
                <DataGrid
                    rows={rows}
                    getRowId={(row) => row.campaignId}
                    columns={columns}
                    apiRef={apiRef}
                    initialState={{
                        pagination: {
                            paginationModel: {page: 0, pageSize: 5},
                        },
                    }}
                    pageSizeOptions={[5, 10]}
                    checkboxSelection
                />
            </div>
        </>
    );
}

export default CampaignTable;