import React from "react";
import {TotalCampaignAnalytics} from "../../../model/Analytics";
import {BaselineStatComparison} from "./BaselineStatComparison";
import AdsClickIcon from "@mui/icons-material/AdsClick";
import {Card, CardContent, CardHeader, useTheme} from "@mui/material";
import classes from "../../../styling/components/analytics/BaselineComparisonCard.module.css";
import PasswordIcon from "@mui/icons-material/Password";
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import {useTranslation} from "react-i18next";
import {ExplanationTooltip} from "./ExplanationTooltip";
import {ChartData} from "chart.js";

interface Props {
    stats: TotalCampaignAnalytics
    reportingEnabled: boolean
    interactionEnabled: boolean
    inBaseline: boolean
}

export const BaselineComparisonCard: React.FC<Props> = (props) => {

    const {stats, reportingEnabled, interactionEnabled, inBaseline} = props
    const {baselineAnalytics, followUpAnalytics} = stats

    const theme = useTheme();

    const {t} = useTranslation()

    const title = <div className={classes.title}>
        {t("campaignAnalyticsView.baselineComparisonCardTitle")}
        <ExplanationTooltip content={t("explanations.baselineCard")}/>
    </div>

    /* Data for doughnut Graphs */
    const dataBaselineClick: ChartData<'doughnut'> = {
        datasets: [{
            data: [baselineAnalytics.clicked, baselineAnalytics.sent - baselineAnalytics.clicked], //@ts-ignore
            backgroundColor: [theme.palette.click.main, "#F0F0F0"]
        }],
        labels: [t('statCard.clicked'), t('statCard.notClicked')]
    }
    const dataFollowUpClick: ChartData<'doughnut'> = {
        datasets: [{
            data: [followUpAnalytics.clicked, followUpAnalytics.sent - followUpAnalytics.clicked], //@ts-ignore
            backgroundColor: [theme.palette.click.main, "#F0F0F0"]
        }],
        labels: [t('statCard.clicked'), t('statCard.notClicked')]
    }
    const dataBaselineReporting: ChartData<'doughnut'> = {
        datasets: [{
            data: [baselineAnalytics.reported, baselineAnalytics.sent - baselineAnalytics.reported], //@ts-ignore
            backgroundColor: [theme.palette.reporting.main, "#F0F0F0"]
        }],
        labels: [t('statCard.reported'), t('statCard.notReported')]
    }
    const dataFollowUpReporting: ChartData<'doughnut'> = {
        datasets: [{
            data: [followUpAnalytics.reported, followUpAnalytics.sent - followUpAnalytics.reported], //@ts-ignore
            backgroundColor: [theme.palette.reporting.main, "#F0F0F0"]
        }],
        labels: [t('statCard.reported'), t('statCard.notReported')]
    }
    const dataBaselineInteraction: ChartData<'doughnut'> = {
        datasets: [{
            data: [baselineAnalytics.interactedCritical, baselineAnalytics.interacted, baselineAnalytics.interactableClicked - baselineAnalytics.interacted - baselineAnalytics.interactedCritical], //@ts-ignore
            backgroundColor: [theme.palette.interaction.main, "#FFE3C4", "#F0F0F0"]
        }],
        labels: [t('statCard.criticalInteraction'), t('statCard.nonCriticalInteraction'), t('statCard.noInteraction')]
    }
    const dataFollowUpInteraction: ChartData<'doughnut'> = {
        datasets: [{
            data: [followUpAnalytics.interactedCritical, followUpAnalytics.interacted, followUpAnalytics.interactableClicked - followUpAnalytics.interacted - followUpAnalytics.interactedCritical], //@ts-ignore
            backgroundColor: [theme.palette.interaction.main, "#FFE3C4", "#F0F0F0"]
        }],
        labels: [t('statCard.criticalInteraction'), t('statCard.nonCriticalInteraction'), t('statCard.noInteraction')]
    }

    return (
        <Card className={classes.baselineComparisonCard}>
            <CardHeader
                title={title}
            />
            <CardContent>
                <BaselineStatComparison statName={t("campaignAnalyticsView.clickRate")}
                                        baselineValue={baselineAnalytics.clicked}
                                        baselineTotal={baselineAnalytics.sent}
                                        baselineData={dataBaselineClick}
                                        followUpValue={followUpAnalytics.clicked}
                                        followUpTotal={followUpAnalytics.sent}
                                        followUpData={dataFollowUpClick} /*@ts-ignore*/
                                        icon={<AdsClickIcon color={"click"}/>} /*@ts-ignore*/
                                        color={theme.palette.click.main} invertedTrend={true}/>

                {reportingEnabled ?
                    <>
                        <hr/>
                        <BaselineStatComparison statName={t("campaignAnalyticsView.reportingRate")}
                                                baselineValue={baselineAnalytics.reported}
                                                baselineTotal={baselineAnalytics.sent}
                                                baselineData={dataBaselineReporting}
                                                followUpValue={followUpAnalytics.reported}
                                                followUpTotal={followUpAnalytics.sent}
                                                followUpData={dataFollowUpReporting}/*@ts-ignore*/
                                                icon={<NotificationsActiveIcon color={"reporting"}/>} /*@ts-ignore*/
                                                color={theme.palette.reporting.main}
                        />
                    </> : null}
                {interactionEnabled ?
                    <>
                        <hr/>
                        <BaselineStatComparison statName={t("campaignAnalyticsView.interactionRate")}
                                                baselineValue={baselineAnalytics.interacted + baselineAnalytics.interactedCritical}
                                                baselineTotal={baselineAnalytics.interactableClicked}
                                                baselineData={dataBaselineInteraction}
                                                followUpValue={followUpAnalytics.interacted + followUpAnalytics.interactedCritical}
                                                followUpTotal={followUpAnalytics.interactableClicked}
                                                followUpData={dataFollowUpInteraction}/*@ts-ignore*/
                                                icon={<PasswordIcon color={"interaction"}/>} /*@ts-ignore*/
                                                color={theme.palette.interaction.main}/>
                    </>
                    : null}
            </CardContent>
        </Card>
    )
}