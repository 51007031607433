import {Await, defer, useLoaderData} from "react-router-dom";
import ReceiverGroupEditor from "../../components/ReceiverGroup/ReceiverGroupEditor";
import {loadCustomerReceiverGroups, loadCustomerReceivers} from "../Receiver/ReceiverManager";
import React, {Suspense} from "react";
import {Receiver} from "../../model/Receiver";
import {ReceiverGroup} from "../../model/ReceiverGroup";
import {CircularProgress} from "@mui/material";

const NewReceiverGroupPage: React.FC = (props) => {
    const { receivers, receiverGroups } = useLoaderData() as { receivers: Receiver[], receiverGroups: ReceiverGroup[] };
    return (
        <Suspense fallback={<CircularProgress/>}>
            <Await resolve={Promise.all([receivers, receiverGroups])}>
                {data => <ReceiverGroupEditor method="POST" receivers={data[0]} receiverGroups={data[1]} />}
            </Await>
        </Suspense>
    )
}

export default NewReceiverGroupPage;

export async function loader({ request, params }: { request: Request, params: { customerId: string, receiverGroupId: string } }) {
    const customerId = params.customerId;

    return defer({
        receivers: loadCustomerReceivers(customerId),
        receiverGroups: loadCustomerReceiverGroups(customerId)
    })
}