import {Box, Button, CircularProgress} from "@mui/material";
import React, {Suspense} from "react";
import {Await, defer, json, Link, useLoaderData} from "react-router-dom";
import {getCustomerCampaigns} from "../../api/campaignClient";
import CampaignTable from "../../components/Campaign/CampaignTable";
import {Campaign} from "../../model/Campaign";
import classes from "../../styling/pages/Manager.module.css";
import {useTranslation} from "react-i18next";

const CampaignManagerPage: React.FC = (props) => {
    const { t } = useTranslation();
    const { campaigns } = useLoaderData() as { campaigns: Campaign[] };
    return (
        <><Box className={classes.buttonContainer}>
            <Link to={"new"}>
                <Button variant="contained">
                    {t('campaignManager.newCampaign')}
                </Button>
            </Link>
        </Box>
            <Suspense fallback={<CircularProgress/>}>
                <Await resolve={campaigns}>
                    {loadedCampaigns => <CampaignTable campaigns={loadedCampaigns} />}
                </Await>
            </Suspense>
        </>
    );
}

export default CampaignManagerPage;

async function loadCustomerCampaigns(id: string) {
    const response = await getCustomerCampaigns(id);

    if (!response.ok) {
        throw json({ message: 'Could not fetch customercampaigns.' }, { status: 500 });
    }
    else {
        const resData = await response.json();
        return resData
    }
}

export async function loader({ request, params }: { request: Request, params: { customerId: string } }) {
    const id = params.customerId;

    return defer({
        campaigns: loadCustomerCampaigns(id)
    })
}