import {Box, Button, CircularProgress} from "@mui/material";
import React, {Suspense} from "react";
import {Await, defer, json, useLoaderData} from "react-router";
import {Link} from "react-router-dom";
import {listCustomers} from "../../api/customerClient";
import CustomersList from "../../components/Customer/CustomersList";
import {Customer} from "../../model/Customer";
import classes from "../../styling/pages/Manager.module.css"
import {useTranslation} from "react-i18next";

const CustomerManagerPage: React.FC = (props) => {
    const { t } = useTranslation();
    const { customers } = useLoaderData() as { customers: Customer[] };

    return (
        <><Box className={classes.buttonContainer}>
            <Link to={"new"}>
                <Button variant="contained">
                    {t('customerManager.newCustomer')}
                </Button>
            </Link>
        </Box>
            <Suspense fallback={<CircularProgress/>}>
                <Await resolve={customers}>
                    {(loadedCustomers) => <CustomersList customers={loadedCustomers} />}
                </Await>
            </Suspense>
        </>
    );

}

export default CustomerManagerPage;

export async function loadCustomers() {
    const response = await listCustomers("1");

    if (!response.ok) {
        throw json({ message: 'Could not fetch customers.' }, { status: 500 });
    }
    else {
        const resData = await response.json();
        return resData
    }
}

export function loader() {
    return defer({
        customers: loadCustomers()
    });
}


