import {Await, defer, json, useLoaderData} from "react-router-dom";
import {Campaign} from "../../model/Campaign";
import React, {Suspense} from "react";
import {Analytics} from "../../model/Analytics";
import {CampaignAnalyticsView} from "../../components/Analytics/CampaignAnalyticsView";
import {getCampaignAnalytics} from "../../api/analyticsClient";
import {loadCampaign} from "../Campaign/EditCampaign";
import {CircularProgress} from "@mui/material";

const CampaignAnalyticsPage: React.FC = (props) => {
    const {analytics, campaign} = useLoaderData() as { analytics: Analytics, campaign: Campaign };

    return (
        <Suspense fallback={<CircularProgress/>}>
            <Await resolve={Promise.all([analytics, campaign])}>
                {data => <CampaignAnalyticsView analytics={data[0]} campaign={data[1]}/>}
            </Await>
        </Suspense>
    )
}

export default CampaignAnalyticsPage;

async function loadCampaignAnalytics(customerId: string, campaignId: string) {
    const response = await getCampaignAnalytics(customerId, campaignId);

    if (!response.ok) {
        throw json({message: 'Could not fetch Campaign.'}, {status: response.status});
    } else {
        const resData = await response.json();
        return resData
    }
}

export async function loader({
                                 request,
                                 params
                             }: { request: Request, params: { customerId: string, campaignId: string } }) {
    const campaignId = params.campaignId;
    const customerId = params.customerId;

    return defer({
        analytics: loadCampaignAnalytics(customerId, campaignId),
        campaign: loadCampaign(campaignId, customerId)
    })
}