import {Template} from "../model/Template";
import {fetchWithToken} from "./fetchWithToken";

const getBaseUrl = (customerId: string) => {
    let url;
    switch (process.env.NODE_ENV) {
        case 'production':
            url = `/api/customers/${customerId}/templates`;
            break;
        case 'development':
            url = `http://localhost:5000/api/customers/${customerId}/templates`;
            break;
        default:
            url = `/api/customers/${customerId}/templates`;
    }

    return url;
}

export async function getTemplate(template_id: string, customerId: string) {
    try {
        const response = await fetchWithToken(getBaseUrl(customerId) + `/${template_id}`, {
            method: "GET"
        })
        return response
    } catch (error) {
        throw new Error("Error while loading template")
    }
}

export async function getTemplatePreview(template_id: string, customerId: string) {
    try {
        const response = await fetchWithToken(getBaseUrl(customerId) + `/${template_id}/preview`, {
            method: "GET"
        })
        return response
    } catch (error) {
        throw new Error("Error while loading template")
    }
}

export async function getTemplateLearningPagePreview(template_id: string, customerId: string) {
    try {
        const response = await fetchWithToken(getBaseUrl(customerId) + `/${template_id}/learningpage`, {
            method: "GET"
        })
        return response
    } catch (error) {
        throw new Error("Error while loading template")
    }
}


export async function getCustomerTemplates(customerId: string) {
    try {
        const response = await fetchWithToken(getBaseUrl(customerId), {
            method: "GET"
        })
        return response
    } catch (error) {
        throw new Error("Error while loading template")
    }
}

export async function listDemoTemplates(customerId: string) {
    try {
        const response = await fetchWithToken(getBaseUrl(customerId) + "/demo", {
            method: "GET"
        })
        return response
    } catch (error) {
        throw new Error("Error while loading template")
    }
}

export async function createTemplate(template: Template, customerId: string) {
    const {...sendTemp} = template
    try {
        const response = await fetchWithToken(getBaseUrl(customerId), {
            method: "POST",
            body: JSON.stringify(sendTemp),
            headers: {
                "Content-Type": "application/json",
            },
        })
        return response
    } catch (error){
        throw new Error("Error while loading template")
    }
}

export async function copyTemplatesToCustomer(templateIds: string[], targetCustomerId: string){
    try {
        const response = await fetchWithToken(process.env.REACT_APP_API_URL + `/system/templates/clone?targetCustomer=${targetCustomerId}`, {
            method: "POST",
            body: JSON.stringify(templateIds),
            headers: {
                "Content-Type": "application/json",
            },
        })
        return response
    } catch (error){
        throw new Error("Error while loading template")
    }
}

export async function editTemplate(template: Template, template_id: string, customerId: string) {
    try {
        const response = await fetchWithToken(getBaseUrl(customerId) + `/${template_id}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(template)
        })
        return response
    } catch (error){
        throw new Error("Error while loading template")
    }
}

export async function deleteTemplate(template_id: string, customerId: string) {
    try {
        const response = await fetchWithToken(getBaseUrl(customerId) + `/${template_id}`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
            },
        })
        return response
    } catch (error) {
        throw new Error("Error while loading template")
    }
}

export async function deactivateTemplate(template_id: string, customerId: string) {
    try {
        const response = await fetchWithToken(getBaseUrl(customerId) + `/${template_id}/deactivate`, {
            method: "PUT"
        })
        return response
    } catch (error) {
        throw new Error("Error")
    }
}