import React, {useContext, useEffect, useState} from "react";
import {OrganizationLevel} from "../../model/enum/OrganizationLevel";
import {User} from "../../model/User";
import {Box, Button, Card, CardContent, TextField, ToggleButton, ToggleButtonGroup} from "@mui/material";
import {Form, json, useActionData, useNavigation} from "react-router-dom";
import classes from "../../styling/components/UserEditor.module.css";
import {editSelfUser} from "../../api/userClient";
import {UserContext} from "../../App";
import {useSnackbarAlert} from "../../hooks/useSnackbarAlert";
import {useTranslation} from "react-i18next";
import {NavigationHeader} from "../Navigation/NavigationHeader";
import Divider from "@mui/material/Divider";

interface Props {
    level: OrganizationLevel,
    user: User
}

export const SettingsEditor: React.FC<Props> = (props) => {
    const {level, user} = props

    const {t} = useTranslation()
    const navigation = useNavigation();
    const isSubmitting = navigation.state === 'submitting';

    const availableLanguages = ["en", "de"]
    const [language, setLanguage] = useState(user?.language ?? "de")

    const {user: loggedInUser, setUser} = useContext(UserContext)
    const actionData = useActionData();
    const [snackbar, openSnackbarAlert] = useSnackbarAlert();

    useEffect(() => {
        if (actionData) {
            openSnackbarAlert(t('userEditor.userUpdated'), "success")
            window.location.reload()
        }
    }, [actionData]);

    const navigationPath = [
        {url: "", label: t("navigationSidebar.settings")}
    ]

    return (
        <div className={classes.userEditor}>
            <NavigationHeader path={navigationPath}/>
            {snackbar}
            <Card>
                <CardContent>
                    <Box component={Form} method={"PUT"} className={classes.form} autoComplete='off'>
                        <div className={classes.subtitle}>
                            {t('userEditor.accountInfo')}
                        </div>
                        <div className={classes.doubleInputArea}>
                            <TextField disabled id="username" label="Username" name="username" margin="normal"
                                       type="text" fullWidth defaultValue={user?.username ?? ''}/>
                            <TextField disabled id="emailaddress" label={t('userEditor.emailAdress')}
                                       name="emailaddress"
                                       margin="normal" type="text" fullWidth defaultValue={user?.email ?? ''}/>
                        </div>
                        <Divider/>
                        <div className={classes.subtitle}>
                            {t('userEditor.personalInfo')}
                        </div>
                        <div className={classes.doubleInputArea}>
                            <TextField id="firstname" label={t('userEditor.firstName')} name="firstname"
                                       margin="normal" type="text" fullWidth defaultValue={user?.firstName ?? ''}/>
                            <TextField id="lastname" label={t('userEditor.lastName')} name="lastname"
                                       margin="normal" type="text" fullWidth defaultValue={user?.lastName ?? ''}/>
                        </div>
                        <Divider/>
                        <div className={classes.subtitle}>
                            {t('userEditor.language')}
                        </div>
                        <div>
                            <ToggleButtonGroup
                                value={language}
                                exclusive
                                onChange={(event, newValue) => {
                                    if (newValue !== null)
                                        setLanguage(newValue)
                                }}

                            >
                                <ToggleButton value={"de"} color={"primary"}>
                                    Deutsch
                                </ToggleButton>
                                <ToggleButton value={"en"} color={"primary"}>
                                    English
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </div>

                        <Button type="submit" variant="contained" disabled={isSubmitting}
                                className={classes.saveButton}>{t('customerEditor.save')}</Button>

                        <input type={"hidden"} id={"language"} name={"language"} value={language}/>
                        <input type={"hidden"} id={"org"} name={"org"} value={level}/>
                    </Box>
                </CardContent>
            </Card>
        </div>
    );
}

export async function action({request, params}: { request: Request, params: any }) {
    const method = request.method;
    const data = await request.formData();

    const userData = {
        firstName: data.get('firstname') as string,
        lastName: data.get('lastname') as string,
        language: data.get('language') as string,
    };

    if (method === 'PUT') {
        const response = await editSelfUser(userData);

        if (!response.ok) {
            throw json({message: 'Could not update user'}, {status: response.status});
        } else {
            return await response.json();
        }
    }
}