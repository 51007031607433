import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';

// Defining the props type for the component
interface ConfirmationDialogProps {
    open: boolean;
    title: string;
    content: string;
    onCancel: () => void;
    onProceed: () => void;
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({ open, title, content, onCancel, onProceed }) => {
    const { t } = useTranslation();
    return (
        <Dialog
            open={open}
            onClose={onCancel}
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {content}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined"  onClick={onCancel}>{t('confirmationDialog.cancel')}</Button>
                <Button variant="contained" onClick={onProceed}>
                    {t('confirmationDialog.proceed')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmationDialog;