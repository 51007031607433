import React, {useState} from 'react';
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridRowSelectionModel,
  GridValueGetterParams
} from '@mui/x-data-grid';
import classes from '../../styling/components/Table.module.css';

import {Link,} from 'react-router-dom';
import {Receiver} from '../../model/Receiver';
import {ReceiverGroup} from '../../model/ReceiverGroup';
import {useTranslation} from 'react-i18next';

interface Props {
  receivers: Receiver[]
  fromReceiverGroupEditor: boolean,
  selectedReceiverIds? : string[],
  isDefaultGroup?: boolean,
  onSelectionChange?: any,
}

const ReceiverTable: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const columns: GridColDef[] = [
    { field: 'firstName', headerName: `${t('receiverTable.firstName')}`, width: 130 },
    { field: 'lastName', headerName: `${t('receiverTable.lastName')}`, width: 130 },
    { field: 'gender', headerName: `${t('receiverTable.gender')}`, width: 70 },
    { field: 'emailAddress', headerName: `${t('receiverTable.emailAddress')}`, width: 200 },
    {
      field: 'receiverGroups', headerName: `${t('receiverTable.receiverGroups')}`, width: 130,
      renderCell: (params: GridRenderCellParams) => (
        params.value.map((receiverGroup: ReceiverGroup) => (
          `${receiverGroup.name} `
        ))
      )
    },
    {
      field: 'edit', headerName: `${t('receiverTable.edit')}`, width: 130,
      valueGetter: (params: GridValueGetterParams) => params.row.receiverId,
      renderCell: (params) => (
        <Link to={`${params.value}`}>{t('receiverTable.edit')}</Link>
      )
    },
  ];

  // Conditionally disable columns if fromCampaignEditor is true
  const disabledColumns = props.fromReceiverGroupEditor ? [`${t('receiverTable.edit')}`] : [];

  // Filter out disabled columns
  const visibleColumns = columns.filter((column) => !disabledColumns.includes(column.field));
  const [selection, setSelection] = useState<GridRowSelectionModel>(props.selectedReceiverIds ? props.selectedReceiverIds as GridRowSelectionModel : []);

  const [rows, setRows] = React.useState<Receiver[]>(props.receivers);
  return (
    <>
      <div className={classes.receivertable}>
        <DataGrid
          rows={rows}
          getRowId={(row) => row.receiverId}
          columns={visibleColumns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          pageSizeOptions={[5, 10]}
          checkboxSelection
          rowSelectionModel={selection}
          onRowSelectionModelChange={
            !props.isDefaultGroup ?
            (selection) => {
                setSelection(selection);
                if (props.onSelectionChange) {
                  props.onSelectionChange(selection);
                }
            } : undefined
          }
        />
      </div>
    </>
  );
}

export default ReceiverTable;