import {I18n} from 'aws-amplify';
import {translations} from '@aws-amplify/ui-react'; // Adjust the import path based on your setup

// Define your custom translations
export const loginPageTranslations = {
    ...translations.de,
    'Username': ' ',
    'Enter your Username': 'E-Mail',
    'Enter your Password': 'Passwort',
    'Reset your password': 'Passwort zurücksetzen',
    'Enter your username': 'Benutzername oder E-Mail',
    'Reset password': 'Passwort zurücksetzen',
    'Change Password': 'Neues Passwort',
    'Please confirm your Password': 'Passwort bestätigen',
    'Password': ' ',
    "1 validation error detected: Value at 'password' failed to satisfy constraint: Member must satisfy regular expression pattern: ^[\\S]+.*[\\S]+$": "Das Passwort erfüllt die Anforderungen nicht. Passwörter müssen mindestens 8 Zeichen lang sein, und mindestens eine Zahl, ein Sonderzeichen, einen Groß- und einen Kleinbuchstaben enthalten.",
    "Password does not conform to policy: Password must satisfy regular expression pattern: ^\\S.*\\S$": "Das Passwort erfüllt die Anforderungen nicht. Passwörter müssen mindestens 8 Zeichen lang sein, und mindestens eine Zahl, ein Sonderzeichen, einen Groß- und einen Kleinbuchstaben enthalten.",
    "Your passwords must match": "Die Passwörter müssen übereinstimmen",
    "Invalid verification code provided, please try again.": "Invalider Code, bitte versuche es erneut.",
    "Incorrect username or password.": "Falscher Benutzername oder Passwort."
    // Include translations for other labels you wish to customize
};

// Add your custom translations
I18n.putVocabularies({'de': loginPageTranslations});
I18n.setLanguage('de');

// Optionally, set the language if your application supports multiple languages