import {Await, defer, useLoaderData} from "react-router-dom";
import React, {Suspense} from "react";
import {UserEditor} from "../../components/User/UserEditor";
import {OrganizationLevel} from "../../model/enum/OrganizationLevel";
import {loadCustomerRoles} from "./EditCustomerUser";
import {CircularProgress} from "@mui/material";

const NewCustomerUserPage: React.FC = (props) => {
    const {availableRoles} = useLoaderData() as { availableRoles: string[] };


    return (
        <Suspense fallback={<CircularProgress/>}>
            <Await resolve={availableRoles}>
                {availableRoles => <UserEditor method="POST" availableRoles={availableRoles}
                                               level={OrganizationLevel.CUSTOMER}/>}
            </Await>
        </Suspense>
    )
}

export default NewCustomerUserPage;


export async function newCustomerUserPageLoader({
                                                    request,
                                                    params
                                                }: { request: Request, params: { customerId: string } }) {
    const customerId = params.customerId;
    return defer({
        availableRoles: loadCustomerRoles(customerId)
    })
}