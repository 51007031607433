import {Await, defer, json, useLoaderData} from "react-router-dom";
import React, {Suspense} from "react";
import {OrganizationLevel} from "../../model/enum/OrganizationLevel";
import {User} from "../../model/User";
import {getSelfUser} from "../../api/userClient";
import {CircularProgress} from "@mui/material";
import {SettingsEditor} from "../../components/User/SettingsEditor";

const EditSettingsPage: React.FC = (props) => {
    const {user, availableRoles} = useLoaderData() as { user: User, availableRoles: string[] };


    return (
        <Suspense fallback={<CircularProgress/>}>
            <Await resolve={Promise.all([user, availableRoles])}>
                {data => <SettingsEditor user={data[0]}
                                         level={OrganizationLevel.CUSTOMER}/>}
            </Await>
        </Suspense>
    )
}

export default EditSettingsPage;

async function loadSelfUser() {
    const response = await getSelfUser();

    if (!response.ok) {
        throw json({message: 'Could not fetch user.'}, {status: response.status});
    } else {
        const resData = await response.json();
        return resData
    }
}

export async function editSettingsPageLoader() {
    return defer({
        user: loadSelfUser(),
    })
}