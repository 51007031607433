import React from "react";
import {Card, CardContent, CardHeader} from "@mui/material";
import classes from '../../../styling/components/analytics/ReceiverGroupCard.module.css';
import {ReceiverGroupAnalyticsTable} from "./ReceiverGroupAnalyticsTable";

interface Props {
    title: string,
    stats: {
        name: string,
        sent: number,
        clicked: number,
        reported: number,
    }[]
    reportingEnabled: boolean
}

export const ReceiverGroupCard: React.FC<Props> = (props) => {
    const {title, stats, reportingEnabled} = props

    return (
        <Card className={classes.receiverGroupCard}>
            <CardHeader title={title}/>
            <CardContent>
                <ReceiverGroupAnalyticsTable stats={stats} reportingEnabled={reportingEnabled}/>
            </CardContent>
        </Card>
    )
}