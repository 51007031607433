import React from "react";
import {toPct} from "../../../util/Calc";
import classes from "../../../styling/components/analytics/TrendStatCardPanel.module.css";
import {TimespanAnalytics} from "../../../model/Analytics";
import {TrendStatCard} from "./TrendStatCard";
import {useTranslation} from "react-i18next";
import i18n from "i18next";

interface Props {
    currentTimespan: TimespanAnalytics,
    previousTimespan: TimespanAnalytics,
    reportingEnabled: boolean
}

export const TrendStatCardPanel: React.FC<Props> = (props) => {
    const {currentTimespan, previousTimespan, reportingEnabled} = props
    const {t} = useTranslation()

    const deltaClickrate = toPct(currentTimespan.clicked, currentTimespan.sent) - toPct(previousTimespan.clicked, previousTimespan.sent);
    const deltaReporting = toPct(currentTimespan.reported, currentTimespan.sent) - toPct(previousTimespan.reported, previousTimespan.sent);

    const dateBegin = new Date(currentTimespan.startDate).toLocaleString(i18n.language, {
        month: "numeric",
        day: "numeric"
    })
    const dateEnd = new Date(currentTimespan.endDate).toLocaleString(i18n.language, { //Chat-Nachricht Teams, Neue Urlaubsrichtlinie/CEO-Fraud
        month: "numeric",
        day: "numeric"
    })


    const finished = new Date() > new Date(currentTimespan.endDate)
    const headlineClickrate = finished ? t("trendStatCard.clickRateFinished", {
        startDate: dateBegin,
        endDate: dateEnd
    }) : t("trendStatCard.clickRateRunning", {startDate: dateBegin})
    const headlineReporting = finished ? t("trendStatCard.reportingRateFinished", {
        startDate: dateBegin,
        endDate: dateEnd
    }) : t("trendStatCard.reportingRateRunning", {startDate: dateBegin})

    return (
        <div className={classes.trendStatCardPanel}>
            <TrendStatCard
                headline={headlineClickrate}
                divisor={currentTimespan.clicked}
                dividend={currentTimespan.sent}
                delta={deltaClickrate}
                invertedTrend={true}
                color={"clickrate"}
            />
            {reportingEnabled ?
                <TrendStatCard
                    headline={headlineReporting}
                    divisor={currentTimespan.reported}
                    dividend={currentTimespan.sent}
                    delta={deltaReporting}
                    invertedTrend={false}
                    color={"reportingrate"}
                />
                : null}
        </div>
    )
}