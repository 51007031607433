import {Await, defer, json, useLoaderData} from "react-router-dom";
import React, {Suspense} from "react";
import {UserEditor} from "../../components/User/UserEditor";
import {OrganizationLevel} from "../../model/enum/OrganizationLevel";
import {User} from "../../model/User";
import {getCustomerUser} from "../../api/userClient";
import {listCustomerRoles} from "../../api/roleClient";
import {CircularProgress} from "@mui/material";

const EditCustomerUserPage: React.FC = (props) => {
    const {user, availableRoles} = useLoaderData() as { user: User, availableRoles: string[] };


    return (
        <Suspense fallback={<CircularProgress/>}>
            <Await resolve={Promise.all([user, availableRoles])}>
                {data => <UserEditor method="PUT" user={data[0]} availableRoles={data[1]}
                                     level={OrganizationLevel.CUSTOMER}/>}
            </Await>
        </Suspense>
    )
}

export default EditCustomerUserPage;

async function loadCustomerUser(userId: string, customerId: string) {
    const response = await getCustomerUser(userId, customerId);

    if (!response.ok) {
        throw json({message: 'Could not fetch user.'}, {status: response.status});
    } else {
        const resData = await response.json();
        return resData
    }
}

export async function loadCustomerRoles(customerId: string) {
    const response = await listCustomerRoles(customerId);

    if (!response.ok) {
        if (response.status === 400 || response.status === 403) {
            return []
        } else {
            throw json({message: 'Could not fetch roles.'}, {status: response.status});
        }
    } else {
        const resData = await response.json();
        return resData
    }
}

export async function editCustomerUserPageLoader({
                                                     request,
                                                     params
                                                 }: { request: Request, params: { userId: string, customerId: string } }) {
    const userId = params.userId;
    const customerId = params.customerId;
    return defer({
        user: loadCustomerUser(userId, customerId),
        availableRoles: loadCustomerRoles(customerId)
    })
}