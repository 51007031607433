import {Receiver} from "../model/Receiver";
import {fetchWithToken} from "./fetchWithToken";

const getBaseUrl = (customerId: string) => {
    let url;
    switch (process.env.NODE_ENV) {
        case 'production':
            url = `/api/customers/${customerId}/receivers`;
            break;
        case 'development':
            url = `http://localhost:5000/api/customers/${customerId}/receivers`;
            break;
        default:
            url = `/api/customers/${customerId}/receivers`;
    }

    return url;
}

export async function getReceiver(receiver_id: string, customerId: string) {
    try {
        const response = await fetchWithToken(getBaseUrl(customerId) + `/${receiver_id}`, {
            method: "GET",
        })
        return response
    } catch (error) {
        throw new Error("Error")
    }
}

export async function getCustomerReceivers(customerId: string) {
    try {
        const response = await fetchWithToken(getBaseUrl(customerId), {
            method: "GET"
        })
        return response
    } catch (error) {
        throw new Error("Error while loading receivers")
    }
}

export async function deleteReceiver(receiverId: string, customerId: string) {
    try {
        const response = await fetchWithToken(getBaseUrl(customerId) + `/${receiverId}`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
            },
        })
        return response
    } catch (error) {
        throw new Error("Error")
    }
}

export async function createReceiver(receiver: Receiver, customerId: string) {
    return createReceivers([receiver], customerId)
}

export async function createReceivers(receiverslist: Receiver[], customerId: string) {
    try {
        const response = await fetchWithToken(getBaseUrl(customerId), {
            method: "POST",
            body: JSON.stringify(receiverslist),
            headers: {
                "Content-Type": "application/json",
            },
        })
        return response
    } catch (error) {
        throw new Error("Error")
    }
}

export async function editReceiver(receiver: Receiver, receiver_id: string, customerId: string) {
    try {
        const response = await fetchWithToken(getBaseUrl(customerId) + `/${receiver_id}`, {
            method: "PUT",
            body: JSON.stringify(receiver),
            headers: {
                "Content-Type": "application/json",
            },
        })
        return response
    } catch (error) {
        throw new Error("Error")
    }
}

export async function deactivateReceiver(receiver_id: string, customerId: string) {
    try {
        const response = await fetchWithToken(getBaseUrl(customerId) + `/${receiver_id}/deactivate`, {
            method: "PUT"
        })
        return response
    } catch (error) {
        throw new Error("Error")
    }
}

