import React from 'react';
import { FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import { ReceiverGroup } from '../../model/ReceiverGroup';

interface ReceiverGroupSelectProps {
  receiverGroups: ReceiverGroup[];
  checked: boolean[];
  handleChange: (event: React.ChangeEvent<HTMLInputElement>, index: number) => void;
}

const ReceiverGroupSelect: React.FC<ReceiverGroupSelectProps> = ({ receiverGroups, checked, handleChange }) => {
  return (
    <FormGroup>
      {receiverGroups.length > 0 &&
        receiverGroups.map((receiverGroup, index) => (
          <FormControlLabel
            key={receiverGroup.receiverGroupId}
            label={receiverGroup.name}
            control={
              <Checkbox
                onChange={(event) => handleChange(event, index)}
                checked={checked[index]}
                disabled={receiverGroup.name === "Default"}
                inputProps={{ 'aria-label': 'controlled' }}
                name={`receiverGroupName_${receiverGroup.name}`}
                value={receiverGroup.name}
              />
            }
          />
        ))}
    </FormGroup>
  );
};

export default ReceiverGroupSelect;