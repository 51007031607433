import React from "react";
import {CampaignAnalyticsSummary} from "../../../model/CampaignAnalyticsSummary";
import {Card, CardActionArea, CardContent, Grid, useMediaQuery, useTheme} from "@mui/material";
import {useTranslation} from "react-i18next";
import classes from "../../../styling/components/analytics/CampaignOverviewCard.module.css";
import {useNavigate, useParams} from "react-router-dom";
import {ArcElement, Chart as ChartJS, Tooltip} from "chart.js";
import Divider from "@mui/material/Divider";
import {translateStatus} from "../../../util/TranslationHelpers";
import {ProgressBar} from "./ProgressBar";
import {toPct} from "../../../util/Calc";
import {ClickRateDoughnut} from "./ClickRateDoughnut";

interface Props {
    campaign: CampaignAnalyticsSummary
}

export const CampaignOverviewCard: React.FC<Props> = (props) => {
    const {campaign} = props
    const {t, i18n} = useTranslation()
    const {customerId} = useParams();
    const navigate = useNavigate();
    const theme = useTheme()
    const md = useMediaQuery(theme.breakpoints.up("md"))

    const data = {
        datasets: [{
            data: [campaign.sent, campaign.total - campaign.sent],
            backgroundColor: ["#00acc7", "#F0F0F0"]
        }],
        labels: [t('analyticsOverview.sent'), t('analyticsOverview.pending')]
    }

    ChartJS.register(ArcElement, Tooltip)


    return (
        <div className={classes.campaignOverviewCard}>
            <Card>
                <CardActionArea onClick={() => navigate(`${campaign.campaignId}`)}>
                    <CardContent>
                        <Grid container rowSpacing={2} columns={13} columnSpacing={5}>
                            <Grid item xs={12} md>
                                <h3>{campaign.name}</h3>
                                <div className={classes.text}>
                                    {t('analyticsOverview.campaignDuration', {
                                        start: new Date(campaign.startDate).toLocaleString(i18n.language, {
                                            year: "numeric",
                                            month: "numeric",
                                            day: "numeric"
                                        }),
                                        end: new Date(campaign.endDate).toLocaleString(i18n.language, {
                                            year: "numeric",
                                            month: "numeric",
                                            day: "numeric"
                                        })
                                    })}
                                    <br/>
                                    {t('analyticsOverview.mailsSent')} {Math.round(campaign.sent / campaign.total * 100) ?? 0} %
                                    ({campaign.sent} / {campaign.total})
                                    <br/>
                                    Status: {translateStatus(campaign.status, t)}
                                </div>
                                <div className={classes.progressbar}>
                                    <ProgressBar value={toPct(campaign.sent, campaign.total)} color={"primary"}
                                                 textColor={"black"}/>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={.1}>
                                <Divider orientation={md ? "vertical" : "horizontal"}/>
                            </Grid>
                            <Grid item xs={12} md={2.5}>
                                <div className={classes.clickrateArea}>
                                    <ClickRateDoughnut clicked={campaign.clicked} sent={campaign.sent}/>
                                </div>
                            </Grid>
                        </Grid>
                    </CardContent>
                </CardActionArea>
            </Card>
        </div>
    )
}